import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { showNotification } from '../../store/notification/actionCreator'

const variantIcon = {
  success: CheckIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: '#4CAF50',
  },
  error: {
    backgroundColor: '#CC0000',
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  icon: {
    fontSize: 24,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit * 2,
  },
  message: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
  },
});

function CustomSnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <span dangerouslySetInnerHTML={{__html: message}} />
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

CustomSnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
};

const CustomSnackbarContentWrapper = withStyles(styles)(CustomSnackbarContent);


export class Notifications extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      message: undefined, // Store message in state so text doesn't disappear during animation
      duration: 5000,
      variant: 'info',
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.notificationMessage) {
      this.setState({
        message: nextProps.notificationMessage,
      })
    }
    if (nextProps.notificationDuration) {
      this.setState({
        duration: nextProps.notificationDuration,
      })
    }
    if (nextProps.notificationVariant) {
      this.setState({
        variant: nextProps.notificationVariant,
      })
    }
  }

  render () {
    const { notificationIsShown } = this.props
    const { message, duration, variant } = this.state
    const direction = { vertical: 'top', horizontal: 'right' }
    return (
      <Snackbar
        anchorOrigin={direction}
        id="globalSnackbar"
        open={notificationIsShown}
        onClose={this.handleRequestClose}
        autoHideDuration={duration}
      >
        <CustomSnackbarContentWrapper
          onClose={this.handleRequestClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    )
  }

  handleRequestClose = (event, reason) => {
    const { variant } = this.state
    if (reason === 'clickaway' && variant !== 'error') {
      return
    }
    this.props.showNotification(false, '', variant)
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showNotification }, dispatch)

const mapStateToProps = state => {
  const { notification } = state
  const { isShown, message, duration, variant } = notification
  const notificationDuration = (!duration && variant === 'error') ? 60000 : duration
  return {
    notificationIsShown: isShown,
    notificationMessage: message,
    notificationVariant: variant,
    notificationDuration: notificationDuration,
  }
}

Notifications.propTypes = {
  notificationIsShown: PropTypes.bool,
  notificationMessage: PropTypes.string,
  notificationDuration: PropTypes.number,
  notificationVariant: PropTypes.string,
  showNotification: PropTypes.func.isRequired,
}

export default 
connect(mapStateToProps, mapDispatchToProps)(
  Notifications
  )
