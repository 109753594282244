import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Error from '@material-ui/icons/Error'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Add from '@material-ui/icons/Add'
import HeaderTitle from "../../components/Header/HeaderTitle"
import { fetchCategories,openModal, closeModal, postCategories, deleteAppCategories, checkCategories} from '../../store/appCategories/actionCreator'
import GridConfig from './GridConfig'
import CategoriesForm from './CategoriesForm'
import "../../styles/tabel.css"
import NumericEditor from './NumericEditor';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '99%',
  },
  container: {
    marginTop:10,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button : {
    marginLeft: 20,
  },
  search: {
    width:'60%',
    float: 'right',
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 24,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: '#930000',
  },
  input:{
    width: 'calc(100% - 70px)',
    border: 0,
  },
  tableSize:{
    height: "calc(100vh - 190px)",
    width: "100%"
  },
})
class AppsAndReport extends Component {
  constructor(props) {
    super(props)
    this.gConfig =  new GridConfig()
    this.state = {
      columnDefs: this.gConfig .createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        // width: 100
      },
      rowData:  [],
      // enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting : true,
      /** For application **/
      enableEditCat:true,
      selectedRowsCat:'',
      selectedRowsId:'',
      selectedRowsApps:'',
      openModal : false,
      isAdd : false,
      selectedRow: ''
      /** For application **/
    }
    this.gridOptions = {
      columnDefs : this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady :this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      // floatingFilter: true, // Show filter in each column
      // this is a simple property
      rowBuffer: 10, // no need to set this, the default is fine for almost all scenarios,
      onRowSelected: this.rowSelected,
      onSelectionChanged: this.onSelectionChanged.bind(this),
      onCellEditingStopped: this.onCellEditingStopped,
      components:{
        numericCellEditor: NumericEditor,
      }
      // onRowClicked: this.RowClickEventHandler,
      // sideBar: true,
    }
    this.handleAddCat = this.handleAddCat.bind(this)
    this.closeEditCat = this.closeEditCat.bind(this)
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.appCategories.appCategoriesListCount > 0){
      this.setState({rowData:nextProps.appCategories.appCategoriesList})
    }
    if (nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly) {
      this.setState({readOnly: nextProps.appsAndReports.readOnly.read_only && JSON.parse(nextProps.appsAndReports.readOnly.read_only)})
    }
  }
  componentWillMount() {
    this.props.fetchCategories()
  }
  onGridReady = params => {
    this.gridApi = params.api
  }
  handleClick = params => {
    this.setState({selectedRow :params.data, isAdd: false },()=>{
      this.props.openModal('editForm')
    })
  }
  handleAddCat = params => {
    this.setState({isAdd :true },()=>{
      this.props.openModal('editForm')
    })
  }
  updateCat = params => {
    this.props.postCategories(params)
  }
  closeEditCat () {
    this.props.closeModal('editForm')
  }
  onCellEditingStopped = (params) => {
    // console.log('cellEditingStopped', params.data);
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("searchCategories").value)
  }
  onSelectionChanged(event) {
    this.setState({enableEditCat:!event.api.getSelectedNodes().length >= 1})
    let selectedRows = this.gridApi.getSelectedRows()
    let items = []
    selectedRows.forEach(function(selectedRow, index) {
      items.push(selectedRow)
    })
    this.setState({selectedRowsApps:items})
  }
  deleteCategories = (id, isMapped) => {
    this.props.deleteAppCategories(id, isMapped)
  }
  checkAppCategories = (param) => {
    this.props.checkCategories(param)
  }
  render() {
    const {classes, appCategories} = this.props
    const {selectedRow,isAdd,readOnly} = this.state
    return (
      <div className={classes.root}>
        <HeaderTitle title="App Categories" />
        <Helmet>
          <title>Manage App Categories</title>
        </Helmet>
        {appCategories.loading && appCategories.loading.appCategoriesListLoading ? <CircularProgress size={50} className={classes.buttonProgress} />
          : appCategories.apiError
            ? <Grid container spacing={24} >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>An error occurred while retrieving Apps/Reports data. Please contact support if this issue persists.</p>
              </Grid>
            </Grid>
            : appCategories.appCategoriesListCount >= 1 ? <Grid container spacing={24} className={classes.container}>
                <Grid item xs={6}>
                  <Button disabled={readOnly} variant="contained" color="primary" className={classes.button} onClick={() => this.handleAddCat()}>
                    <Add className={classes.buttonIcon}/>
                    Add
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.search} elevation={1}>
                    <IconButton className={classes.iconButton} aria-label="Search"><SearchIcon /></IconButton>
                    <InputBase className={classes.input} placeholder="Search List" onInput={this.onQuickFilterChanged.bind(this)} id="searchCategories" />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <div className={[classes.tableSize, 'ag-theme-material full-row'].join(' ')}>
                    <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
                  </div>
                </Grid>
              </Grid>
              : <Grid container spacing={24}>
                <Grid item xs={12}>
                  <p className={classes.boxDiv}><Error className={classes.icon} /> There are no App Categories.</p>
                </Grid>
              </Grid>
        }
        {this.props.appCategories.modal.editForm &&
          <CategoriesForm
            open={this.props.appCategories.modal.editForm}
            updateAppCategories={this.updateCat}
            onClose={this.closeEditCat}
            data={appCategories}
            selectedRow={selectedRow}
            isAdd={isAdd}
            deleteCategories={this.deleteCategories}
            checkAppCategories={this.checkAppCategories}
          />
        }
      </div>
    )
  }
}
AppsAndReport.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchCategories: PropTypes.func,
  postCategories: PropTypes.func,
  deleteAppCategories: PropTypes.func,
  appCategories :PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  checkCategories: PropTypes.func.isRequired,
  appsAndReports: PropTypes.object,
}
const mapStateToProps = state => {
  const { appCategories, appsAndReports } = state
  return {
    appCategories: appCategories,
    appsAndReports,
    openModal,
    closeModal,
  }
}
export default connect(mapStateToProps, {fetchCategories, openModal, closeModal, postCategories, deleteAppCategories, checkCategories})(withStyles(styles)(AppsAndReport))


