import axios from 'axios'
import * as types from './actionType'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'

export function fetchSupport() {
  return dispatch => {
    dispatch(getSupportInit())
    return axios.get(`${apiConfig.adminApi.host}manage_support_options_path`)
    // return axios.get(`http://10.112.40.31:8081/pol-service/api/v1/admin/manage_support_options_path`, {headers:{"user-i":"z004zd3"}})
      .then(response => {
        dispatch(getSupportSuccess(response.data))
      })
      .catch(err => {
        dispatch(showNotification(true, 'Failed to get support options', 'error'))
        dispatch(getSupportFail())
      })
    }
}
export function getSupportInit () {
  return {
    type: types.GET_SUPPORT_INIT,
  }
}
export function getSupportSuccess (data) {
  return {
    type: types.GET_SUPPORT_SUCCESS,
    data,
  }
}
export function getSupportFail () {
  return {
    type: types.GET_SUPPORT_FAIL,
  }
}

export function editSupport(data) {
  return dispatch => {
    dispatch(updateInit())
    // return axios.post(`http://10.112.168.184:8081/pol-service/api/v1/admin/edit_manage_support_paths`, data, {headers:{"user-i":"z004zd3"}})
    return axios.post(`${apiConfig.adminApi.host}edit_manage_support_paths`, data)
      .then(response => {
        dispatch(updateSupportSuccess(response.data))
      })
      .catch(err => {
        dispatch(showNotification(true, err.response && err.response.data.meta_info.error.message, 'error'))
        dispatch(updateSupportFail())
      })
  }
}
export function updateSupportSuccess (data) {
  return {
    type: types.UPDATE_SUPPORT_SUCCESS,
    data,
  }
}
export function updateSupportFail () {
  return {
    type: types.UPDATE_SUPPORT_FAIL,
  }
}
export function updateInit () {
  return {
    type: types.UPDATE_SUPPORT_INIT,
  }
}

export function addSupport(data) {
  return dispatch => {
    dispatch(postSupportInit())
    return axios.post(`${apiConfig.adminApi.host}add_manage_support_paths`, data)
    // return axios.post(`http://10.112.40.71:8081/pol-service/api/v1/admin/add_manage_support_paths`, data, {headers:{"user-i":"z004zd3"}})
      .then(response => {
        dispatch(postSupportSuccess(response.data))
      })
      .catch(err => {
        dispatch(showNotification(true, err.response && err.response.data.meta_info.error.message, 'error'))
        dispatch(postSupportFail())
      })
  }
}

export function postSupportSuccess (data) {
  return {
    type: types.POST_SUPPORT_SUCCESS,
    data,
  }
}
export function postSupportFail () {
  return {
    type: types.POST_SUPPORT_FAIL,
  }
}
export function postSupportInit () {
  return {
    type: types.POST_SUPPORT_INIT,
  }
}

export function openModal(modalName, name) {
  return {
    type: types.OPEN_MODAL,
    modalName,
    name,
  }
}

export function closeModal(modalName) {
  return {
    type: types.CLOSE_MODAL,
    modalName,
  }
}

export function fetchSupportOptions () {
  // return dispatch => axios.get(`http://10.112.40.71:8081/pol-service/api/v1/admin/admin_reason_for_support_list?hasContact=false`, {headers:{"user-i":"z004zd3"}})
  return dispatch => axios.get(`${apiConfig.adminApi.host}admin_reason_for_support_list?hasContact=false`)
      .then(response => {
        dispatch(getSupportOptions(response.data))
      })
      .catch(err => { throw (err) })
}

export function getSupportOptions (data) {
  return {
    type: types.GET_OPTIONS,
    data,
  }
}

export function deleteSupport (data) {
  return dispatch => {
    dispatch(deleteSupportInit())
    // return axios.post(`http://10.112.168.184:8081/pol-service/api/v1/admin/delete_manage_support_paths`, data, {headers:{"user-i":"z004zd3"}})
    return axios.post(`${apiConfig.adminApi.host}delete_manage_support_paths`, data)
    .then(response => {
      dispatch(deleteSupportSuccess(response.data))
    })
    .catch(err => {
      dispatch(showNotification(true, err.response && err.response.data.meta_info.error.message, 'error'))
      dispatch(deleteSupportFail())
    })
  }
}
export function deleteSupportInit () {
  return { type: types.DELETE_SUPPORT_INIT }
}
export function deleteSupportSuccess (data) {
  return { type: types.DELETE_SUPPORT_SUCCESS, data, }
}
export function deleteSupportFail () {
  return { type: types.DELETE_SUPPORT_FAIL }
}

export function getHelpCategories () {
  return dispatch => {
    dispatch(getHelpCategoriesInit())
    return axios.get(`${apiConfig.adminApi.host}all_help_metric_groups`)
      .then(response => {
        dispatch(getHelpCategoriesSuccess(response.data))
      })
      .catch(err => {
        dispatch(showNotification(true, err.response && err.response.data.meta_info.error.message, 'error'))
        dispatch(getHelpCategoriesFail())
      })
  }
}
export function getHelpCategoriesInit () {
  return { type: types.GET_HELP_CATEGORIES_INIT }
}
export function getHelpCategoriesSuccess (data) {
  return { type: types.GET_HELP_CATEGORIES_SUCCESS, data, }
}
export function getHelpCategoriesFail () {
  return { type: types.GET_HELP_CATEGORIES_FAIL }
}

export function editHelpCategories (data) {
  return dispatch => {
    dispatch(editHelpCategoriesInit())
    return axios.post(`${apiConfig.adminApi.host}add_edit_help_metric_group`, data)
      .then(response => {
        dispatch(editHelpCategoriesSuccess(response.data))
      })
      .catch(err => {
        dispatch(showNotification(true, err.response && err.response.data.meta_info.error.message, 'error'))
        dispatch(editHelpCategoriesFail())
      })
  }
}
export function editHelpCategoriesInit () {
  return { type: types.EDIT_HELP_CATEGORIES_INIT }
}
export function editHelpCategoriesSuccess (data) {
  return { type: types.EDIT_HELP_CATEGORIES_SUCCESS, data, }
}
export function editHelpCategoriesFail () {
  return { type: types.EDIT_HELP_CATEGORIES_FAIL }
}

export function deleteHelpCategories (id, name) {
  return dispatch => axios.post(`${apiConfig.adminApi.host}delete_help_metric_group?id=${id}`)
      .then(response => {
        dispatch(deleteHelpCategoriesSuccess())
        dispatch(showNotification(true, `${name} Help Category deleted`, 'success'))
      })
      .catch(err => {
        dispatch(showNotification(true, err.response && err.response.data.meta_info.error.message, 'error'))
      })
}
export function deleteHelpCategoriesSuccess () {
  return { type: types.DELETE_HELP_CATEGORIES_SUCCESS }
}
