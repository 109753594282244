export const TEXT_SUCCESS_APP_ADDED = "App/Report has been added successfully.";
export const TEXT_SUCCESS_APP_UPDATED = "App/Report has been updated successfully.";
export const TEXT_ERROR_DEFAULT = "POL is experiencing a problem. Please try again.";
export const TEXT_ERROR_DUPLICATE = " is a duplicate ";
export const TEXT_SUCCESS_APP_CATEGORIES_ADDED = "App Category has been added successfully.";
export const TEXT_SUCCESS_APP_CATEGORIES_UPDATED = "App Category has been updated successfully.";
export const TEXT_SUCCESS_APP_GROUP_ADDED = "LDS Group has been added successfully.";
export const TEXT_SUCCESS_APP_GROUP_UPDATED = "LDS Group has been Updated successfully.";
export const TEXT_SUCCESS_REASON_FOR_SUPPORT_ADDED = "Reason For Support has been added successfully.";
export const TEXT_SUCCESS_REASON_FOR_SUPPORT_UPDATED = "Reason For Support has been updated successfully.";
export const TEXT_SUCCESS_SEARCH_FIELD_UPDATES = "Changes submitted successfully!";
export const TEXT_SUCCESS_REPORT_GENERATED = "Report is ready and available in downloads.";
export const DEFAULT_ID = "000"
