import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Download from '@material-ui/icons/CloudDownloadRounded'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Error, Close } from '@material-ui/icons'
import { Grid, CircularProgress, Button } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { Helmet } from 'react-helmet'
import Modal from '@material-ui/core/Modal'
import moment from 'moment-timezone'
import GridConfig from './GridConfig'
import HeaderTitle from '../../components/Header/HeaderTitle'
import {
  fetchContent,
  editContentFeedback,
} from './../../store/contentFeedback/actionCreator'
import { DEFAULT_ID } from './../../constants/notifications'
import apiConfig from '../../config/apiConfig'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    marginTop: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 12,
  },
  search: {
    width: '60%',
    float: 'right',
  },
  popup: {
    padding: 20,
    lineHeight: 3,
  },
  headerRow: {
    backgroundColor: '#f2f2f2',
    height: '55px',
    position: 'sticky',
    top: '0',
    color: 'rgba(0,0,0,.54)',
  },
  tableContainer: {
    height: '900px',
    overflowY: 'scroll',
    margin: '0px 0px 0px 20px',
    overflowX: 'scroll',
    '& tbody': {
      padding: '10px',
    },
    '&::-webkit-scrollbar': {
      height: '12px',
      width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'darkgray',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'lightgray',
    },
    '&:hover': {
      overflowY: 'scroll',
    },
  },
  tableFields: {
    height: '55px',
    borderBottom: '1px ridge rgba(0, 0, 0, 0.1)',
    color: 'dimgrey',
  },
  dimmedOverlay: {
    content: '',
    position: 'inherit',
    top: 0,
    left: 0,
    height: '900px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  tableCells: {
    padding: '0px 18px',
  },
  resolvedField: {
    backgroundColor: '#f9ebd7',
    width: '100px',
    textAlignLast: 'center',
  },
  ownerHeader: {
    borderRight: '1px solid #e5e5e5',
    width: '8%',
  },
  ownerField: {
    marginLeft: '66px',
  },
  filterError: {
    textAlign: 'center',
    color: 'black',
    padding: '50px',
    fontSize: '15px',
  },
  tableCellsSelected: {
    backgroundColor: '#eee',
  },
  feedbackTable: {
    width: '150%',
    fontSize: '12px',
    borderCollapse: 'collapse',
    overflowY: 'auto',
    '&:tbody': {
      padding: '10px',
    },
  },
  saveButton: {
    marginLeft: '1332px',
    marginBottom: '12px',
  },
  filterListIcon: {
    marginRight: '-68px',
    marginTop: '-18px',
  },
  filterInputBox: {
    marginRight: '-194px',
    position: 'absolute',
    width: '72px',
    height: '25px',
  },
  closeButton: {
    top: '1em',
    right: '1em',
    position: 'absolute',
    minHeight: '40px',
    '&:focus': {
      background: 'transparent',
      outline: '1px dashed #707070',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  closeIconStyle: {
    float: 'right',
    cursor: 'pointer',
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 24,
  },
  input: {
    width: 'calc(100% - 70px)',
    border: 0,
  },
  tableSize: {
    height: 'calc(100vh - 130px)',
    width: '100%',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  showArchive: {
    display: 'inline',
    marginLeft: 50,
  },
  showArchiveLabel: {
    color: '#757575',
    fontWeight: 700,
  },
  colorSwitchBase: {
    color: '#0049db',
    '&$colorChecked': {
      color: '#0049db',
      '& + $colorBar': {
        backgroundColor: '#0049db',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  paper: {
    position: 'absolute',
    minwidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 100,
    outline: 'none',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

// const MIN_HEIGHT = 75

class ContentFeedback extends Component {
  constructor(props) {
    super(props)
    this.gConfig = new GridConfig()
    this.state = {
      open: false,
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        filter: true,
        autoHeight: true,
        // width: 100
      },
      rowData: [],
      enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      page: 0,
      selectedRows: [],
      appliedFilters: [],
      rowChecked: {},
      isEdited: false,
      editingRow: null,
      editingAuthorComments: -1,
      editingResolved: -1,
      resolvedValues: [],
      authorComments: [],
      resolved: 'No',
      searchedId: null,
      contact: null,
      contentTitle: null,
      sortOrder: 'asc',
      totalItems: 0,
      totalPages: 0,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting: true,
      // /** For application **/
      enableEditCat: true,
      // selectedRowsCat: '',
      // selectedRowsId: '',
      // selectedRowsApps: '',
      // openModal: false,
      // showArchive: false,
      // readOnly: false,
      /** For application **/
    }
    this.gridOptions = {
      columnDefs: this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady: this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      // // floatingFilter: true, // Show filter in each column
      // // this is a simple property
      // rowBuffer: 10, // no need to set this, the default is fine for almost all scenarios,
      onRowSelected: this.rowSelected,
      onSelectionChanged: this.onSelectionChanged.bind(this),
      // isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
      // doesExternalFilterPass: this.doesExternalFilterPass,
      onRowClicked: this.RowClickEventHandler,
      sideBar: true,
      onColumnResized: this.onColumnResized,
    }
    this.handleEditCat = this.handleEditCat.bind(this)
    this.closeEditCat = this.closeEditCat.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.contents &&
      nextProps.contents.contentFeedback !== this.props.contents.contentFeedback
    ) {
      let rowData1 = nextProps.contents.contentFeedback.sort((a, b) =>
        moment(b.feedback_date, 'MM/DD/YY hh:mm a').diff(
          moment(a.feedback_date, 'MM/DD/YY hh:mm a'),
        ),
      )
      if (this.state.rowData.length === 0) {
        this.setState({ rowData: rowData1 })
      } else if (this.state.rowData != rowData1) {
        this.setState((prevState) => ({
          rowData: [...prevState.rowData, ...rowData1],
        }))
      }
    }
  }

  componentDidUpdate() {
    let numberOfElements = this.props.contents?.result?.number_of_elements
    if (this.state.totalItems != numberOfElements) {
      if (
        this.state.searchedId != null &&
        numberOfElements < this.props.contents.result.size
      ) {
        this.setState({
          totalItems: numberOfElements,
          totalPages: this.state.page,
        })
      } else {
        this.setState({
          totalItems: numberOfElements,
          totalPages: this.props.contents?.result?.total_pages,
        })
      }
    }
  }

  componentWillMount() {
    this.props.fetchContent(
      this.state.page,
      this.state.searchedId,
      this.state.feedbackRating,
      this.state.contact,
      this.state.contentTitle,
    )
  }
  componentDidMount() {
    if (this.props.contents && this.props.contents.contentFeedback) {
      let rowData1 = this.props.contents.contentFeedback.sort(
        (a, b) =>
          moment(b.feedback_date, 'MM/DD/YY hh:mm a').diff(
            moment(a.feedback_date, 'MM/DD/YY hh:mm a'),
          ) || a.contact.localeCompare(b.contact),
      )
      this.setState({
        rowData: rowData1,
        authorComments: Array(rowData1.length).fill(''),
        resolvedValues: Array(rowData1.length).fill('No'),
      })
    }
  }
  onColumnResized = (params) => {
    params.api.resetRowHeights()
  }
  onGridReady = (params) => {
    this.gridApi = params.api
    // let defaultSortModel = [
    //   { colId: 'contact', sort: 'asc' },
    //   { colId: 'item_id', sort: 'asc' },
    //   { colId: 'feedback_date', sort: 'asc' },
    // ];
    // this.gridApi.setSortModel(defaultSortModel);
  }
  handleClick = (params) => {
    let url = '/apps/edit/' + params.data.id
    // this.props.history.push(url)
  }
  handleEditCat = (params) => {
    // this.props.openModal('editForm')
  }
  closeEditCat() {
    // this.props.closeModal('editForm')
  }
  rowSelected(event) {
    // console.log(event)
    // return document.querySelector(cssSelector).checked === true
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById('quickFilter').value)
  }
  onSelectionChanged(event) {
    this.setState({ enableEditCat: !event.api.getSelectedNodes().length >= 1 })
    let selectedRows = this.gridApi.getSelectedRows()
    let items = []
    selectedRows.forEach(function (selectedRow, index) {
      items.push(selectedRow)
    })
    this.setState({ selectedRowsApps: items })
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked }, function () {
      this.gridOptions.api.onFilterChanged()
      this.externalFilterChanged(name)
    })
  }
  externalFilterChanged() {
    this.gridOptions.api.onFilterChanged()
  }
  doesExternalFilterPass = (node) => {
    let status = node.data.archive === this.state.showArchive
    return status
  }
  isExternalFilterPresent() {
    return this.state.showArchive != 'false'
  }
  openPopup = () => {
    this.setState({ open: !this.state.open })
  }
  handleClose = () => {
    this.setState({ open: false })
  }

  handleScroll = (event) => {
    let apiCallTriggered = false
    const { target } = event
    if (
      target.scrollTop > this.state.scrollTop &&
      this.state.page < this.state.totalPages
    ) {
      const isAtBottom =
        target.scrollHeight - target.scrollTop - 10 <= target.clientHeight
      if (isAtBottom && !apiCallTriggered) {
        apiCallTriggered = true
        this.props.fetchContent(
          this.state.page + 1,
          this.state.searchedId,
          this.state.feedbackRating,
          this.state.contact,
          this.state.contentTitle,
        )
        this.setState((prevState) => ({
          page: prevState.page + 1,
        }))
      } else if (!isAtBottom) {
        apiCallTriggered = false
      }
    }
    this.setState({
      scrollTop: target.scrollTop,
    })
  }

  handleCheckboxChange = (index, item) => {
    this.setState((prevState) => {
      const updatedRowChecked = { ...prevState.rowChecked }
      updatedRowChecked[index] = !updatedRowChecked[index]
      const selectedRows = updatedRowChecked[index]
        ? [...prevState.selectedRows, item]
        : prevState.selectedRows.filter((selectedItem) => selectedItem !== item)
      return {
        rowChecked: updatedRowChecked,
        selectedRows: selectedRows,
      }
    })
  }

  handleExportRows = () => {
    const { selectedRows, rowData } = this.state
    const columnNames = [
      'Owner',
      'ID',
      'Title',
      'Page Helpful?',
      'FB Date',
      'Feedback Comments',
      'Content Owner Action',
      'Resolved',
      'Last Edited',
      'Owner Action Date',
      'Site Type',
      'Topic/ App/Rpt',
      'Content Type',
      'Abstract',
      'Last Modified Date',
      'External/Internal User',
      'BAAID',
    ]
    const headerRow = columnNames.join(',')

    const alignRows = (value) => {
      if (value === null || value === undefined) {
        return ''
      }
      return `"${String(value).replace(/"/g, '""')}"`
    }

    const dataRows = (selectedRows.length > 0 ? selectedRows : rowData).map(
      (row) => {
        const rowValues = [
          alignRows(row.contact),
          alignRows(row.item_id),
          alignRows(row.content_title),
          alignRows(row.content_is_helpful),
          alignRows(row.feedback_date),
          alignRows(row.feedback_comments),
          alignRows(row.author_comment),
          alignRows(row.resolved),
          alignRows(row.last_edited),
          alignRows(row.author_comment_date),
          alignRows(row.site_type),
          alignRows(row.topic),
          alignRows(row.content_type),
          alignRows(row.content_abstract),
          alignRows(row.last_modified_date),
          alignRows(row.is_internal),
          alignRows(row.baa_id),
        ]
        return rowValues.join(',')
      },
    )

    const csvData = [headerRow, ...dataRows].join('\r\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'Pol_ContentFeedback.csv'
    a.click()
    window.URL.revokeObjectURL(url)
  }

  handleFieldClick = (index, field) => {
    if (field === 0) {
      this.setState({ editingAuthorComments: index })
    } else {
      this.setState({ editingResolved: index })
    }
  }

  toggleFilterOptions = (filterKey) => {
    this.setState((prevState) => ({
      [filterKey]: !prevState[filterKey],
    }))
  }

  handleFilterKeyPress = (event, filterType) => {
    if (event.key === 'Enter') {
      const filterValue = event.target.value
      const newState = {
        rowData: [],
        page: 0,
        selectedRows: [],
        rowChecked: {},
      }

      switch (filterType) {
        case 'ID':
          newState.searchedId = filterValue
          break
        case 'PageHelpful':
          newState.feedbackRating = filterValue
          break
        case 'Contact':
          newState.contact = filterValue
          break
        case 'Title':
          newState.contentTitle = filterValue
          break
        default:
          break
      }

      this.setState(newState, () => {
        this.props.fetchContent(
          0,
          this.state.searchedId,
          this.state.feedbackRating,
          this.state.contact,
          this.state.contentTitle,
        )
      })

      this.setState({
        showFilterOptions: false,
        showFilterOptionsPageHelpful: false,
        showFilterOptionsContact: false,
        showFilterOptionsTitle: false,
      })
    }
  }

  toggleSortOrder = () => {
    this.setState(
      (prevState) => ({
        sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
      }),
      () => {
        this.sortContentFeedback()
      },
    )
  }

  sortContentFeedback = () => {
    if (this.props.contents && this.props.contents.contentFeedback) {
      const allContentFeedback = [...this.state.rowData]
      const { sortOrder } = this.state
      let sortedData = allContentFeedback.slice()
      sortedData.sort((a, b) => {
        const dateA = moment(a.feedback_date, 'MM/DD/YY hh:mm a')
        const dateB = moment(b.feedback_date, 'MM/DD/YY hh:mm a')
        if (sortOrder === 'asc') {
          return dateB - dateA
        } else {
          return dateA - dateB
        }
      })
      this.setState({
        rowData: sortedData,
      })
    }
  }

  handleInput = (item, editedValue) => {
    const { rowData } = this.state
    if (editedValue !== item.author_comment) {
      const modifiedItem = {
        ...item,
        author_comment: editedValue,
      }
      this.props.editContentFeedback(modifiedItem)
      const updatedRowData = [...rowData]
      const indexToUpdate = updatedRowData.findIndex(
        (row) => row.id === item.id,
      )
      updatedRowData[indexToUpdate] = modifiedItem
      this.setState({
        rowData: updatedRowData,
      })
    }
    this.setState({
      editingAuthorComments: -1,
      isEdited: false,
    })
  }

  handleClearingFilters = () => {
    this.setState(
      {
        rowData: [],
        page: 0,
        searchedId: null,
        feedbackRating: null,
        contact: null,
        contentTitle: null,
      },
      () => {
        this.props.fetchContent(
          0,
          this.state.searchedId,
          this.state.feedbackRating,
          this.state.contact,
          this.state.contentTitle,
        )
      },
    )
  }

  handleResolvedInput = (item) => {
    const newValue = this.state.resolved
    const updatedItem = { ...item, resolved: newValue }
    this.props.editContentFeedback(updatedItem)
    this.setState({ editingResolved: -1 })

    const updatedRowData = this.state.rowData.map((rowDataItem) => {
      if (rowDataItem === item) {
        return updatedItem
      }
      return rowDataItem
    })
    this.setState({ rowData: updatedRowData })
  }

  handleResolvedChange = (e) => {
    const newValue = e.target.value
    this.setState({ resolved: newValue })
  }

  render() {
    const { classes, contents } = this.props
    const {
      readOnly,
      rowData,
      page,
      rowChecked,
      editingAuthorComments,
      editingResolved,
      searchedId,
      feedbackRating,
      contact,
      contentTitle,
    } = this.state
    const appliedFilters =
      searchedId || feedbackRating || contact || contentTitle
    const hostUrl = apiConfig.hostUrl
    const sharePointGeneralUrl = apiConfig.sharePoint.General
    return (
      <div className={classes.root}>
        <HeaderTitle title="Content Feedback" />
        <Helmet>
          <title>Content Feedback</title>
        </Helmet>
        <Button
          disabled={readOnly}
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={this.openPopup}
        >
          Training
        </Button>
        <Button
          disabled={readOnly}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleExportRows}
        >
          <Download className={classes.buttonIcon} />
          Export
        </Button>
        {appliedFilters && (
          <Button
            disabled={readOnly}
            variant="contained"
            color="primary"
            className={classes.button}
            style={{
              backgroundColor: 'lightgray',
              color: 'black',
              marginLeft: '1334px',
            }}
            onClick={this.handleClearingFilters}
          >
            Clear Filters
            <Close className={classes.buttonIcon} />
          </Button>
        )}
        <Modal
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.paper}>
            <Button
              disableRipple
              aria-label={`Close Model`}
              onClick={this.handleClose.bind(this)}
              className={classes.closeButton}
            >
              <Close className={classes.closeIconStyle} />
            </Button>
            <ul className={classes.popup}>
              {/* <li>
                POL Content Feedback Video -
                <p>
                  <iframe
                    src="https://player.vimeo.com/video/716130915?h=85171f566f"
                    width="640"
                    height="312"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  />
                </p>
              </li> */}
              <li>
                <a
                  href="https://targetonline.sharepoint.com/:w:/r/sites/MerchandisingWebContributors/POL%20Analytics/Using%20the%20POL%20Feedback%20Report.docx?d=w8bacd22f9a564255855eebdc26bfbc33&csf=1&web=1&e=gBycVz"
                  target="_blank"
                >
                  Using the POL Feedback Report
                </a>
              </li>
              <li>
                <a
                  href="https://targetonline.sharepoint.com/sites/MerchandisingWebContributors/Shared%20Documents/Forms/AllItems.aspx?viewid=6cf6c567%2D35df%2D4b56%2Db36c%2D80451414dc86&id=%2Fsites%2FMerchandisingWebContributors%2FShared%20Documents%2FTraining"
                  target="_blank"
                >
                  Partners Online Web Contributors Training
                </a>
              </li>
              <li>
                Post questions in Slack channel{' '}
                <a
                  href="https://target.slack.com/archives/C02LZ875XDF"
                  target="_blank"
                >
                  #pol_web_contributors
                </a>
              </li>
            </ul>
          </div>
        </Modal>
        {contents.loading &&
        contents.loading.contentListLoading &&
        page === 0 &&
        !appliedFilters ? (
          <CircularProgress size={50} className={classes.buttonProgress} />
        ) : !appliedFilters && contents.apiError ? (
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.boxDiv}>
              <p>
                An error occurred while retrieving Content Feedback. Please
                contact support if this issue persists.
              </p>
            </Grid>
          </Grid>
        ) : contents.contentFeedback ? (
          <div className={[classes.tableSize, 'ag-theme-material'].join(' ')}>
            <div
              className={
                contents.loading && contents.loading.contentListLoading
                  ? [classes.tableContainer, classes.dimmedOverlay].join(' ')
                  : classes.tableContainer
              }
              onScroll={this.handleScroll}
            >
              {contents.loading && contents.loading.contentListLoading && (
                <CircularProgress
                  size={50}
                  className={classes.buttonProgress}
                />
              )}
              <table className={classes.feedbackTable}>
                <thead>
                  <tr className={classes.headerRow}>
                    <th colspan="2" className={classes.ownerHeader}>
                      Owner
                      <div
                        className={`${classes.filterListIcon} ${classes.ownerField}`}
                        onClick={() =>
                          this.toggleFilterOptions('showFilterOptionsContact')
                        }
                      >
                        <FilterListIcon />
                      </div>
                      {this.state.showFilterOptionsContact && (
                        <input
                          className={classes.filterInputBox}
                          id="filterText"
                          type="text"
                          placeholder="Filter..."
                          defaultValue={this.state.contact || ''}
                          onKeyDown={(event) =>
                            this.handleFilterKeyPress(event, 'Contact')
                          }
                        ></input>
                      )}
                    </th>
                    <th className={classes.tableCells}>
                      ID
                      <div
                        className={classes.filterListIcon}
                        onClick={() =>
                          this.toggleFilterOptions('showFilterOptions')
                        }
                      >
                        <FilterListIcon />
                      </div>
                      {this.state.showFilterOptions && (
                        <div>
                          <input
                            className={classes.filterInputBox}
                            id="filterText"
                            type="text"
                            placeholder="Filter..."
                            defaultValue={this.state.searchedId || ''}
                            onKeyDown={(event) =>
                              this.handleFilterKeyPress(event, 'ID')
                            }
                          ></input>
                        </div>
                      )}
                    </th>
                    <th className={classes.tableCells}>
                      Title
                      <div
                        className={classes.filterListIcon}
                        onClick={() =>
                          this.toggleFilterOptions('showFilterOptionsTitle')
                        }
                        style={{ marginLeft: '252px' }}
                      >
                        <FilterListIcon />
                      </div>
                      {this.state.showFilterOptionsTitle && (
                        <input
                          className={classes.filterInputBox}
                          style={{ marginLeft: '110px' }}
                          id="filterText"
                          type="text"
                          placeholder="Filter..."
                          defaultValue={this.state.contentTitle || ''}
                          onKeyDown={(event) =>
                            this.handleFilterKeyPress(event, 'Title')
                          }
                        ></input>
                      )}
                    </th>
                    <th className={classes.tableCells} style={{ width: '4%' }}>
                      Page Helpful?
                      <div
                        className={classes.filterListIcon}
                        style={{ marginLeft: '18px', marginTop: '-24px' }}
                        onClick={() =>
                          this.toggleFilterOptions(
                            'showFilterOptionsPageHelpful',
                          )
                        }
                      >
                        <FilterListIcon />
                      </div>
                      {this.state.showFilterOptionsPageHelpful && (
                        <div>
                          <select
                            className={classes.filterInputBox}
                            id="filterText"
                            defaultValue={this.state.feedbackRating || ''}
                            onKeyDown={(event) =>
                              this.handleFilterKeyPress(event, 'PageHelpful')
                            }
                            placeholder="Filter..."
                          >
                            <option value="">Select:</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      )}
                    </th>
                    <th
                      className={classes.tableCells}
                      onClick={this.toggleSortOrder}
                    >
                      FB Date
                      <div
                        className={classes.filterListIcon}
                        style={{ marginRight: '-74px', marginBottom: '-7px' }}
                      >
                        {this.state.sortOrder === 'asc' ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                      </div>
                    </th>
                    <th className={classes.tableCells}>Feedback Comments</th>
                    <th className={classes.tableCells}>Content Owner Action</th>
                    <th className={classes.tableCells}>Resolved</th>
                    <th className={classes.tableCells}>Last Edited</th>
                    <th className={classes.tableCells}>Owner Action Date</th>
                    <th className={classes.tableCells}>Site Type</th>
                    <th className={classes.tableCells}>Topic/App/Rpt</th>
                    <th className={classes.tableCells}>Content Type</th>
                    <th className={classes.tableCells}>Abstract</th>
                    <th className={classes.tableCells}>Last Modified Date</th>
                    <th className={classes.tableCells}>Is Internal</th>
                  </tr>
                </thead>
                {appliedFilters && contents.apiError ? (
                  <tbody>
                    <td colSpan="10" className={classes.filterError}>
                      No results found. Try changing your filters.
                    </td>
                  </tbody>
                ) : (
                  <tbody>
                    {rowData.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          rowChecked[index]
                            ? [
                                classes.tableFields,
                                classes.tableCellsSelected,
                              ].join(' ')
                            : classes.tableFields
                        }
                      >
                        <td>
                          <input
                            type="checkbox"
                            onClick={() =>
                              this.handleCheckboxChange(index, item)
                            }
                          />
                        </td>
                        <td
                          style={{
                            paddingLeft: '15px',
                            borderRight: '1px ridge rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {item.contact}
                        </td>
                        <td className={classes.tableCells}>{item.item_id}</td>
                        <td
                          className={classes.tableCells}
                          style={{ width: '14%' }}
                        >
                          <a
                            href={
                              item.item_id === DEFAULT_ID
                                ? `${hostUrl}${item.browser_url}`
                                : `${sharePointGeneralUrl}${item.browser_url.split('/Pages')[0]}/Pages/Forms/DispForm.aspx?ID=${item.item_id}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.content_title}
                          </a>
                        </td>
                        <td className={classes.tableCells}>
                          {item.content_is_helpful}
                        </td>
                        <td className={classes.tableCells}>
                          {item.feedback_date}
                        </td>
                        <td className={classes.tableCells}>
                          {item.feedback_comments}
                        </td>
                        <td
                          className={classes.tableCells}
                          name="authorComments"
                          style={{ backgroundColor: '#f9ebd7', width: '150px' }}
                          onClick={() => this.handleFieldClick(index, 0)}
                        >
                          {editingAuthorComments === index ? (
                            <div
                              contentEditable={true}
                              onBlur={(e) =>
                                this.handleInput(item, e.target.innerText)
                              }
                            >
                              {item.author_comment}
                            </div>
                          ) : (
                            item.author_comment
                          )}
                        </td>
                        <td
                          className={`${classes.tableCells} ${classes.resolvedField}`}
                        >
                          <div
                            contentEditable={true}
                            onClick={() => this.handleFieldClick(index, 1)}
                          >
                            {editingResolved === index ? (
                              <select
                                value={this.state.resolved}
                                onChange={(e) =>
                                  this.handleResolvedChange(e, item)
                                }
                                onBlur={() => this.handleResolvedInput(item)}
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </select>
                            ) : item.resolved ? (
                              item.resolved
                            ) : (
                              'No'
                            )}
                          </div>
                        </td>
                        <td
                          className={classes.tableCells}
                          style={{ width: '90px' }}
                        >
                          {item.last_edited}
                        </td>
                        <td
                          className={classes.tableCells}
                          style={{ width: '90px' }}
                        >
                          {item.author_comment_date}
                        </td>
                        <td className={classes.tableCells}>{item.site_type}</td>
                        <td className={classes.tableCells}>{item.topic}</td>
                        <td className={classes.tableCells}>
                          {item.content_type}
                        </td>
                        <td className={classes.tableCells}>
                          {item.content_abstract}
                        </td>
                        <td className={classes.tableCells}>
                          {item.last_modified_date}
                        </td>
                        <td className={classes.tableCells}>
                          {item.is_internal}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        ) : (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <p className={classes.boxDiv}>
                <Error className={classes.icon} /> There are no Content
                Feedback.
              </p>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

ContentFeedback.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchContent: PropTypes.func,
  editContentFeedback: PropTypes.func,
  ContentFeedback: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  appsAndReports: PropTypes.object,
  contents: PropTypes.object,
  config: PropTypes.object,
}

const mapStateToProps = (state) => {
  const { contents, appsAndReports, config } = state
  return {
    contents,
    appsAndReports,
    config,
  }
}

export default connect(mapStateToProps, { fetchContent, editContentFeedback })(
  withStyles(styles)(ContentFeedback),
)
