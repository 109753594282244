import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles, Grid, TextField, Switch } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider/Divider'
import { Button } from 'nicollet-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import HeaderTitle from '../../components/Header/HeaderTitle'
import {
  fetchFields,
  updateFields,
  getSearchReportData,
  getSummaryReportData
} from '../../store/SearchFieldWeightage/actionCreator'

const styles = {
  root: {
    flexGrow: 1,
    width: '99%'
  },
  progress: {
    marginLeft: '4%',
    marginTop: '4%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  marginContainer: {
    marginLeft: '4%'
  },
  firstContainer: {
    height: '910px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 12,
      background: '#F5F5F5',
      border: '1px solid #c6c6c6'
    },
    '&::-webkit-scrollbar-track': {
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.1)',
      border: '1px solid #c6c6c6'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#fff'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: '#9e9e9e'
    }
  },
  paper: {
    marginTop: '20px',
    height: '910px'
  },
  paperGrid: {
    width: '1078px',
    marginTop: '20px',
    height: '910px'
  },
  reports: {
    margin: '30px'
  },
  displayMessage: {
    color: '#c00',
    margin: '-17px 0px 0px 249px ',
    fontSize: '14px'
  },
  erorrMessage: {
    color: '#c00',
    margin: '-17px 0px 0px 255px ',
    fontSize: '14px'
  },
  submitButton: {
    marginTop: '50px', 
    marginBottom: '40px'
  },
  uploadOptions: {
    marginBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

class SearchFieldWeightage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: this.props.fields,
      notification: null,
      apiResponse: '',
      selectedFiles: [],
      summaryFiles: [],
      displayMessage: false
    }
    this.updateWeight = this.updateWeight.bind(this)
  }

  componentDidMount() {
    this.props.fetchFields()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields !== this.props.fields) {
      this.setState({ fields: [...this.props.fields] })
    }
  }

  handleKeyPress = event => {
    const { value } = event.target
    if (!/^\d*$/.test(value) || (value < 0 && event.key === 'ArrowDown')) {
      event.preventDefault()
    }
  }

  toggleField(field) {
    const { fields } = this.state
    const updatedFields = fields.map(f => {
      if (f.field_id === field.field_id) {
        return { ...f, checked: !field.checked }
      }
      return f
    })
    this.setState({ fields: updatedFields })
  }

  updateWeight(field, event) {
    let weight = event.target.value
    if (weight < 0) {
      weight = 0
    }
    const { fields } = this.state
    const updatedFields = fields.map(f => {
      if (f.field_id === field.field_id) {
        return { ...f, weight: parseInt(event.target.value) }
      }
      return f
    })
    this.setState({ fields: updatedFields })
  }

  handleUpdateFields = () => {
    const changesArray = Object.values(this.state.fields).map(
      ({ field_id, field_name, weight, flag, checked }) => ({
        field_id,
        field_name,
        weight,
        flag: checked
      })
    )
    this.props.updateFields(changesArray)
  }

  handleAdobeReportChange = event => {
    this.setState({
      selectedFiles: [...this.state.selectedFiles, event.target.files[0]],
      displayMessage: false
    })
  }

  handleMultipleFiles = event => {
    const summaryFiles = event.target.files
    const summaryFilesArray = []

    for (let i = 0; i < summaryFiles.length; i++) {
      summaryFilesArray.push(summaryFiles[i])
    }

    this.setState({ summaryFiles: summaryFilesArray, filesMissingError: false })
  }

  handleButtonClick = () => {
    if (!this.state.selectedFiles[0]) {
      this.setState({ displayMessage: true })
      return
    }
    const formData = new FormData()
    formData.append('adobeReport', this.state.selectedFiles[0])
    this.props.getSearchReportData(formData)
  }

  handleGenerateSummary = () => {
    if (!this.state.summaryFiles[0]) {
      this.setState({ filesMissingError: true })
      return
    }
    const formData = new FormData()
    const { summaryFiles } = this.state
    for (let i = 0; i < summaryFiles.length; i++) {
      formData.append('files', this.state.summaryFiles[i])
    }

    for (const entry of formData.entries()) {
      const [fieldName, fieldValue] = entry
    }
    this.props.getSummaryReportData(formData)
  }

  handleExportFields = () => {
    const headerRow = 'Field Name,Field Weight'
    const enabledFields = this.state.fields.filter(field => field.flag === true)
    const csvContent = `${headerRow}\n${enabledFields
      .map(field => `${field.field_name},${field.weight}`)
      .join('\n')}`
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.setAttribute('download', 'Search_Attributes&Weights.csv')
    downloadLink.click()
  }

  render() {
    const { classes, fieldReducer } = this.props
    const { displayMessage, filesMissingError } = this.state
    const sortedFields = this.state.fields.sort((a, b) =>
      a.field_name.localeCompare(b.field_name)
    )
    let loading = fieldReducer.loading
    let configReportLoading = fieldReducer.uploading
    return (
      <div className={classes.root}>
        <HeaderTitle title="Manage Search Attributes & Weights" />
        <Helmet>
          <title>Search Relevance</title>
        </Helmet>
        <Grid container>
          <Paper className={classes.paperGrid}>
            <div className={classes.firstContainer}>
              <Grid>
                <Grid
                  item
                  xs={8}
                  justifyContent="flex-end"
                  className={classes.marginContainer}
                  style={{ maxWidth: '90%' }}
                >
                  <Grid
                    container
                    style={{ margin: '25px 0px', fontWeight: 500 }}
                  >
                    <Grid item xs={7}>
                      Field Name
                    </Grid>
                    <Grid item xs={3}>
                      Enable
                    </Grid>
                    <Grid item xs={2}>
                      Weight
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  {loading ? (
                    <div>
                      <CircularProgress
                        size={50}
                        className={classes.progress}
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {sortedFields.map(field => (
                        <React.Fragment key={field.field_id}>
                          <Grid container>
                            <Grid item xs={7} style={{ marginTop: 20 }}>
                              {field.field_name}
                            </Grid>
                            <Grid item xs={3} style={{ marginTop: 10 }}>
                              <Switch
                                checked={field.checked}
                                onChange={() => this.toggleField(field)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                type="number"
                                inputProps={{ min: '0' }}
                                onKeyDown={this.handleKeyPress}
                                value={field.weight}
                                onChange={event =>
                                  this.updateWeight(field, event)
                                }
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </React.Fragment>
                      ))}
                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleUpdateFields}
                          style={{ marginTop: 20, marginLeft: 890 }}
                        >
                          Submit
                        </Button>
                        <Button
                          onClick={this.handleExportFields}
                          style={{ marginTop: -65, marginLeft: 800 }}
                        >
                          Export
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>

          <Paper className={classes.paper}>
            <Grid container className={classes.reports}>
              <Grid>
                <h3 style={{ textAlign: 'center', marginBottom: '50px' }}>
                  SEARCH DATA REPORTS
                </h3>
                <hr />
                <h4 className={classes.submitButton}>
                  Config-Specific Search Report:
                </h4>
                <p style={{ textAlign: 'center' }}>
                  Choose the Adobe Report required for Search Report generation.
                </p>
                <div
                  className={classes.uploadOptions}
                  style={{ marginLeft: 40 }}
                >
                  <label htmlFor="adobeReport">Adobe Report:</label>
                  <input
                    type="file"
                    name="adobeReport"
                    id="adobeReport"
                    accept=".csv"
                    onChange={this.handleAdobeReportChange}
                  />
                </div>
                {displayMessage && (
                  <div
                    className={classes.displayMessage}
                  >
                    Choose the file to proceed.
                  </div>
                )}
                <div className={classes.uploadOptions}>
                  <Button
                    variant="contained"
                    color="primary"
                    id="generateReportButton"
                    type="button"
                    onClick={this.handleButtonClick}
                    className={classes.submitButton}
                  >
                    Generate Config-Specific Search Data Report
                  </Button>
                </div>
                {configReportLoading && (
                  <div className={classes.overlay}>
                    <CircularProgress size={50} className={classes.progress} />
                  </div>
                )}
                <hr />
                <div className="container">
                  <h4 className={classes.submitButton}>
                    Summary Report comparing multiple Configurations:
                  </h4>
                  <p>
                    Choose the multiple Config-Specific Search Reports required
                    for the Summary Report.
                  </p>

                  <div
                    className={classes.uploadOptions}
                    style={{ marginLeft: 40 }}
                  >
                    <label htmlFor="files">Search Reports:</label>
                    <input
                      type="file"
                      name="files"
                      multiple
                      accept=".xlsx"
                      onChange={this.handleMultipleFiles}
                    />
                  </div>
                  {filesMissingError && (
                    <div
                      className={classes.erorrMessage}
                    >
                      Choose the files to proceed.
                    </div>
                  )}
                  <div className={classes.uploadOptions}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.submitButton}
                      onClick={this.handleGenerateSummary}
                    >
                      Generate Summary Report
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    )
  }
}

SearchFieldWeightage.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchFields: PropTypes.func.isRequired,
  updateFields: PropTypes.func.isRequired,
  getSearchReportData: PropTypes.func.isRequired,
  getSummaryReportData: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      weight: PropTypes.number.isRequired
    })
  ).isRequired,
  fieldReducer: PropTypes.func.isRequired,
  uploading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  fields: state.fieldReducer.fields.map(f => ({ ...f, editable: true })),
  fieldReducer: state.fieldReducer,
  uploading: state.uploading,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  fetchFields: () => dispatch(fetchFields()),
  updateFields: changesArray => dispatch(updateFields(changesArray)),
  getSearchReportData: formData => dispatch(getSearchReportData(formData)),
  getSummaryReportData: formData => dispatch(getSummaryReportData(formData))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchFieldWeightage))
