import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'nicollet-react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'
import { withStyles, Grid, TextField, Typography, Button } from '@material-ui/core'
import EditContentFeedbackForm from './EditContentFeedbackForm'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { fetchContentById, editContentFeedback } from './../../store/contentFeedback/actionCreator'

const styles = theme => ({
  
})

class EditContentFeedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    componentDidMount() {
        const {id} = this.props.match.params
        this.props.fetchContentById(id)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.editContent && nextProps.editContent !== this.props.editContent) {
            if (nextProps.editContent.success === true) {
                window.location.href = '/contentFeedback'
            }
        }
    }
    render() {
        const {classes, contents, config, editContentFeedback} = this.props
        const configData = config && config.data
        return (
            <div className={classes.root}>
                <HeaderTitle title="Edit Content Feedback" />
                <Helmet>
                    <title>Edit Content Feedback</title>
                </Helmet>
                <div className={classes.root}>
                    <EditContentFeedbackForm contents={contents} configData={configData} editContentFeedback={editContentFeedback} />
                </div>
            </div>
        )
    }
}

EditContentFeedback.propTypes = {
    classes: PropTypes.object.isRequired,
    contents: PropTypes.object,
    fetchContentById: PropTypes.func,
    match: PropTypes.object,
    config: PropTypes.object,
    editContentFeedback: PropTypes.func,
    editContent: PropTypes.object,
}

const mapStateToProps = state => ({
    contents: state.contentById,
    config: state.config,
    editContent: state.editContent,
})

export default connect(mapStateToProps, {fetchContentById, editContentFeedback})(withStyles(styles)(EditContentFeedback))
