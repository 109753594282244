import React, { Component } from 'react'
import PropTypes from "prop-types"
import { withStyles } from '@material-ui/core/styles'
import { Modal } from 'nicollet-react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography/Typography'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Zoom from '@material-ui/core/Zoom/Zoom'
import WarningIcon from '@material-ui/icons/Warning';
import { connect } from 'react-redux'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { postAppGroupsListCallError } from '../../store/appGroups/actionCreator'

const styles = theme => ({
  progress: {
    marginLeft: '4%',
    marginTop: '4%',
  },
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: 10,
  },
  button: {
    marginLeft: 20,
  },
  containerHeading: {
    margin: '20px 0',
    padding: 10,
    background: '#fde30b',
    width: '99%',
  },
  cancelButton: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#0049db',
    paddingRight: 20,
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  content: {
    color: '#888887',
  },
  captionText: {
    // fontWeight: 500,
    fontSize: 14,
    color: '#666666',
    marginBottom: 5,
  },
  linkDelete: {
    color: '#bb423a',
    textDecoration: 'none',
    fontSize: 14,
  },
  buttonProgress: {
    color: '#FFF',
    marginRight: 10,
  },
  alertBar: {
    backgroundColor: '#ffa000',
    color: '#FFF',
    padding: '5px 5px 15px 10px',
    borderRadius: 4,
    width: '80%',
  },
  alertIcon: {
    top: 10,
    position: 'relative',
    padding: 5,
  },
  errorIcon: {
    color: 'red',
    margin: 8,
  },
  correctIcon: {
    color: 'green',
    margin: 8,
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
})
class AppGroupForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adGroupName: '',
      adGroupId: '',
      adGroupDesc: '',
      isAdd: props.isAdd,
      showConfirmModal: false,
      readOnly: false,
    }
  }
  componentDidMount() {
    if (!this.props.isAdd) {
      this.setState({
        adGroupDesc: this.props.selectedRow.ad_group_desc,
        adGroupId: this.props.selectedRow.ad_group_id,
        adGroupName: this.props.selectedRow.ad_group_name,
      })
    }
    if (this.props.appsAndReports.readOnly !== undefined) {
      this.setState({readOnly: JSON.parse(this.props.appsAndReports.readOnly.read_only)})
      // this.setState({readOnly: true})
    }
  }
  onSave = () => {
    this.props.updateAppGroup(this.state)
  }
  handleChange = name => event => {
    if(name === 'adGroupName'){
      this.props.checkAppGroup(event.target.value)
    }
    this.setState({ [name]: event.target.value });
  }
  handleDeleteModal = () => {
    this.setState(prevState => ({ showConfirmModal: !prevState.showConfirmModal }));
  }
  handleDelete = (id, isMapped) => event => {
    this.props.deleteAdGroups(id, isMapped)
    this.handleDeleteModal()
    if (isMapped === 1) {
      this.props.onClose()
    }
    if (this.state.readOnly) {
      return false
    }
  }
  focusToNewCat = () => {
    this.props.onClose()
    this.props.postAppGroupsListCallError()
  }
  handleBlur = (event) => {
    this.props.checkAppGroup(event.target.value)
  }
  render() {
    const { classes, open = false, appGroupsProps, data, appGroups } = this.props
    const { adGroupName, adGroupDesc, showConfirmModal, adGroupId, readOnly } = this.state
    let type = (this.props.isAdd) ? 'Add' : 'Edit'
    let showDeleteModal = (data.appGroupsDelete.length > 0 && showConfirmModal)
    return (
      <Modal isOpen={open} onClose={this.focusToNewCat} contentStyles={{ maxWidth: '800px' }}>
        {!showDeleteModal && !appGroups.text &&
          <React.Fragment>
            <h3> {type} an LDS AD Group </h3>
            <Divider variant="middle" style={{ margin: '0 0 16px 0' }} />
            <div>
              <Typography className={classes.containerHeading}>Be sure to create LDS group in the LDS system first, and then add it here after so it can be tied to an application</Typography>
              <Grid container lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <TextField
                    id="adGroupName"
                    type="text"
                    label="LDS group Name"
                    value={adGroupName || ''}
                    onChange={this.handleChange('adGroupName')}
                    helperText="This needs to be match the name of the group you already created in LDS itself"
                    variant="outlined"
                    onBlur={this.handleBlur}
                    required
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  {appGroups.loading && appGroups.loading.appGroupLoading && <CircularProgress size={25} className={classes.progress} />}
                  {appGroups.appGroupCheck && appGroups.appGroupCheck.exist && adGroupName && <Typography className={classes.icons}><CancelIcon className={classes.errorIcon} /><span style={{color:'red'}}>Already Exists</span></Typography>}
                  {appGroups.appGroupCheck && !appGroups.appGroupCheck.exist && adGroupName && <CheckCircleIcon className={classes.correctIcon} />}
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <TextField
                    id="adGroupDesc"
                    label="LDS group Description"
                    multiline
                    rows="4"
                    defaultValue={adGroupDesc || ''}
                    className={classes.textField}
                    onChange={this.handleChange('adGroupDesc')}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 20 }} />
                <Grid container>
                  <Grid item xs={6}>
                    {type === 'Edit' &&
                      <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                        <a className={classes.linkDelete} href="#" onClick={this.handleDelete(adGroupId, 0)}>
                          Delete
                    </a>
                      </Typography>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.buttonContainer}>
                      <Button className={classes.cancelButton} onClick={this.props.onClose}>Cancel</Button>
                      <Button variant="contained" color="primary" disabled={!adGroupName || (appGroups.appGroupCheck && appGroups.appGroupCheck.exist) || readOnly}
                        onClick={this.onSave}>
                        Save
                    </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        {showDeleteModal && !appGroups.text &&
          <Zoom in={showDeleteModal} style={{ transitionDelay: showDeleteModal ? '100ms' : '0ms' }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.alertBar}>
                  <WarningIcon className={classes.alertIcon} />
                  {data.appGroupsDelete && <span><b>{data.appGroupsDelete}</b> is Associated with "{adGroupName}" , </span>}
                  Do you want to delete  ? </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <div className={classes.buttonContainer}>
                  <Button className={classes.cancelButton} onClick={this.handleDeleteModal}>No</Button>
                  <Button variant="contained" color="primary" onClick={this.handleDelete(adGroupId, 1)}>
                    {data.loading.appCategoriesDeleteLoading &&
                      <CircularProgress size={20} className={classes.buttonProgress} />
                    } Yes
                </Button>
                </div>
              </Grid>
            </Grid>
          </Zoom>
        }
        {appGroups && appGroups.status &&
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <p className={classes.alertBar}><WarningIcon className={classes.alertIcon} />{appGroups.text} </p>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={this.focusToNewCat}>
                  OK
                </Button>
              </div>
            </Grid>
          </Grid>
        }
      </Modal>

    )
  }
}
AppGroupForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  appGroupsProps: PropTypes.object,
  selectedRow: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  updateAppGroup: PropTypes.func.isRequired,
  deleteAdGroups: PropTypes.func,
  open: PropTypes.bool,
  isAdd: PropTypes.bool,
  appGroups: PropTypes.object,
  postAppGroupsListCallError: PropTypes.func,
  checkAppGroup: PropTypes.func,
  appsAndReports: PropTypes.object,
}

const mapStateToProps = state => {
  const { appGroups, appsAndReports } = state
  return {
    appGroups,
    appsAndReports,
  }
}

const mapDispatchToProps = dispatch => ({
  postAppGroupsListCallError: data => dispatch(postAppGroupsListCallError('', ''))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppGroupForm))
