import axios from "axios";

import {
  REASONFORSUPPORT_API_CALL_INNITIATED,
  REASONFORSUPPORT_API_CALL_SUCCESS,
  REASONFORSUPPORT_API_CALL_FAIL,
  REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED,
  REASONFORSUPPORT_BY_ID_API_CALL_FAIL,
  REASONFORSUPPORT_BY_ID_API_CALL_SUCCESS,
  REASONFORSUPPORT_POST_API_CALL_INNITIATED,
  REASONFORSUPPORT_POST_API_CALL_SUCCESS,
  REASONFORSUPPORT_POST_API_CALL_FAIL,
  REASONFORSUPPORT_POST_API_CALL_ERROR,
  REASONFORSUPPORT_POST_API_CALL_FINAL,
  CHECK_REASON_INITIATED,
  CHECK_REASON_FAIL,
  CHECK_REASON_SUCCESS,
} from "./actionType";

import {
  TEXT_SUCCESS_REASON_FOR_SUPPORT_ADDED,
  TEXT_SUCCESS_REASON_FOR_SUPPORT_UPDATED,
  TEXT_ERROR_DEFAULT,
  TEXT_ERROR_DUPLICATE
} from "../../constants/notifications";
import { showNotification } from "../notification/actionCreator";
import apiConfig from "../../config/apiConfig";

export function fetchReasonForSupports() {
  return function(dispatch) {
    dispatch(
      apiCallInnitiated({
        type: REASONFORSUPPORT_API_CALL_INNITIATED,
        data: []
      })
    );
    return axios
      // .get(`http://10.112.33.77:8081/pol-service/api/v1/admin/admin_reason_for_support_list?hasContact=true`, {headers:{"user-i":"z004zd3"}})
      .get(`${apiConfig.adminApi.host}admin_reason_for_support_list?hasContact=true`, {
        data: {}
      })
      .then(response => {
        dispatch(apiCallSuccess(response.data));
      })
      .catch(error => {
        dispatch(apiCallFail(error));
      });
  };
}

export function apiCallInnitiated(data) {
  return {
    type: REASONFORSUPPORT_API_CALL_INNITIATED,
    data
  };
}
export function apiCallSuccess(data) {
  return {
    type: REASONFORSUPPORT_API_CALL_SUCCESS,
    data
  };
}
export function apiCallFail(error) {
  return {
    type: REASONFORSUPPORT_API_CALL_FAIL,
    error
  };
}
/*************** Fetching By Id *********************/
export function fetchReasonForSupportById(reasonId) {
  let url = `${apiConfig.adminApi.host}admin_reason_for_support/${reasonId}`
  // let url = `http://localhost:8081/pol-service/api/v1/admin/admin_reason_for_support/${reasonId}`
  return function(dispatch) {
    dispatch(
      fetchByIdApiCallInnitiated({
        type: REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED,
        data: []
      })
    );
    return axios
      // .get(`${apiConfig.adminApi.reasonForSupportById}${reasonId}`, {
      .get(url, {
        data: {}
      })
      .then(response => {
        dispatch(fethByIdApiCallSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchByIdApiCallFail(error));
      });
  };
}

export function fetchByIdApiCallInnitiated(data) {
  return {
    type: REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED,
    data
  };
}
export function fethByIdApiCallSuccess(data) {
  return {
    type: REASONFORSUPPORT_BY_ID_API_CALL_SUCCESS,
    data
  };
}
export function fetchByIdApiCallFail(error) {
  return {
    type: REASONFORSUPPORT_BY_ID_API_CALL_FAIL,
    error
  };
}

/*************** Post Reason For Support Form *********************/
export function postReasonForSupport(reasonForSupportObj) {
  let txt = "";
  if (reasonForSupportObj.isEdit) {
    txt = TEXT_SUCCESS_REASON_FOR_SUPPORT_UPDATED;
  } else {
    txt = TEXT_SUCCESS_REASON_FOR_SUPPORT_ADDED;
  }
  let url = `${apiConfig.adminApi.host}post_reason_for_support/`
  // let url = 'http://localhost:8081/pol-service/api/v1/admin/post_reason_for_support/'
  return function(dispatch) {
    dispatch(
      postReasonForSupportInnitiated({
        type: REASONFORSUPPORT_POST_API_CALL_INNITIATED,
        data: []
      })
    );
    return axios
      .post(
        // `${apiConfig.adminApi.postReasonForSupport}`,
        url,
        reasonForSupportObj,
        {}
      )
      .then(response => {
        dispatch(showNotification(true, txt, "success"));
        dispatch(postReasonForSupportSuccess(response.data));
        dispatch(fetchReasonForSupports())
      })
      .catch(error => {
        dispatch(postReasonForSupportFail(error))
        dispatch(postReasonForSupportError(true, error?.response?.data?.meta_info?.error?.message))
        // if(error.response && error.response.status && error.response.status === 400 || 500){
        //   dispatch(postReasonForSupportError(true, reasonForSupportObj.reason_for_support+TEXT_ERROR_DUPLICATE+"Reason For Support."))
        // }else{
        //   dispatch(postReasonForSupportError(true, TEXT_ERROR_DEFAULT))
        // }
      });
  };
}

export function postReasonForSupportInnitiated(data) {
  return {
    type: REASONFORSUPPORT_POST_API_CALL_INNITIATED,
    data
  };
}
export function postReasonForSupportSuccess(data) {
  return {
    type: REASONFORSUPPORT_POST_API_CALL_SUCCESS,
    data
  };
}
export function postReasonForSupportFail(error) {
  return {
    type: REASONFORSUPPORT_POST_API_CALL_FAIL,
    error
  };
}
export function postReasonForSupportError(status, text) {
  return {
    type: REASONFORSUPPORT_POST_API_CALL_ERROR,
    status,
    text
  }
}
export function postReasonForSupportFinal() {
  return {
    type: REASONFORSUPPORT_POST_API_CALL_FINAL,
  }
}

/************************/
export function checkReasonForSupport (data) {
  return function(dispatch) {
    dispatch(checkReasonForSupportInitiated())
    return axios.get(`${apiConfig.adminApi.host}admin_reason_for_support/validate?title=${data}`)
      .then(response => {
        dispatch(checkReasonForSupportSuccess(response.data.result))
      })
      .catch(error => {
        dispatch(checkReasonForSupportFail(error))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
      })
  }
}
export function checkReasonForSupportInitiated(){
  return {
    type: CHECK_REASON_INITIATED
  }
}
export function checkReasonForSupportSuccess(data){
  return {
    type: CHECK_REASON_SUCCESS,
    data,
  }
}
export function checkReasonForSupportFail(error){
  return {
    type: CHECK_REASON_FAIL,
    error,
  }
}
