import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { Helmet } from 'react-helmet'
import Info from "@material-ui/icons/Info";
import TextField from '@material-ui/core/TextField'
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from "prop-types";
import { Modal } from 'nicollet-react'
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import '../../styles/helperText.css'

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import Switch from '@material-ui/core/Switch'
import {
  postReasonForSupport,
  fetchReasonForSupports,
  postReasonForSupportError
} from "../../store/resonForSupport/actionCreator";
import HeaderTitle from '../../components/Header/HeaderTitle'
import ModalContainer from "./ModalContainer";
import { isEmpty } from "../../Utills/reusableFunctions";

const styles = theme => ({
  progress: {
    marginLeft: '4%',
    marginTop: '4%',
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  commonGridStyle: {
    display: "flex",
    marginTop: 5
  },
  textField: {
    marginTop: 15,
  },
  textFieldDisabled: {
    marginTop: 15,
    marginBottom: 30,
  },
  // Grid Styling
  containerStyle: {
    marginTop: "1em",
    border: "1px solid #d6d6d6!important",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)"
  },
  midContainerStyle: {
    marginTop: "1em"
  },
  topContainerStyle: {
    // border: "1px solid #d6d6d6!important",
    boxShadow: "0 1px 0 0 rgba(0,0,0,.1)!important",
    borderRadius: "2px!important",
    padding: "0 20px",
    marginTop: 0,
    display: "flex",
    alignItems: "baseline"
  },

  closeButton: {
    color: "#3a6fd6",
    fontSize: 12,
    "&:focus": {
      outline: "2px dotted #707070",
      background: "transparent"
    },
    "&:hover": {
      background: "transparent"
    }
  },
  closeIconStyle: {
    width: "0.75em"
  },
  saveContainer: {
    position: "relative",
    height: "15em",
    left: "5%",
    top: "10%",
    width: "28%",
    border: "1px solid #d6d6d6!important",
    boxShadow: "0 1px 0 0 rgba(0,0,0,.1)!important",
    borderRadius: "2px!important",
    padding: 20,

    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: 0,
      top: "1em"
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      right: 0,
      top: "1em"
    }
  },
  captionText: {
    // fontWeight: 500,
    fontSize: 14,
    color: "#666666",
    marginBottom: 5
  },
  publishContentFinal: {
    color: "#b85300",
    marginLeft: 10,
    marginTop: 2
  },
  saveButtonStyle: {
    background: "#0049DB",
    color: "#fff"
    // marginTop: '1em',
  },
  contentTitle: {
    fontWeight: 500,
    fontSize: 24,
    color: "#333333",
    marginTop: "1em"
  },
  buttonDisabled: {},
  contentSubTitle: {
    fontWeight: 500,
    fontSize: 20,
    color: "#333333",
    // marginTop: "1em"
  },
  alertText: {
    border: '1px solid #b85300',
    margin: '20px 0',
    padding: 10,
    display: 'flex',
  },
  linkArchive: {
    color: '#bb423a',
    textDecoration: 'none',
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  cancelButton: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#0049db',
    marginRight: 20,
  },
  alertBar: {
    backgroundColor: '#ffa000',
    color: '#FFF',
    padding: '5px 5px 15px 10px',
    borderRadius: 4,
  },
  alertIcon: {
    top: 10,
    position: 'relative',
    padding: 5,
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  errorIcon: {
    color: 'red',
    margin: 8,
  },
  correctIcon: {
    color: 'green',
    margin: 8,
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  marginContainer: {
    marginLeft: '2%',
  },
});

class ReasonForSupportForm extends Component {
  // Custom Toolbar for Quill
  toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    // [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ['link'],
    ["clean"] // remove formatting button
  ];

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      reason_for_support: "",
      comments: "",
      email: "",
      destination_system_name: "None",
      published: "",
      labelWidth: 2,
      edit: props.edit,
      showArchiveModal: false,
      archive: false,
      mandatoryField: true,
      apiSuccess: false,
      readOnly: false,
      vendor_number: false,
      vendor_name: false,
      trip_id: false,
      purchase_order: false,
      ship_id: false,
      gf_card_number: false,
      gf_card_name: false,
      credit_number: false,
      load_id: false,
      vendor_number_radio: false,
      vendor_name_radio: false,
      trip_id_radio: false,
      purchase_order_radio: false,
      ship_id_radio: false,
      gf_card_number_radio: false,
      gf_card_name_radio: false,
      credit_number_radio: false,
      load_id_radio: false,
      bp_group: false,
      brand: false,
      buyer_name: false,
      campaign_id: false,
      campaign_name: false,
      capacity_type: false,
      carrier_code: false,
      category: false,
      chargeback_number: false,
      check_number: false,
      commit_number: false,
      contract_number: false,
      country_of_production: false,
      date_range: false,
      department: false,
      dept_class: false,
      dpci_tcin: false,
      ecom_id: false,
      edi_date_of_transmission: false,
      edi_document_type: false,
      edi_isa_control_number: false,
      error_message: false,
      event_id: false,
      event_name: false,
      factory_id: false,
      factory_name: false,
      gf_dataset: false,
      inventory_analyst_name: false,
      invoice_number: false,
      location_id: false,
      master_group_id: false,
      materials: false,
      metrics_impacted: false,
      mfg_id: false,
      page: false,
      planogram_number: false,
      pro_number: false,
      product_details: false,
      product_name: false,
      project_id: false,
      report_name: false,
      report_type: false,
      ship_node: false,
      store_number: false,
      studio_name: false,
      target_distribution_center_number: false,
      template_name: false,
      time_error_occurred: false,
      trailer_number: false,
      view_name: false,
      weeks: false,
      workspace_id: false,
      accounts_payable_workflow: false,
      violation_id: false,
      chargeback_reason_code: false,

      bp_group_radio: false,
      brand_radio: false,
      buyer_name_radio: false,
      campaign_id_radio: false,
      campaign_name_radio: false,
      capacity_type_radio: false,
      carrier_code_radio: false,
      category_radio: false,
      chargeback_number_radio: false,
      check_number_radio: false,
      commit_number_radio: false,
      contract_number_radio: false,
      country_of_production_radio: false,
      date_range_radio: false,
      department_radio: false,
      dept_class_radio: false,
      dpci_tcin_radio: false,
      ecom_id_radio: false,
      edi_date_of_transmission_radio: false,
      edi_document_type_radio: false,
      edi_isa_control_number_radio: false,
      error_message_radio: false,
      event_id_radio: false,
      event_name_radio: false,
      factory_id_radio: false,
      factory_name_radio: false,
      gf_dataset_radio: false,
      inventory_analyst_name_radio: false,
      invoice_number_radio: false,
      location_id_radio: false,
      master_group_id_radio: false,
      materials_radio: false,
      metrics_impacted_radio: false,
      mfg_id_radio: false,
      page_radio: false,
      planogram_number_radio: false,
      pro_number_radio: false,
      product_details_radio: false,
      product_name_radio: false,
      project_id_radio: false,
      report_name_radio: false,
      report_type_radio: false,
      ship_node_radio: false,
      store_number_radio: false,
      studio_name_radio: false,
      target_distribution_center_number_radio: false,
      template_name_radio: false,
      time_error_occurred_radio: false,
      trailer_number_radio: false,
      view_name_radio: false,
      weeks_radio: false,
      workspace_id_radio: false,
      accounts_payable_workflow_radio: false,
      violation_id_radio: false,
      chargeback_reason_code_radio: false,

      notes: {
        vendor_number: '',
        vendor_name: '',
        trip_id: '',
        purchase_order: '',
        ship_id: '',
        gf_card_number: '',
        gf_card_name: '',
        credit_number: '',
        load_id: '',
        bp_group: '',
        brand: '',
        buyer_name: '',
        campaign_id: '',
        campaign_name: '',
        capacity_type: '',
        carrier_code: '',
        category: '',
        chargeback_number: '',
        check_number: '',
        commit_number: '',
        contract_number: '',
        country_of_production: '',
        date_range: '',
        department: '',
        dept_class: '',
        dpci_tcin: '',
        ecom_id: '',
        edi_date_of_transmission: '',
        edi_document_type: '',
        edi_isa_control_number: '',
        error_message: '',
        event_id: '',
        event_name: '',
        factory_id: '',
        factory_name: '',
        gf_dataset: '',
        inventory_analyst_name: '',
        invoice_number: '',
        location_id: '',
        master_group_id: '',
        materials: '',
        metrics_impacted: '',
        mfg_id: '',
        page: '',
        planogram_number: '',
        pro_number: '',
        product_details: '',
        product_name: '',
        project_id: '',
        report_name: '',
        report_type: '',
        ship_node: '',
        store_number: '',
        studio_name: '',
        target_distribution_center_number: '',
        template_name: '',
        time_error_occurred: '',
        trailer_number: '',
        view_name: '',
        weeks: '',
        workspace_id: '',
        accounts_payable_workflow: '',
        violation_id: '',
        chargeback_reason_code: '',
      },
    };
  }

  componentDidMount() {
    if (this.props.reasonForSupportObj && this.props.reasonForSupportObj.id != "") {
      this.setState({ id: this.props.reasonForSupportObj.id });
    } else {
      id: "";
    }

    if (this.props.reasonForSupportObj && this.props.reasonForSupportObj.id) {
      this.setState({
        reason_for_support: this.props.reasonForSupportObj.reason_for_support,
        comments: this.props.reasonForSupportObj.comments,
        email: this.props.reasonForSupportObj.email,
        destination_system_name: this.props.reasonForSupportObj
          .destination_system_name,
        published: this.props.reasonForSupportObj.published,
        vendor_number: this.props.reasonForSupportObj.vendor_number === 'false' ? false : true,
        vendor_name: this.props.reasonForSupportObj.vendor_name === 'false' ? false : true,
        trip_id: this.props.reasonForSupportObj.trip_id === 'false' ? false : true,
        purchase_order: this.props.reasonForSupportObj.purchase_order === 'false' ? false : true,
        ship_id: this.props.reasonForSupportObj.ship_id === 'false' ? false : true,
        gf_card_number: this.props.reasonForSupportObj.gf_card_number === 'false' ? false : true,
        gf_card_name: this.props.reasonForSupportObj.gf_card_name === 'false' ? false : true,
        credit_number: this.props.reasonForSupportObj.credit_number === 'false' ? false : true,
        load_id: this.props.reasonForSupportObj.load_id === 'false' ? false : true,
        bp_group: this.props.reasonForSupportObj.bp_group === 'false' ? false : true,
        brand: this.props.reasonForSupportObj.brand === 'false' ? false : true,
        buyer_name: this.props.reasonForSupportObj.buyer_name === 'false' ? false : true,
        campaign_id: this.props.reasonForSupportObj.campaign_id === 'false' ? false : true,
        campaign_name: this.props.reasonForSupportObj.campaign_name === 'false' ? false : true,
        capacity_type: this.props.reasonForSupportObj.capacity_type === 'false' ? false : true,
        carrier_code: this.props.reasonForSupportObj.carrier_code === 'false' ? false : true,
        category: this.props.reasonForSupportObj.category === 'false' ? false : true,
        chargeback_number: this.props.reasonForSupportObj.chargeback_number === 'false' ? false : true,
        check_number: this.props.reasonForSupportObj.check_number === 'false' ? false : true,
        commit_number: this.props.reasonForSupportObj.commit_number === 'false' ? false : true,
        contract_number: this.props.reasonForSupportObj.contract_number === 'false' ? false : true,
        country_of_production: this.props.reasonForSupportObj.country_of_production === 'false' ? false : true,
        date_range: this.props.reasonForSupportObj.date_range === 'false' ? false : true,
        department: this.props.reasonForSupportObj.department === 'false' ? false : true,
        dept_class: this.props.reasonForSupportObj.dept_class === 'false' ? false : true,
        dpci_tcin: this.props.reasonForSupportObj.dpci_tcin === 'false' ? false : true,
        ecom_id: this.props.reasonForSupportObj.ecom_id === 'false' ? false : true,
        edi_date_of_transmission: this.props.reasonForSupportObj.edi_date_of_transmission === 'false' ? false : true,
        edi_document_type: this.props.reasonForSupportObj.edi_document_type === 'false' ? false : true,
        edi_isa_control_number: this.props.reasonForSupportObj.edi_isa_control_number === 'false' ? false : true,
        error_message: this.props.reasonForSupportObj.error_message === 'false' ? false : true,
        event_id: this.props.reasonForSupportObj.event_id === 'false' ? false : true,
        event_name: this.props.reasonForSupportObj.event_name === 'false' ? false : true,
        factory_id: this.props.reasonForSupportObj.factory_id === 'false' ? false : true,
        factory_name: this.props.reasonForSupportObj.factory_name === 'false' ? false : true,
        gf_dataset: this.props.reasonForSupportObj.gf_dataset === 'false' ? false : true,
        inventory_analyst_name: this.props.reasonForSupportObj.inventory_analyst_name === 'false' ? false : true,
        invoice_number: this.props.reasonForSupportObj.invoice_number === 'false' ? false : true,
        location_id: this.props.reasonForSupportObj.location_id === 'false' ? false : true,
        master_group_id: this.props.reasonForSupportObj.master_group_id === 'false' ? false : true,
        materials: this.props.reasonForSupportObj.materials === 'false' ? false : true,
        metrics_impacted: this.props.reasonForSupportObj.metrics_impacted === 'false' ? false : true,
        mfg_id: this.props.reasonForSupportObj.mfg_id === 'false' ? false : true,
        page: this.props.reasonForSupportObj.page === 'false' ? false : true,
        planogram_number: this.props.reasonForSupportObj.planogram_number === 'false' ? false : true,
        pro_number: this.props.reasonForSupportObj.pro_number === 'false' ? false : true,
        product_details: this.props.reasonForSupportObj.product_details === 'false' ? false : true,
        product_name: this.props.reasonForSupportObj.product_name === 'false' ? false : true,
        project_id: this.props.reasonForSupportObj.project_id === 'false' ? false : true,
        report_name: this.props.reasonForSupportObj.report_name === 'false' ? false : true,
        report_type: this.props.reasonForSupportObj.report_type === 'false' ? false : true,
        ship_node: this.props.reasonForSupportObj.ship_node === 'false' ? false : true,
        store_number: this.props.reasonForSupportObj.store_number === 'false' ? false : true,
        studio_name: this.props.reasonForSupportObj.studio_name === 'false' ? false : true,
        target_distribution_center_number: this.props.reasonForSupportObj.target_distribution_center_number === 'false' ? false : true,
        template_name: this.props.reasonForSupportObj.template_name === 'false' ? false : true,
        time_error_occurred: this.props.reasonForSupportObj.time_error_occurred === 'false' ? false : true,
        trailer_number: this.props.reasonForSupportObj.trailer_number === 'false' ? false : true,
        view_name: this.props.reasonForSupportObj.view_name === 'false' ? false : true,
        weeks: this.props.reasonForSupportObj.weeks === 'false' ? false : true,
        workspace_id: this.props.reasonForSupportObj.workspace_id === 'false' ? false : true,
        accounts_payable_workflow: this.props.reasonForSupportObj.accounts_payable_workflow === 'false' ? false : true,
        violation_id: this.props.reasonForSupportObj.violation_id === 'false' ? false : true,
        chargeback_reason_code: this.props.reasonForSupportObj.chargeback_reason_code === 'false' ? false : true,

        vendor_number_radio: this.props.reasonForSupportObj.vendor_number === 'required' ? true : false,
        vendor_name_radio: this.props.reasonForSupportObj.vendor_name === 'required' ? true : false,
        trip_id_radio: this.props.reasonForSupportObj.trip_id === 'required' ? true: false,
        purchase_order_radio: this.props.reasonForSupportObj.purchase_order === 'required' ? true: false,
        ship_id_radio: this.props.reasonForSupportObj.ship_id === 'required' ? true: false,
        gf_card_number_radio: this.props.reasonForSupportObj.gf_card_number === 'required' ? true: false,
        gf_card_name_radio: this.props.reasonForSupportObj.gf_card_name === 'required' ? true: false,
        credit_number_radio: this.props.reasonForSupportObj.credit_number === 'required' ? true: false,
        load_id_radio: this.props.reasonForSupportObj.load_id === 'required' ? true : false,
        bp_group_radio: this.props.reasonForSupportObj.bp_group === 'required' ? true : false,
        brand_radio: this.props.reasonForSupportObj.brand === 'required' ? true : false,
        buyer_name_radio: this.props.reasonForSupportObj.buyer_name === 'required' ? true : false,
        campaign_id_radio: this.props.reasonForSupportObj.campaign_id === 'required' ? true : false,
        campaign_name_radio: this.props.reasonForSupportObj.campaign_name === 'required' ? true : false,
        capacity_type_radio: this.props.reasonForSupportObj.capacity_type === 'required' ? true : false,
        carrier_code_radio: this.props.reasonForSupportObj.carrier_code === 'required' ? true : false,
        category_radio: this.props.reasonForSupportObj.category === 'required' ? true : false,
        chargeback_number_radio: this.props.reasonForSupportObj.chargeback_number === 'required' ? true : false,
        check_number_radio: this.props.reasonForSupportObj.check_number === 'required' ? true : false,
        commit_number_radio: this.props.reasonForSupportObj.commit_number === 'required' ? true : false,
        contract_number_radio: this.props.reasonForSupportObj.contract_number === 'required' ? true : false,
        country_of_production_radio: this.props.reasonForSupportObj.country_of_production === 'required' ? true : false,
        date_range_radio: this.props.reasonForSupportObj.date_range === 'required' ? true : false,
        department_radio: this.props.reasonForSupportObj.department === 'required' ? true : false,
        dept_class_radio: this.props.reasonForSupportObj.dept_class === 'required' ? true : false,
        dpci_tcin_radio: this.props.reasonForSupportObj.dpci_tcin === 'required' ? true : false,
        ecom_id_radio: this.props.reasonForSupportObj.ecom_id === 'required' ? true : false,
        edi_date_of_transmission_radio: this.props.reasonForSupportObj.edi_date_of_transmission === 'required' ? true : false,
        edi_document_type_radio: this.props.reasonForSupportObj.edi_document_type === 'required' ? true : false,
        edi_isa_control_number_radio: this.props.reasonForSupportObj.edi_isa_control_number === 'required' ? true : false,
        error_message_radio: this.props.reasonForSupportObj.error_message === 'required' ? true : false,
        event_id_radio: this.props.reasonForSupportObj.event_id === 'required' ? true : false,
        event_name_radio: this.props.reasonForSupportObj.event_name === 'required' ? true : false,
        factory_id_radio: this.props.reasonForSupportObj.factory_id === 'required' ? true : false,
        factory_name_radio: this.props.reasonForSupportObj.factory_name === 'required' ? true : false,
        gf_dataset_radio: this.props.reasonForSupportObj.gf_dataset === 'required' ? true : false,
        inventory_analyst_name_radio: this.props.reasonForSupportObj.inventory_analyst_name === 'required' ? true : false,
        invoice_number_radio: this.props.reasonForSupportObj.invoice_number === 'required' ? true : false,
        location_id_radio: this.props.reasonForSupportObj.location_id === 'required' ? true : false,
        master_group_id_radio: this.props.reasonForSupportObj.master_group_id === 'required' ? true : false,
        materials_radio: this.props.reasonForSupportObj.materials === 'required' ? true : false,
        metrics_impacted_radio: this.props.reasonForSupportObj.metrics_impacted === 'required' ? true : false,
        mfg_id_radio: this.props.reasonForSupportObj.mfg_id === 'required' ? true : false,
        page_radio: this.props.reasonForSupportObj.page === 'required' ? true : false,
        planogram_number_radio: this.props.reasonForSupportObj.planogram_number === 'required' ? true : false,
        pro_number_radio: this.props.reasonForSupportObj.pro_number === 'required' ? true : false,
        product_details_radio: this.props.reasonForSupportObj.product_details === 'required' ? true : false,
        product_name_radio: this.props.reasonForSupportObj.product_name === 'required' ? true : false,
        project_id_radio: this.props.reasonForSupportObj.project_id === 'required' ? true : false,
        report_name_radio: this.props.reasonForSupportObj.report_name === 'required' ? true : false,
        report_type_radio: this.props.reasonForSupportObj.report_type === 'required' ? true : false,
        ship_node_radio: this.props.reasonForSupportObj.ship_node === 'required' ? true : false,
        store_number_radio: this.props.reasonForSupportObj.store_number === 'required' ? true : false,
        studio_name_radio: this.props.reasonForSupportObj.studio_name === 'required' ? true : false,
        target_distribution_center_number_radio: this.props.reasonForSupportObj.target_distribution_center_number === 'required' ? true : false,
        template_name_radio: this.props.reasonForSupportObj.template_name === 'required' ? true : false,
        time_error_occurred_radio: this.props.reasonForSupportObj.time_error_occurred === 'required' ? true : false,
        trailer_number_radio: this.props.reasonForSupportObj.trailer_number === 'required' ? true : false,
        view_name_radio: this.props.reasonForSupportObj.view_name === 'required' ? true : false,
        weeks_radio: this.props.reasonForSupportObj.weeks === 'required' ? true : false,
        workspace_id_radio: this.props.reasonForSupportObj.workspace_id === 'required' ? true : false,
        accounts_payable_workflow_radio: this.props.reasonForSupportObj.accounts_payable_workflow === 'required' ? true : false,
        violation_id_radio: this.props.reasonForSupportObj.violation_id === 'required' ? true : false,
        chargeback_reason_code_radio: this.props.reasonForSupportObj.chargeback_reason_code === 'required' ? true : false,

        notes: this.props.reasonForSupportObj.notes,
      });
    }
    if (this.props.appsAndReports.readOnly !== undefined) {
      this.setState({ readOnly: JSON.parse(this.props.appsAndReports.readOnly.read_only) })
      // this.setState({readOnly: true})
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.reasonForSupportId && nextProps.reasonForSupportId != "") {
      this.setState({
        id: this.props.reasonForSupportObj.id
      });
    } else {
      id: "";
    }
    if ((this.props.reasonForSupportObj !== nextProps.reasonForSupportObj) && nextProps.reasonForSupportObj.id) {
      this.setState(prevState => ({
        // reason_for_support: nextProps.reasonForSupportObj.reason_for_support,
        reason_for_support: prevState.reason_for_support,
        comments: nextProps.reasonForSupportObj.comments,
        email: nextProps.reasonForSupportObj.email,
        destination_system_name: nextProps.reasonForSupportObj.destination_system_name,
        published: nextProps.reasonForSupportObj.published,
        apiSuccess: nextProps.reasonForSupportPost.apiSuccess,
        vendor_number: nextProps.reasonForSupportObj.vendor_number === 'false' ? false : true,
        vendor_name: nextProps.reasonForSupportObj.vendor_name === 'false' ? false : true,
        trip_id: nextProps.reasonForSupportObj.trip_id === 'false' ? false : true,
        purchase_order: nextProps.reasonForSupportObj.purchase_order === 'false' ? false : true,
        ship_id: nextProps.reasonForSupportObj.ship_id === 'false' ? false : true,
        gf_card_number: nextProps.reasonForSupportObj.gf_card_number === 'false' ? false : true,
        gf_card_name: nextProps.reasonForSupportObj.gf_card_name === 'false' ? false : true,
        credit_number: nextProps.reasonForSupportObj.credit_number === 'false' ? false : true,
        load_id: nextProps.reasonForSupportObj.load_id === 'false' ? false : true,
        vendor_number_radio: nextProps.reasonForSupportObj.vendor_number === 'required' ? true : false,
        vendor_name_radio: nextProps.reasonForSupportObj.vendor_name === 'required' ? true : false,
        trip_id_radio: nextProps.reasonForSupportObj.trip_id === 'required' ? true: false,
        purchase_order_radio: nextProps.reasonForSupportObj.purchase_order === 'required' ? true: false,
        ship_id_radio: nextProps.reasonForSupportObj.ship_id === 'required' ? true: false,
        gf_card_number_radio: nextProps.reasonForSupportObj.gf_card_number === 'required' ? true: false,
        gf_card_name_radio: nextProps.reasonForSupportObj.gf_card_name === 'required' ? true: false,
        credit_number_radio: nextProps.reasonForSupportObj.credit_number === 'required' ? true: false,
        load_id_radio: nextProps.reasonForSupportObj.load_id === 'required' ? true : false,

        bp_group: nextProps.reasonForSupportObj.bp_group === 'false' ? false : true,
        brand: nextProps.reasonForSupportObj.brand === 'false' ? false : true,
        buyer_name: nextProps.reasonForSupportObj.buyer_name === 'false' ? false : true,
        campaign_id: nextProps.reasonForSupportObj.campaign_id === 'false' ? false : true,
        campaign_name: nextProps.reasonForSupportObj.campaign_name === 'false' ? false : true,
        capacity_type: nextProps.reasonForSupportObj.capacity_type === 'false' ? false : true,
        carrier_code: nextProps.reasonForSupportObj.carrier_code === 'false' ? false : true,
        category: nextProps.reasonForSupportObj.category === 'false' ? false : true,
        chargeback_number: nextProps.reasonForSupportObj.chargeback_number === 'false' ? false : true,
        check_number: nextProps.reasonForSupportObj.check_number === 'false' ? false : true,
        commit_number: nextProps.reasonForSupportObj.commit_number === 'false' ? false : true,
        contract_number: nextProps.reasonForSupportObj.contract_number === 'false' ? false : true,
        country_of_production: nextProps.reasonForSupportObj.country_of_production === 'false' ? false : true,
        date_range: nextProps.reasonForSupportObj.date_range === 'false' ? false : true,
        department: nextProps.reasonForSupportObj.department === 'false' ? false : true,
        dept_class: nextProps.reasonForSupportObj.dept_class === 'false' ? false : true,
        dpci_tcin: nextProps.reasonForSupportObj.dpci_tcin === 'false' ? false : true,
        ecom_id: nextProps.reasonForSupportObj.ecom_id === 'false' ? false : true,
        edi_date_of_transmission: nextProps.reasonForSupportObj.edi_date_of_transmission === 'false' ? false : true,
        edi_document_type: nextProps.reasonForSupportObj.edi_document_type === 'false' ? false : true,
        edi_isa_control_number: nextProps.reasonForSupportObj.edi_isa_control_number === 'false' ? false : true,
        error_message: nextProps.reasonForSupportObj.error_message === 'false' ? false : true,
        event_id: nextProps.reasonForSupportObj.event_id === 'false' ? false : true,
        event_name: nextProps.reasonForSupportObj.event_name === 'false' ? false : true,
        factory_id: nextProps.reasonForSupportObj.factory_id === 'false' ? false : true,
        factory_name: nextProps.reasonForSupportObj.factory_name === 'false' ? false : true,
        gf_dataset: nextProps.reasonForSupportObj.gf_dataset === 'false' ? false : true,
        inventory_analyst_name: nextProps.reasonForSupportObj.inventory_analyst_name === 'false' ? false : true,
        invoice_number: nextProps.reasonForSupportObj.invoice_number === 'false' ? false : true,
        location_id: nextProps.reasonForSupportObj.location_id === 'false' ? false : true,
        master_group_id: nextProps.reasonForSupportObj.master_group_id === 'false' ? false : true,
        materials: nextProps.reasonForSupportObj.materials === 'false' ? false : true,
        metrics_impacted: nextProps.reasonForSupportObj.metrics_impacted === 'false' ? false : true,
        mfg_id: nextProps.reasonForSupportObj.mfg_id === 'false' ? false : true,
        page: nextProps.reasonForSupportObj.page === 'false' ? false : true,
        planogram_number: nextProps.reasonForSupportObj.planogram_number === 'false' ? false : true,
        pro_number: nextProps.reasonForSupportObj.pro_number === 'false' ? false : true,
        product_details: nextProps.reasonForSupportObj.product_details === 'false' ? false : true,
        product_name: nextProps.reasonForSupportObj.product_name === 'false' ? false : true,
        project_id: nextProps.reasonForSupportObj.project_id === 'false' ? false : true,
        report_name: nextProps.reasonForSupportObj.report_name === 'false' ? false : true,
        report_type: nextProps.reasonForSupportObj.report_type === 'false' ? false : true,
        ship_node: nextProps.reasonForSupportObj.ship_node === 'false' ? false : true,
        store_number: nextProps.reasonForSupportObj.store_number === 'false' ? false : true,
        studio_name: nextProps.reasonForSupportObj.studio_name === 'false' ? false : true,
        target_distribution_center_number: nextProps.reasonForSupportObj.target_distribution_center_number === 'false' ? false : true,
        template_name: nextProps.reasonForSupportObj.template_name === 'false' ? false : true,
        time_error_occurred: nextProps.reasonForSupportObj.time_error_occurred === 'false' ? false : true,
        trailer_number: nextProps.reasonForSupportObj.trailer_number === 'false' ? false : true,
        view_name: nextProps.reasonForSupportObj.view_name === 'false' ? false : true,
        weeks: nextProps.reasonForSupportObj.weeks === 'false' ? false : true,
        workspace_id: nextProps.reasonForSupportObj.workspace_id === 'false' ? false : true,
        accounts_payable_workflow: nextProps.reasonForSupportObj.accounts_payable_workflow === 'false' ? false : true,
        violation_id: nextProps.reasonForSupportObj.violation_id === 'false' ? false : true,
        chargeback_reason_code: nextProps.reasonForSupportObj.chargeback_reason_code === 'false' ? false : true,

        bp_group_radio: nextProps.reasonForSupportObj.bp_group === 'required' ? true : false,
        brand_radio: nextProps.reasonForSupportObj.brand === 'required' ? true : false,
        buyer_name_radio: nextProps.reasonForSupportObj.buyer_name === 'required' ? true : false,
        campaign_id_radio: nextProps.reasonForSupportObj.campaign_id === 'required' ? true : false,
        campaign_name_radio: nextProps.reasonForSupportObj.campaign_name === 'required' ? true : false,
        capacity_type_radio: nextProps.reasonForSupportObj.capacity_type === 'required' ? true : false,
        carrier_code_radio: nextProps.reasonForSupportObj.carrier_code === 'required' ? true : false,
        category_radio: nextProps.reasonForSupportObj.category === 'required' ? true : false,
        chargeback_number_radio: nextProps.reasonForSupportObj.chargeback_number === 'required' ? true : false,
        check_number_radio: nextProps.reasonForSupportObj.check_number === 'required' ? true : false,
        commit_number_radio: nextProps.reasonForSupportObj.commit_number === 'required' ? true : false,
        contract_number_radio: nextProps.reasonForSupportObj.contract_number === 'required' ? true : false,
        country_of_production_radio: nextProps.reasonForSupportObj.country_of_production === 'required' ? true : false,
        date_range_radio: nextProps.reasonForSupportObj.date_range === 'required' ? true : false,
        department_radio: nextProps.reasonForSupportObj.department === 'required' ? true : false,
        dept_class_radio: nextProps.reasonForSupportObj.dept_class === 'required' ? true : false,
        dpci_tcin_radio: nextProps.reasonForSupportObj.dpci_tcin === 'required' ? true : false,
        ecom_id_radio: nextProps.reasonForSupportObj.ecom_id === 'required' ? true : false,
        edi_date_of_transmission_radio: nextProps.reasonForSupportObj.edi_date_of_transmission === 'required' ? true : false,
        edi_document_type_radio: nextProps.reasonForSupportObj.edi_document_type === 'required' ? true : false,
        edi_isa_control_number_radio: nextProps.reasonForSupportObj.edi_isa_control_number === 'required' ? true : false,
        error_message_radio: nextProps.reasonForSupportObj.error_message === 'required' ? true : false,
        event_id_radio: nextProps.reasonForSupportObj.event_id === 'required' ? true : false,
        event_name_radio: nextProps.reasonForSupportObj.event_name === 'required' ? true : false,
        factory_id_radio: nextProps.reasonForSupportObj.factory_id === 'required' ? true : false,
        factory_name_radio: nextProps.reasonForSupportObj.factory_name === 'required' ? true : false,
        gf_dataset_radio: nextProps.reasonForSupportObj.gf_dataset === 'required' ? true : false,
        inventory_analyst_name_radio: nextProps.reasonForSupportObj.inventory_analyst_name === 'required' ? true : false,
        invoice_number_radio: nextProps.reasonForSupportObj.invoice_number === 'required' ? true : false,
        location_id_radio: nextProps.reasonForSupportObj.location_id === 'required' ? true : false,
        master_group_id_radio: nextProps.reasonForSupportObj.master_group_id === 'required' ? true : false,
        materials_radio: nextProps.reasonForSupportObj.materials === 'required' ? true : false,
        metrics_impacted_radio: nextProps.reasonForSupportObj.metrics_impacted === 'required' ? true : false,
        mfg_id_radio: nextProps.reasonForSupportObj.mfg_id === 'required' ? true : false,
        page_radio: nextProps.reasonForSupportObj.page === 'required' ? true : false,
        planogram_number_radio: nextProps.reasonForSupportObj.planogram_number === 'required' ? true : false,
        pro_number_radio: nextProps.reasonForSupportObj.pro_number === 'required' ? true : false,
        product_details_radio: nextProps.reasonForSupportObj.product_details === 'required' ? true : false,
        product_name_radio: nextProps.reasonForSupportObj.product_name === 'required' ? true : false,
        project_id_radio: nextProps.reasonForSupportObj.project_id === 'required' ? true : false,
        report_name_radio: nextProps.reasonForSupportObj.report_name === 'required' ? true : false,
        report_type_radio: nextProps.reasonForSupportObj.report_type === 'required' ? true : false,
        ship_node_radio: nextProps.reasonForSupportObj.ship_node === 'required' ? true : false,
        store_number_radio: nextProps.reasonForSupportObj.store_number === 'required' ? true : false,
        studio_name_radio: nextProps.reasonForSupportObj.studio_name === 'required' ? true : false,
        target_distribution_center_number_radio: nextProps.reasonForSupportObj.target_distribution_center_number === 'required' ? true : false,
        template_name_radio: nextProps.reasonForSupportObj.template_name === 'required' ? true : false,
        time_error_occurred_radio: nextProps.reasonForSupportObj.time_error_occurred === 'required' ? true : false,
        trailer_number_radio: nextProps.reasonForSupportObj.trailer_number === 'required' ? true : false,
        view_name_radio: nextProps.reasonForSupportObj.view_name === 'required' ? true : false,
        weeks_radio: nextProps.reasonForSupportObj.weeks === 'required' ? true : false,
        workspace_id_radio: nextProps.reasonForSupportObj.workspace_id === 'required' ? true : false,
        accounts_payable_workflow_radio: nextProps.reasonForSupportObj.accounts_payable_workflow === 'required' ? true : false,
        violation_id_radio: nextProps.reasonForSupportObj.violation_id === 'required' ? true : false,
        chargeback_reason_code_radio: nextProps.reasonForSupportObj.chargeback_reason_code === 'required' ? true : false,

        notes: nextProps.reasonForSupportObj.notes,
      }));
    }
    if (nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly) {
      this.setState({ readOnly: JSON.parse(nextProps.appsAndReports.readOnly.read_only) })
      // this.setState({readOnly: true})
    }
    if (nextProps.reasonForSupportPost.apiSuccess) {
      nextProps.history.push('/reason')
    }
  }

  handleChange = name => event => {
    if (name === 'reason_for_support') {
      this.props.checkReasonForSupport(event.target.value)
    }
    this.setState({ [name]: event.target.value });
  };

  handleQuillChange(val) {
    this.setState({
      comments: val
    });
  }
  onArchive = type => () => {
    let archiveVal = type === 'Archive' ? true : false
    this.setState({ archive: archiveVal }, () => {
      this.props.postReasonForSupport(this.state)
      this.props.history.push(`/reason`);
    })
  }
  saveButtonClick(event) {
    event.preventDefault();
    if (this.state.destination_system_name !== "Email") {
      this.setState({ email: "" });
    }
    this.props.postReasonForSupport(this.state);
    // this.props.fetchReasonForSupports();
  }
  componentWillUpdate() {
    if (this.state.apiSuccess) {
      this.props.history.push('/reason')
    }
  }

  enableButton = () => {
    let isValid = this.state.reason_for_support === "" || (this.state.destination_system_name === "Email" && this.state.email === "") ? true : false;
    return isValid;
  };
  handleArchive = e => {
    if (this.state.readOnly) {
      return false
    }
    this.setState({ showArchiveModal: true })
  }
  toggleModal = e => {
    // Published inactive
    this.setState(prevState => ({ showArchiveModal: !prevState.showArchiveModal }));
  }
  emptyFieldValidation = (e) => {
    if (e.target.value === '') {
      this.setState({ mandatoryField: false })
    } else {
      this.props.checkReasonForSupport(event.target.value)
    }
  }
  focusToNewCat = () => {
    this.props.postReasonForSupportError()
    this.props.history.push(`/reason`)
  }
  handleForm = e => {
    let {name} = e.target //-> vendor_number - true/false
    this.setState({[name]: !this.state[`${name}`]})
  }
  handleRadio = name => e => {
    let {value} = e.target //-> vendor_number_radio - true/false
    this.setState({[value]: !this.state[`${value}`]})
  }

  handleOpen = name => event => {
    this.setState({open: true, clicked: name})
  }

  handleClose = event => {
    this.setState({open: false})
  }

  editNotes = attr => note => {
    if(note === '<p><br></p>'){
      this.setState(prevState => {
        let notes = Object.assign({}, prevState.notes)
        notes[`${attr}`] = ''
        return { notes }
      })
    } else {
      if(!isEmpty(note)) {
        this.setState(prevState => {
          let notes = Object.assign({}, prevState.notes)
          notes[`${attr}`] = note
          return { notes }
        })
      }
    }
  }

  render() {
    const { classes, reasonType, history, edit, reasonForSupportPost } = this.props;
    const { readOnly } = this.state;
    // let txtArchive =  (this.props.reasonForSupportObj && this.props.reasonForSupportObj.published === 'Yes') ? 'Archive' : 'Unarchive'
    let txtArchive = (this.props.reasonForSupportObj && this.props.reasonForSupportObj.archive) ? 'Unarchive' : 'Archive'
    let loading = reasonForSupportPost.loading && reasonForSupportPost.loading.appReasonForSupportLoading
    let success = reasonForSupportPost.appReasonForSupportCheck && !reasonForSupportPost.appReasonForSupportCheck.exist && this.state.reason_for_support
    let failure = reasonForSupportPost.appReasonForSupportCheck && reasonForSupportPost.appReasonForSupportCheck.exist && this.state.reason_for_support
    return (
      <div className={classes.root}>
        <HeaderTitle title="Reasons For Support" />
        <Helmet>
          <title>{reasonType} Reasons For Support</title>
        </Helmet>
        <Grid container>
          <Grid container lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.marginContainer}>
              <Typography className={classes.contentSubTitle}>Basics</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.marginContainer}>
              <TextField
                id="reason_for_support"
                name="reason_for_support"
                label="Reason For Support"
                required={true}
                variant="outlined"
                value={this.state.reason_for_support}
                onChange={this.handleChange("reason_for_support")}
                className={classes.textField}
                helperText={(!this.state.mandatoryField) ? "* Missing mandatory field" : null}
                onBlur={this.emptyFieldValidation}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3} style={{ marginTop: '12px' }}>
              {loading && <CircularProgress size={25} className={classes.progress} />}
              {failure && <Typography className={classes.icons}><CancelIcon className={classes.errorIcon} /><span style={{ color: 'red' }}>Already Exists</span></Typography>}
              {success && <CheckCircleIcon className={classes.correctIcon} />}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.marginContainer}>
              <TextField
                id="id"
                name="id"
                label="Id"
                disabled={true}
                placeholder="Id (autogenerated)"
                variant="outlined"
                value={this.state.id}
                onChange={this.handleChange("id")}
                style={{ display: "block", width: "10em", marginTop: 20 }}
                className={[
                  classes.textFieldDisabled,
                  "input-dotted"
                ].join(" ")}
                helperText="Automatically generated"
              />
              <TextField
                select
                id="destination_system_name"
                name="destination_system_name"
                variant="outlined"
                value={this.state.destination_system_name === "" ? "ServiceNow" : this.state.destination_system_name}
                onChange={this.handleChange("destination_system_name").bind(this)}
                label="Destination System Name"
                className={classes.textField}
                style={{ width: "15em", display: "flex", marginTop: 35 }}
              >
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="ServiceNow">ServiceNow</MenuItem>
                <MenuItem value="SalesForce">SalesForce</MenuItem>
              </TextField>
              {this.state.destination_system_name === "Email" &&
                <TextField
                  id="email"
                  label="Email"
                  required
                  disabled={this.state.destination_system_name === "Email" ? false : true}
                  type="email"
                  name="email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                  style={{ width: "25em", marginTop: "4%" }}
                  className={classes.textField}
                  helperText='If Destination System Name is Email, this field is mandatory.'
                />
              }
              <Typography className={classes.contentSubTitle} style={{ marginTop: 25 }}>Comments (optional)</Typography>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.commonGridStyle} style={{ height: "13em" }}>
                <ReactQuill
                  value={this.state.comments}
                  onChange={this.handleQuillChange.bind(this)}
                  style={{ height: "10em", width: "100%" }}
                  modules={{
                    toolbar: this.toolbarOptions,
                    clipboard: { matchVisual: false }
                  }}
                />
              </Grid>
              <Grid container>
                <Typography variant="caption" style={{fontWeight: 600}}>Note: Comments section accepts approximately ~1500 characters only. The final # of accepted characters depends upon the code that is generated in backend. If the total # of characters goes beyond accepted limit then, error will be shown upon clicking save to reduce characters.</Typography>
              </Grid>
            </Grid>
              <Grid container>
                <Grid item xs={8} className={classes.marginContainer}>
                  <Grid container>
                    <Typography className={classes.contentSubTitle} style={{ margin: '25px 0px' }}>Structured Fields</Typography>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>Field</Grid>
                    <Grid item xs={2}>Required</Grid>
                    <Grid item xs={4}>Notes</Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.vendor_name} onChange={this.handleForm} name="vendor_name" />Vendor Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.vendor_name_radio}
                      onChange={this.handleRadio('vendor_name')}
                      value="vendor_name_radio" />
                    </Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['vendor_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('vendor_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.vendor_number} onChange={this.handleForm} name="vendor_number" />Vendor Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.vendor_number_radio}
                      onChange={this.handleRadio('vendor_number')}
                      value="vendor_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['vendor_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('vendor_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.purchase_order} onChange={this.handleForm} name="purchase_order" />Purchase Order</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.purchase_order_radio}
                      onChange={this.handleRadio('purchase_order')}
                      value="purchase_order_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['purchase_order']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('purchase_order')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.trip_id} onChange={this.handleForm} name="trip_id" />Trip ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.trip_id_radio}
                      onChange={this.handleRadio('trip_id')}
                      value="trip_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['trip_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('trip_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.load_id} onChange={this.handleForm} name="load_id" />Load ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.load_id_radio}
                      onChange={this.handleRadio('load_id')}
                      value="load_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['load_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('load_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.ship_id} onChange={this.handleForm} name="ship_id" />Ship ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.ship_id_radio}
                      onChange={this.handleRadio('ship_id')}
                      value="ship_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['ship_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('ship_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.gf_card_name} onChange={this.handleForm} name="gf_card_name" />Greenfield Card Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.gf_card_name_radio}
                      onChange={this.handleRadio('gf_card_name')}
                      value="gf_card_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['gf_card_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('gf_card_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.gf_card_number} onChange={this.handleForm} name="gf_card_number" />Greenfield Card Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.gf_card_number_radio}
                      onChange={this.handleRadio('gf_card_number')}
                      value="gf_card_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['gf_card_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('gf_card_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.credit_number} onChange={this.handleForm} name="credit_number" />Credit/Debit/Memo Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.credit_number_radio}
                      onChange={this.handleRadio('credit_number')}
                      value="credit_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['credit_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('credit_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.accounts_payable_workflow} onChange={this.handleForm} name="accounts_payable_workflow" />Accounts Payable Workflow</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.accounts_payable_workflow_radio}
                      onChange={this.handleRadio('accounts_payable_workflow')}
                      value="accounts_payable_workflow_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['accounts_payable_workflow']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('accounts_payable_workflow')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.bp_group} onChange={this.handleForm} name="bp_group" />BP Group</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.bp_group_radio}
                      onChange={this.handleRadio('bp_group')}
                      value="bp_group_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['bp_group']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('bp_group')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.brand} onChange={this.handleForm} name="brand" />Brand</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.brand_radio}
                      onChange={this.handleRadio('brand')}
                      value="brand_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['brand']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('brand')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.buyer_name} onChange={this.handleForm} name="buyer_name" />Buyer Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.buyer_name_radio}
                      onChange={this.handleRadio('buyer_name')}
                      value="buyer_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['buyer_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('buyer_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.campaign_id} onChange={this.handleForm} name="campaign_id" />Campaign ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.campaign_id_radio}
                      onChange={this.handleRadio('campaign_id')}
                      value="campaign_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['campaign_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('campaign_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.campaign_name} onChange={this.handleForm} name="campaign_name" />Campaign Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.campaign_name_radio}
                      onChange={this.handleRadio('campaign_name')}
                      value="campaign_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['campaign_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('campaign_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.capacity_type} onChange={this.handleForm} name="capacity_type" />Capacity Type</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.capacity_type_radio}
                      onChange={this.handleRadio('capacity_type')}
                      value="capacity_type_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['capacity_type']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('capacity_type')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.carrier_code} onChange={this.handleForm} name="carrier_code" />Carrier Code (SCAC)</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.carrier_code_radio}
                      onChange={this.handleRadio('carrier_code')}
                      value="carrier_code_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['carrier_code']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('carrier_code')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.category} onChange={this.handleForm} name="category" />Category</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.category_radio}
                      onChange={this.handleRadio('category')}
                      value="category_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['category']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('category')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.chargeback_number} onChange={this.handleForm} name="chargeback_number" />Chargeback ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.chargeback_number_radio}
                      onChange={this.handleRadio('chargeback_number')}
                      value="chargeback_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['chargeback_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('chargeback_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.check_number} onChange={this.handleForm} name="check_number" />Check Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.check_number_radio}
                      onChange={this.handleRadio('check_number')}
                      value="check_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['check_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('check_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.commit_number} onChange={this.handleForm} name="commit_number" />Commit Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.commit_number_radio}
                      onChange={this.handleRadio('commit_number')}
                      value="commit_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['commit_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('commit_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.contract_number} onChange={this.handleForm} name="contract_number" />Contract Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.contract_number_radio}
                      onChange={this.handleRadio('contract_number')}
                      value="contract_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['contract_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('contract_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.country_of_production} onChange={this.handleForm} name="country_of_production" />Country Of Production</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.country_of_production_radio}
                      onChange={this.handleRadio('country_of_production')}
                      value="country_of_production_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['country_of_production']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('country_of_production')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.date_range} onChange={this.handleForm} name="date_range" />Dates/Date Range</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.date_range_radio}
                      onChange={this.handleRadio('date_range')}
                      value="date_range_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['date_range']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('date_range')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.department} onChange={this.handleForm} name="department" />Department</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.department_radio}
                      onChange={this.handleRadio('department')}
                      value="department_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['department']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('department')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.dept_class} onChange={this.handleForm} name="dept_class" />Department/Class</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.dept_class_radio}
                      onChange={this.handleRadio('dept_class')}
                      value="dept_class_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['dept_class']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('dept_class')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.dpci_tcin} onChange={this.handleForm} name="dpci_tcin" />DPCI/TCIN</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.dpci_tcin_radio}
                      onChange={this.handleRadio('dpci_tcin')}
                      value="dpci_tcin_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['dpci_tcin']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('dpci_tcin')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.ecom_id} onChange={this.handleForm} name="ecom_id" />Ecom ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.ecom_id_radio}
                      onChange={this.handleRadio('ecom_id')}
                      value="ecom_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['ecom_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('ecom_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.edi_date_of_transmission} onChange={this.handleForm} name="edi_date_of_transmission" />EDI Date Of Transmission</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.edi_date_of_transmission_radio}
                      onChange={this.handleRadio('edi_date_of_transmission')}
                      value="edi_date_of_transmission_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['edi_date_of_transmission']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('edi_date_of_transmission')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.edi_document_type} onChange={this.handleForm} name="edi_document_type" />EDI Document Type</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.edi_document_type_radio}
                      onChange={this.handleRadio('edi_document_type')}
                      value="edi_document_type_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['edi_document_type']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('edi_document_type')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.edi_isa_control_number} onChange={this.handleForm} name="edi_isa_control_number" />EDI ISA Control Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.edi_isa_control_number_radio}
                      onChange={this.handleRadio('edi_isa_control_number')}
                      value="edi_isa_control_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['edi_isa_control_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('edi_isa_control_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.error_message} onChange={this.handleForm} name="error_message" />Error Message</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.error_message_radio}
                      onChange={this.handleRadio('error_message')}
                      value="error_message_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['error_message']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('error_message')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.event_id} onChange={this.handleForm} name="event_id" />Event ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.event_id_radio}
                      onChange={this.handleRadio('event_id')}
                      value="event_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['event_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('event_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.event_name} onChange={this.handleForm} name="event_name" />Event Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.event_name_radio}
                      onChange={this.handleRadio('event_name')}
                      value="event_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['event_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('event_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.factory_id} onChange={this.handleForm} name="factory_id" />Factory ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.factory_id_radio}
                      onChange={this.handleRadio('factory_id')}
                      value="factory_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['factory_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('factory_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.factory_name} onChange={this.handleForm} name="factory_name" />Factory Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.factory_name_radio}
                      onChange={this.handleRadio('factory_name')}
                      value="factory_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['factory_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('factory_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.gf_dataset} onChange={this.handleForm} name="gf_dataset" />Greenfield Dataset</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.gf_dataset_radio}
                      onChange={this.handleRadio('gf_dataset')}
                      value="gf_dataset_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['gf_dataset']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('gf_dataset')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.inventory_analyst_name} onChange={this.handleForm} name="inventory_analyst_name" />Inventory Analyst (IA) Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.inventory_analyst_name_radio}
                      onChange={this.handleRadio('inventory_analyst_name')}
                      value="inventory_analyst_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['inventory_analyst_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('inventory_analyst_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.invoice_number} onChange={this.handleForm} name="invoice_number" />Invoice Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.invoice_number_radio}
                      onChange={this.handleRadio('invoice_number')}
                      value="invoice_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['invoice_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('invoice_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.location_id} onChange={this.handleForm} name="location_id" />Location</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.location_id_radio}
                      onChange={this.handleRadio('location_id')}
                      value="location_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['location_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('location_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.master_group_id} onChange={this.handleForm} name="master_group_id" />Master Group ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.master_group_id_radio}
                      onChange={this.handleRadio('master_group_id')}
                      value="master_group_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['master_group_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('master_group_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.materials} onChange={this.handleForm} name="materials" />Materials (FR,FS,KN,Trim,Artwork etc)</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.materials_radio}
                      onChange={this.handleRadio('materials')}
                      value="materials_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['materials']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('materials')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.metrics_impacted} onChange={this.handleForm} name="metrics_impacted" />Metrics Impacted</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.metrics_impacted_radio}
                      onChange={this.handleRadio('metrics_impacted')}
                      value="metrics_impacted_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['metrics_impacted']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('metrics_impacted')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.mfg_id} onChange={this.handleForm} name="mfg_id" />MFG ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.mfg_id_radio}
                      onChange={this.handleRadio('mfg_id')}
                      value="mfg_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['mfg_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('mfg_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.page} onChange={this.handleForm} name="page" />Page</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.page_radio}
                      onChange={this.handleRadio('page')}
                      value="page_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['page']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('page')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.planogram_number} onChange={this.handleForm} name="planogram_number" />Planogram Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.planogram_number_radio}
                      onChange={this.handleRadio('planogram_number')}
                      value="planogram_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['planogram_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('planogram_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.pro_number} onChange={this.handleForm} name="pro_number" />PRO Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.pro_number_radio}
                      onChange={this.handleRadio('pro_number')}
                      value="pro_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['pro_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('pro_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.product_details} onChange={this.handleForm} name="product_details" />Product Details (PID, BOM, POM etc)</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.product_details_radio}
                      onChange={this.handleRadio('product_details')}
                      value="product_details_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['product_details']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('product_details')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.product_name} onChange={this.handleForm} name="product_name" />Product Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.product_name_radio}
                      onChange={this.handleRadio('product_name')}
                      value="product_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['product_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('product_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.project_id} onChange={this.handleForm} name="project_id" />Project ID/Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.project_id_radio}
                      onChange={this.handleRadio('project_id')}
                      value="project_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['project_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('project_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.report_name} onChange={this.handleForm} name="report_name" />Report Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.report_name_radio}
                      onChange={this.handleRadio('report_name')}
                      value="report_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['report_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('report_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.report_type} onChange={this.handleForm} name="report_type" />Report/Table Type</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.report_type_radio}
                      onChange={this.handleRadio('report_type')}
                      value="report_type_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['report_type']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('report_type')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.ship_node} onChange={this.handleForm} name="ship_node" />Ship Node</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.ship_node_radio}
                      onChange={this.handleRadio('ship_node')}
                      value="ship_node_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['ship_node']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('ship_node')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.store_number} onChange={this.handleForm} name="store_number" />Store Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.store_number_radio}
                      onChange={this.handleRadio('store_number')}
                      value="store_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['store_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('store_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.studio_name} onChange={this.handleForm} name="studio_name" />Studio Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.studio_name_radio}
                      onChange={this.handleRadio('studio_name')}
                      value="studio_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['studio_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('studio_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.target_distribution_center_number} onChange={this.handleForm} name="target_distribution_center_number" />Target Distribution Center Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.target_distribution_center_number_radio}
                      onChange={this.handleRadio('target_distribution_center_number')}
                      value="target_distribution_center_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['target_distribution_center_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('target_distribution_center_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.template_name} onChange={this.handleForm} name="template_name" />Template Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.template_name_radio}
                      onChange={this.handleRadio('template_name')}
                      value="template_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['template_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('template_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.time_error_occurred} onChange={this.handleForm} name="time_error_occurred" />Time Error Occurred</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.time_error_occurred_radio}
                      onChange={this.handleRadio('time_error_occurred')}
                      value="time_error_occurred_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['time_error_occurred']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('time_error_occurred')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.trailer_number} onChange={this.handleForm} name="trailer_number" />Trailer Number</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.trailer_number_radio}
                      onChange={this.handleRadio('trailer_number')}
                      value="trailer_number_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['trailer_number']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('trailer_number')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.view_name} onChange={this.handleForm} name="view_name" />View Name</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.view_name_radio}
                      onChange={this.handleRadio('view_name')}
                      value="view_name_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['view_name']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('view_name')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.weeks} onChange={this.handleForm} name="weeks" />Week(s)</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.weeks_radio}
                      onChange={this.handleRadio('weeks')}
                      value="weeks_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['weeks']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('weeks')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.workspace_id} onChange={this.handleForm} name="workspace_id" />Workspace ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.workspace_id_radio}
                      onChange={this.handleRadio('workspace_id')}
                      value="workspace_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['workspace_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('workspace_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.violation_id} onChange={this.handleForm} name="violation_id" />Violation ID</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.violation_id_radio}
                      onChange={this.handleRadio('violation_id')}
                      value="violation_id_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['violation_id']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('violation_id')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                  <Grid container>
                    <Grid item xs={6}><Checkbox checked={this.state.chargeback_reason_code} onChange={this.handleForm} name="chargeback_reason_code" />Match Chargeback Reason Code</Grid>
                    <Grid item xs={2}><Switch
                      checked={this.state.chargeback_reason_code_radio}
                      onChange={this.handleRadio('chargeback_reason_code')}
                      value="chargeback_reason_code_radio" /></Grid>
                    <Grid item xs={4}>
                      <Button style={isEmpty(this.state.notes && this.state.notes['chargeback_reason_code']) ? {backgroundColor: '#cd8986'} : {backgroundColor: '#8fee8f'}} onClick={this.handleOpen('chargeback_reason_code')}>Click to enter notes</Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                </Grid>
              </Grid>

              <Grid container className={classes.marginContainer}>
                {/* <Typography style={{ color: '#cc0000' }}>** Note: Switch buttons are for selecting required/not required field for corresponding attribute.</Typography> */}
                <Typography className={classes.contentSubTitle} style={{ marginTop: 22 }} >Publishing Details</Typography>
                <TextField
                  select
                  name="destination_system_name"
                  value={
                    this.state.published === "" ? "No" : this.state.published
                  }
                  onChange={this.handleChange("published").bind(this)}
                  label="Published"
                  className={[classes.textField,classes.marginContainer].join(' ')}
                  style={{ width: "10em", marginTop: 25 }}
                  variant="outlined">
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </TextField>
                <Divider style={{ marginTop: 20 }} />
              </Grid>
              <Grid container className={classes.marginContainer}>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography variant="caption" className={classes.alertText}>
                    <Info /><span className={classes.publishContentFinal}>To publish, fill out all required fields in the 'key info' tab</span>
                  </Typography>
                </Grid>
                <Grid item lg={7} md={7} sm={12} xs={12}>
                  {reasonType !== 'Add' &&
                    <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                      <a className={classes.linkArchive} href="#" onClick={this.handleArchive}>
                        {txtArchive}
                      </a>
                    </Typography>
                  }
                </Grid>
                {/* <Grid item lg={7} md={7} sm={12} xs={12}></Grid> */}
                <Grid item md={5} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    classes={{
                      root: classes.saveButtonStyle,
                      disabled: classes.buttonDisabled
                    }}
                    disabled={this.enableButton() || (reasonForSupportPost.appReasonForSupportCheck && reasonForSupportPost.appReasonForSupportCheck.exist) || readOnly} color="primary" onClick={this.saveButtonClick.bind(this)} style={{ float: 'right' }}>
                    Save
                  {this.props.postReasonForSupport &&
                      this.props.postReasonForSupport.loading &&
                      this.props.postReasonForSupport.loading.reasonForSupportLoading ? (<CircularProgress size={24} className={classes.buttonProgress} />) : ("")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>

          </Grid>
        </Grid>
        <ModalContainer modalName={this.state.clicked} open={this.state.open} notes={this.state.notes} editNotes={this.editNotes} onclose={this.handleClose} />
        <Modal isOpen={this.state.showArchiveModal || reasonForSupportPost.status} contentStyles={{ maxWidth: '320px' }} closeButton={false} onClose={this.toggleModal}>
          {reasonForSupportPost && !reasonForSupportPost.status &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.content}>
                  Do you want to {txtArchive} "{this.state.reason_for_support}" ?
              </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <div className={classes.buttonContainer}>
                  <Button className={classes.cancelButton} onClick={this.toggleModal}>No</Button>
                  <Button variant="contained" color="primary" onClick={this.onArchive(txtArchive)}>Yes</Button>
                </div>
              </Grid>
            </Grid>
          }
          {reasonForSupportPost && reasonForSupportPost.status &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.alertBar}><WarningIcon className={classes.alertIcon} />{reasonForSupportPost.text} </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <div className={classes.buttonContainer}>
                  <Button variant="contained" color="primary" onClick={this.focusToNewCat}>
                    OK
                </Button>
                </div>
              </Grid>
            </Grid>
          }
        </Modal>
      </div>
    );
  }
}

ReasonForSupportForm.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  reasonType: PropTypes.string,
  postReasonForSupport: PropTypes.func,
  fetchReasonForSupports: PropTypes.func,
  reasonForSupportId: PropTypes.string,
  status: PropTypes.string,
  reasonForSupportText: PropTypes.string,
  comments: PropTypes.string,
  email: PropTypes.string,
  destinationSystemName: PropTypes.string,
  published: PropTypes.string,
  reasonForSupportObj: PropTypes.object,
  edit: PropTypes.bool,
  onClose: PropTypes.func,
  reasonForSupportPost: PropTypes.object,
  postReasonForSupportError: PropTypes.func,
  checkReasonForSupport: PropTypes.func,
  appsAndReports: PropTypes.object,
};

const mapStateToProps = state => {
  const { reasonForSupportObjState, reasonForSupportPost, appsAndReports } = state;
  return {
    reasonForSupportPost,
    reasonForSupportObjState: reasonForSupportObjState,
    appsAndReports: appsAndReports,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    postReasonForSupport: data => dispatch(postReasonForSupport(data)),
    fetchReasonForSupports: data => dispatch(fetchReasonForSupports()),
    postReasonForSupportError: data => dispatch(postReasonForSupportError(false, '')),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReasonForSupportForm));
