import * as types from './actionType'

const initialState = {
  getLoading: true,
  getSuccess: false,
  data: [],
  checkNode: [],
}

export default function manageSupport(state = initialState, action = {}) {
  switch(action.type){
    case types.GET_SUPPORT_SUCCESS:
      return {
        ...state,
        getLoading: false,
        getSuccess: true,
        data: action.data.result,
      }
    case types.GET_SUPPORT_INIT:
      return {
        ...state,
        getLoading: true,
        getSuccess: false,
      }
    case types.GET_SUPPORT_FAIL:
      return {
        ...state,
        getLoading: false,
        getSuccess: false,
      }
    case types.OPEN_MODAL:
      return {
        ...state,
        [action.modalName]: true,
        name: action.name,
      }
    case types.CLOSE_MODAL:
      return {
        ...state,
        [action.modalName]: false,
      }
    default:
      return state
  }
}

export function updateSupport (state = { postLoading: true, postSuccess: false }, action = {}) {
  switch(action.type) {
    case types.UPDATE_SUPPORT_SUCCESS:
      return {
        ...state,
        result: action.data.result,
        postLoading: false,
        postSuccess: true,
      }
    case types.UPDATE_SUPPORT_FAIL:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
      }
    case types.UPDATE_SUPPORT_INIT:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
      }
    default:
      return state
  }
}

export function addSupport (state = {addLoading: true, addSuccess: false}, action = {}) {
  switch(action.type) {
    case types.POST_SUPPORT_INIT:
      return {
        ...state,
        addLoading: true,
        addSuccess: false,
      }
    case types.POST_SUPPORT_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addSuccess: true,
      }
    case types.POST_SUPPORT_FAIL:
      return {
        ...state,
        addLoading: false,
      }
    default:
      return state
  }
}

export function supportOptions (state = [], action = {}) {
  switch(action.type){
    case types.GET_OPTIONS:
      return {
        ...state,
        data: action.data.result.sort(compare),
      }
    default:
      return state
  }
}

export function delSupport (state = {delLoading: true, delSuccess: false}, action = {}) {
  switch(action.type) {
    case types.DELETE_SUPPORT_INIT:
      return {
        ...state,
        delLoading: true,
        delSuccess: false,
      }
    case types.DELETE_SUPPORT_SUCCESS:
      return {
        ...state,
        delLoading: false,
        delSuccess: true,
      }
    case types.DELETE_SUPPORT_FAIL:
      return {
        ...state,
        delLoading: false,
      }
    default:
      return state
  }
}

export function getHelpCategories (state = {getCatLoading: true, getCatSuccess: false}, action = {}) {
  switch(action.type) {
    case types.GET_HELP_CATEGORIES_INIT:
      return {
        ...state,
        getCatLoading: true,
        getCatSuccess: false,
      }
    case types.GET_HELP_CATEGORIES_SUCCESS:
      return {
        ...state,
        getCatLoading: false,
        getCatSuccess: true,
        data: action.data.result,
      }
    case types.GET_HELP_CATEGORIES_FAIL:
      return {
        ...state,
        getCatLoading: false,
      }
    default:
      return state
  }
}

export function editHelpCategories (state = {editCatLoading: true, editCatSuccess: false, deleteCatSuccess: false}, action = {}) {
  switch(action.type) {
    case types.EDIT_HELP_CATEGORIES_INIT:
      return {
        ...state,
        editCatLoading: true,
        editCatSuccess: false,
      }
    case types.EDIT_HELP_CATEGORIES_SUCCESS:
      return {
        ...state,
        editCatLoading: false,
        editCatSuccess: true,
        data: action.data.result,
      }
    case types.EDIT_HELP_CATEGORIES_FAIL:
      return {
        ...state,
        editCatLoading: false,
        editCatSuccess: false,
      }
    case types.DELETE_HELP_CATEGORIES_SUCCESS:
      return {
        ...state,
        deleteCatSuccess: true,
      }
    default:
      return state
  }
}

function compare(a, b) {
  const rfsA = a.reason_for_support.toUpperCase();
  const rfsB = b.reason_for_support.toUpperCase();

  let comparison = 0;
  if (rfsA > rfsB) {
    comparison = 1;
  } else if (rfsA < rfsB) {
    comparison = -1;
  }
  return comparison;
}
