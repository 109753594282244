import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SignInSuccess from 'react-praxis-components/SignInSuccess'
import { AuthProvider } from '@praxis/component-auth'
import { CircularProgress } from '@material-ui/core'
import Layout from './components/Layout/Layout'
import muiTheme from './config/themeConfig'
import apiConfig from './config/apiConfig'

const OAuthProviderConfig = {
  authorizationUrl: `${apiConfig.auth.host}${apiConfig.auth.authorizationPath}`,
  clientId: apiConfig.auth.clientId,
  logoutUrl: `${apiConfig.auth.logoutHost}${apiConfig.auth.logoutPath}?TARGET=${window.location.href}`,
  nonce: apiConfig.auth.nonce,
  popupOptions: apiConfig.auth.popupOptions,
  loginRedirect: apiConfig.auth.redirectUri,
  responseType: apiConfig.auth.responseType,
  scope: apiConfig.auth.scope,
  storageType: apiConfig.auth.storageType,
  tokenType: apiConfig.auth.tokenType,
}

const element = () => (< CircularProgress color='secondary' size={50} style={{ position: "absolute", top: "50%", left: "50%", marginTop: -12, marginLeft: -12, }} />)

const App = () => (
  <AuthProvider
    authorizationUrl={OAuthProviderConfig.authorizationUrl}
    clientId={OAuthProviderConfig.clientId}
    loginRedirect={OAuthProviderConfig.loginRedirect}
    logoutUrl={OAuthProviderConfig.logoutUrl}
    tokenType={OAuthProviderConfig.tokenType}
    hideLoadingIndicator={false}
    loadingIndicator={element}
  >
    <MuiThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <Switch>
          {/* Display SignInSuccess without Layout */}
          <Route exact path="/auth/login" component={SignInSuccess} />
          <Route path="/" component={Layout} />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  </AuthProvider>
)

export default App
