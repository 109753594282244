import axios from 'axios'
import _ from 'lodash'

import {
  APP_CATEGORIES_LIST_API_CALL_INITIATED,
  APP_CATEGORIES_LIST_API_CALL_SUCCESS,
  APP_CATEGORIES_LIST_API_CALL_FAIL,
  OPEN_MODAL,
  CLOSE_MODAL,
  POST_APPS_CATEGORIES_API_CALL_FAIL,
  POST_APPS_CATEGORIES_API_CALL_ERROR,
  POST_APPS_CATEGORIES_API_CALL_INITIATED,
  POST_APPS_CATEGORIES_API_CALL_SUCCESS,
  DELETE_APPS_CATEGORIES_API_CALL_FAIL,
  DELETE_APPS_CATEGORIES_API_CALL_INITIATED,
  DELETE_APPS_CATEGORIES_API_CALL_SUCCESS,
  CHECK_CATEGORIES_INITIATED,
  CHECK_CATEGORIES_SUCCESS,
  CHECK_CATEGORIES_FAIL,
} from './actionType'

import apiConfig from '../../config/apiConfig'
import { TEXT_ERROR_DEFAULT, TEXT_SUCCESS_APP_CATEGORIES_UPDATED, TEXT_SUCCESS_APP_CATEGORIES_ADDED, TEXT_ERROR_DUPLICATE } from '../../constants/notifications'
import { showNotification } from '../notification/actionCreator'

export function fetchCategories() {
  return function (dispatch) {
    dispatch(categoriesApiCallInitiated({
      type: APP_CATEGORIES_LIST_API_CALL_INITIATED,
      data: [],
    }))
    return axios.get(`${apiConfig.adminApi.host}appGroups`, {
      data: {},
    })
      .then(response => {
        dispatch(categoriesApiCallSuccess(response.data.result))
      })
      .catch((error) => {
        dispatch(categoriesApiCallFail(error))
      })
  }
}
export function categoriesApiCallInitiated(data) {
  return {
    type: APP_CATEGORIES_LIST_API_CALL_INITIATED,
    data,
  }
}
export function categoriesApiCallSuccess(data) {
  return {
    type: APP_CATEGORIES_LIST_API_CALL_SUCCESS,
    data,
  }
}
export function categoriesApiCallFail(error) {
  return {
    type: APP_CATEGORIES_LIST_API_CALL_FAIL,
    error,
  }
}

export function openModal(modalName) {
  return {
    type: OPEN_MODAL,
    modalName,
  }
}

export function closeModal(modalName) {
  return {
    type: CLOSE_MODAL,
    modalName,
  }
}
/***************************/
export function postCategories(postData) {
  let txt = ''
  if (postData.isAdd) {
    txt = TEXT_SUCCESS_APP_CATEGORIES_UPDATED
  }
  else {
    txt = TEXT_SUCCESS_APP_CATEGORIES_ADDED
  }
  let data = {
    app_group_desc: postData.categoriesDesc,
    app_group_id: postData.categoriesId,
    app_group_name: postData.categoriesName,
    sequence: postData.categoriesOrder,
    status: postData.categoriesStatus,
  }
  if (postData.isAdd) {
    data = _.omit(data, ['app_group_id'])
  }
  return function (dispatch) {
    dispatch(postCategoriesInitiated())
    // return axios.get(`${apiConfig.adminApi.host}admin_app_list`, data)
    return axios.post(`${apiConfig.adminApi.host}appGroups`, data)
      .then(response => {
        dispatch(showNotification(true, txt, 'success'))
        dispatch(postCategoriesCallSuccess())
        dispatch(fetchCategories())
        dispatch(closeModal('editForm'))
      })
      .catch((e) => {
        dispatch(postCategoriesCallFail())
        dispatch(postCategoriesError(true, e?.response?.data?.meta_info?.error?.message))
        // if (e.response && e.response.status && e.response.status === 400 || 500) {
        //   dispatch(postCategoriesError(true, postData.categoriesName + TEXT_ERROR_DUPLICATE + "Category."))
        // } else {
        //   dispatch(postCategoriesError(true, TEXT_ERROR_DEFAULT))
        // }
      })
  }
}
export function postCategoriesInitiated() {
  return {
    type: POST_APPS_CATEGORIES_API_CALL_INITIATED,
  }
}

export function postCategoriesCallSuccess(data) {
  return {
    type: POST_APPS_CATEGORIES_API_CALL_SUCCESS,
    data,
  }
}

export function postCategoriesCallFail(error) {
  return {
    type: POST_APPS_CATEGORIES_API_CALL_FAIL,
    error,
  }
}

export function postCategoriesError(status, text) {
  return {
    type: POST_APPS_CATEGORIES_API_CALL_ERROR,
    status,
    text,
  }
}

/***************************/
export function checkCategories(data) {
  return function (dispatch) {
    dispatch(checkCategoriesInitiated())
    return axios.get(`${apiConfig.adminApi.host}appGroups/validate?title=${data}`)
      .then(response => {
        dispatch(checkCategoriesSuccess(response.data.result))
      })
      .catch(error => {
        dispatch(checkCategoriesFail(error))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
      })
  }
}
export function checkCategoriesInitiated() {
  return {
    type: CHECK_CATEGORIES_INITIATED
  }
}
export function checkCategoriesSuccess(data) {
  return {
    type: CHECK_CATEGORIES_SUCCESS,
    data,
  }
}
export function checkCategoriesFail(error) {
  return {
    type: CHECK_CATEGORIES_FAIL,
    error,
  }
}
/***************************/

/***************************/
export function deleteAppCategories(id, isMapped) {
  let data = { id: id }
  if (isMapped === 1) {
    data.confirm_delete = true
  }
  return function (dispatch) {
    (isMapped === 1) ? dispatch(deleteAppCategoriesInitiated()) : ''
    return axios.delete(`${apiConfig.adminApi.host}deleteAppGroups`, { data: data })
      .then(response => {
        if (response.data.result !== 'SUCCESS') {
          dispatch(deleteAppCategoriesSuccess(response.data.result))
        } else {
          dispatch(closeModal('editForm'))
          dispatch(fetchCategories())
          dispatch(showNotification(true, 'App Categories deleted successfully', 'success'))
        }
      })
      .catch((e) => {
        dispatch(deleteAppCategoriesFail(e))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
      })
  }
}
export function deleteAppCategoriesFail(data) {
  return {
    type: DELETE_APPS_CATEGORIES_API_CALL_FAIL,
    data,
  }
}
export function deleteAppCategoriesSuccess(data) {
  return {
    type: DELETE_APPS_CATEGORIES_API_CALL_SUCCESS,
    data,
  }
}
export function deleteAppCategoriesInitiated(data) {
  return {
    type: DELETE_APPS_CATEGORIES_API_CALL_INITIATED,
    data,
  }
}
