import axios from 'axios'
import {
  CATEGORIES_LIST_API_CALL_INITIATED,
  CATEGORIES_LIST_API_CALL_SUCCESS,
  CATEGORIES_LIST_API_CALL_FAIL,
} from './actionType'

import apiConfig from '../../config/apiConfig'

export function fetchCategories () {
  return function (dispatch) {
    dispatch(categoriesApiCallInitiated({
      type: CATEGORIES_LIST_API_CALL_INITIATED,
      data: [],
    }))
    return axios.get(`${apiConfig.adminApi.host}app_groups_list`, {
      data: {},
    })
      .then(response => {
        let catList = [];
        response.data.result.forEach(function(obj) {
          catList.push({ label:obj.app_group_name, value: obj.app_group_id })
        });
        dispatch(categoriesApiCallSuccess(catList))
      })
      .catch((error) => {
        dispatch(categoriesApiCallFail(error))
      })
  }
}
export function categoriesApiCallInitiated (data) {
  return {
    type: CATEGORIES_LIST_API_CALL_INITIATED,
    data,
  }
}
export function categoriesApiCallSuccess (data) {
  return {
    type: CATEGORIES_LIST_API_CALL_SUCCESS,
    data,
  }
}
export function categoriesApiCallFail (error) {
  return {
    type: CATEGORIES_LIST_API_CALL_FAIL,
    error,
  }
}
