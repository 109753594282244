import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CircularProgress from "@material-ui/core/CircularProgress";
import "ag-grid-community/dist/styles/ag-grid.css";
import Download from "@material-ui/icons/CloudDownloadRounded";
import Add from "@material-ui/icons/Add";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import HeaderTitle from "../../components/Header/HeaderTitle";
import GridConfig from "./GridConfig";
import { fetchReasonForSupports, postReasonForSupportFinal } from "../../store/resonForSupport/actionCreator";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '99%',
  },
  container: {
    marginTop: 10
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  button: {
    marginLeft: "8%"
  },
  search: {
    width: "60%",
    float: "right"
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 24
  },
  input: {
    width: "calc(100% - 70px)",
    border: 0
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32
  },
  tableSize:{
    height: "calc(100vh - 185px)",
    width: "100%"
  },
  showArchive: {
    display: 'inline',
    marginLeft: 50,
  },
  showArchiveLabel: {
    color: '#757575',
    fontWeight: 700,
  },
  colorSwitchBase: {
    color: '#0049db',
    '&$colorChecked': {
      color: '#0049db',
      '& + $colorBar': {
        backgroundColor: '#0049db',
      },
    },
  },
  colorBar: {},
  colorChecked: {}
});
class ReasonForSupport extends Component {
  constructor(props) {
    super(props);
    this.gConfig = new GridConfig();
    this.state = {
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: "fa-bars",
          sortable: true
        },
        resizable: true
      },
      rowData: [],
      // enableFilter: true,
      pagination: false,
      enableEditCat: true,
      paginationPageSize: 10,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting: true,
      showArchive: false,
      readOnly: false,
    };
    this.gridOptions = {
      columnDefs: this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      resizable: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady: this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      // floatingFilter: true, // Show filter in each column
      // this is a simple property
      rowBuffer: 10, // no need to set this, the default is fine for almost all scenarios,
      onRowSelected: this.rowSelected,
      onSelectionChanged: this.onSelectionChanged,
      isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
      doesExternalFilterPass: this.doesExternalFilterPass,
      enableCellTextSelection: true,
      // onRowClicked: this.RowClickEventHandler
      // sideBar: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reasonForSupport.reasonForSupportCount > 0) {
      this.setState({
        rowData: nextProps.reasonForSupport.reasonForSupportList
      });
    }
    if (nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly) {
      this.setState({readOnly: nextProps.appsAndReports.readOnly.read_only && JSON.parse(nextProps.appsAndReports.readOnly.read_only)})
      // this.setState({readOnly: true})
    }
    if (nextProps.reasonForSupportPost.apiSuccess) {
      nextProps.postReasonForSupportFinal()
    }
  }

  componentWillMount() {
    this.props.fetchReasonForSupports();
  }

  componentDidMount() {
    if (this.props.appsAndReports.readOnly !== undefined) {
      this.setState({readOnly: JSON.parse(this.props.appsAndReports.readOnly.read_only)})
      // this.setState({readOnly: true})
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
  };
  handleClick = params => {
    this.props.history.push(`/reason/edit/${params.data.id}`);
  };

  rowSelected(event) {
    // console.log(event)
    // return document.querySelector(cssSelector).checked === true;
  }
  // componentDidMount() {
  //   fetch('https://api.myjson.com/bins/15psn9')
  //   .then(result => result.json())
  //   .then(rowData => this.setState({rowData}))
  // }
  getBooleanValue(cssSelector) {
    // return document.querySelector(cssSelector).checked === true;
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  }
  clickAddButton(event) {
    this.props.history.push(`/reason/new`);
  }
  onSelectionChanged = (event) => {
    this.setState({enableEditCat:!event.api.getSelectedNodes().length >= 1})
    let selectedRows = this.gridApi.getSelectedRows()
    let items = []
    selectedRows.forEach(function(selectedRow, index) {
      items.push(selectedRow)
    })
    this.setState({selectedRowsApps:items})
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, function() {
      this.gridOptions.api.onFilterChanged()
      this.externalFilterChanged(name)
    })

  }
  externalFilterChanged () {
    this.gridOptions.api.onFilterChanged()
  }
  doesExternalFilterPass = node => {
    let status = node.data.archive === this.state.showArchive
    return status
  }
  isExternalFilterPresent () {
    return this.state.showArchive != 'false'
  }
  render() {
    const { classes, reasonForSupport } = this.props;
    const { readOnly } = this.state;
    return (
      <div className={classes.root}>
        <HeaderTitle title="Reasons For Support" />
        <Helmet>
          <title>Manage Reasons For Support</title>
        </Helmet>
        {reasonForSupport.loading && reasonForSupport.loading.reasonForSupportLoading ? <CircularProgress size={50} className={classes.buttonProgress} />
          : reasonForSupport.apiError
            ? <Grid container spacing={24} >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>An error occurred while retrieving Reason For Support data. Please contact support if this issue persists.</p>
              </Grid>
            </Grid>
            : reasonForSupport.reasonForSupportCount >= 1 ? <Grid container spacing={24} className={classes.container}>
                <Grid item xs={6}>
                  <Button disabled={readOnly} variant="contained" color="primary" className={classes.button} onClick={this.clickAddButton.bind(this)}>
                    <Add className={classes.buttonIcon} /> Add
                  </Button>
                  <Button disabled={readOnly} variant="contained" color="primary" className={classes.button} onClick={this.gConfig.onBtExport.bind(this)}>
                    <Download className={classes.buttonIcon} />
                    Export
                  </Button>
                  <FormGroup row className={classes.showArchive}>
                    <FormControlLabel
                      classes={{ label: classes.showArchiveLabel}}
                      control={
                        <Switch
                          checked={this.state.showArchive}
                          onChange={this.handleChange('showArchive')}
                          value="archive"
                          classes={{
                            switchBase: classes.colorSwitchBase,
                            checked: classes.colorChecked,
                            bar: classes.colorBar,
                          }}
                        />
                      }
                      label="Show Archive"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.search} elevation={1}>
                    <IconButton className={classes.iconButton} aria-label="Search"><SearchIcon /></IconButton>
                    <InputBase className={classes.input} placeholder="Search List" onInput={this.onQuickFilterChanged.bind(this)} id="quickFilter"/>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <div className={[classes.tableSize, 'ag-theme-material full-row'].join(' ')}>
                    <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
                  </div>
                </Grid>
              </Grid>
              : <Grid container spacing={24}>
                <Grid item xs={12}>
                  <p className={classes.boxDiv}><Error className={classes.icon} />There are no Reasons For Support.</p>
                </Grid>
              </Grid>
        }
      </div>
    );
  }
}
ReasonForSupport.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchReasonForSupports: PropTypes.func,
  reasonForSupport: PropTypes.object,
  appsAndReports: PropTypes.object,
  reasonForSupportPost: PropTypes.object,
  postReasonForSupportFinal: PropTypes.func,
};
const mapStateToProps = state => {
  const { reasonForSupport, appsAndReports, reasonForSupportPost } = state;
  return {
    reasonForSupport: reasonForSupport,
    appsAndReports,
    reasonForSupportPost,
  };
};
export default connect(
  mapStateToProps,
  { fetchReasonForSupports, postReasonForSupportFinal }
)(withStyles(styles)(ReasonForSupport));
