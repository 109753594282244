import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Error from '@material-ui/icons/Error'
// import 'ag-grid-community/dist/styles/ag-grid.css'
// // import 'ag-grid-community/dist/styles/ag-theme-balham.css'
// import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import Download from '@material-ui/icons/CloudDownloadRounded'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import HeaderTitle from "../../components/Header/HeaderTitle"
import { fetchApps, openModal, closeModal } from '../../store/appsAndReport/actionCreator'
import GridConfig from './GridConfig'
import EditCategories from './EditCategories'
import "../../styles/tabel.css"

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '99%',
  },
  container: {
    marginTop: 10,
  },
  button: {
    marginLeft: 20,
  },
  search: {
    width: '60%',
    float: 'right',
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 24,
  },
  input: {
    width: 'calc(100% - 70px)',
    border: 0,
  },
  tableSize: {
    height: "calc(100vh - 180px)",
    width: "100%"
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  showArchive: {
    display: 'inline',
    marginLeft: 50,
  },
  showArchiveLabel: {
    color: '#757575',
    fontWeight: 700,
  },
  colorSwitchBase: {
    color: '#0049db',
    '&$colorChecked': {
      color: '#0049db',
      '& + $colorBar': {
        backgroundColor: '#0049db',
      },
    },
  },
  colorBar: {},
  colorChecked: {}
})
class AppsAndReport extends Component {
  constructor(props) {
    super(props)
    this.gConfig = new GridConfig()
    this.state = {
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        filter: true,
        // width: 100
      },
      rowData: [],
      // enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting: true,
      /** For application **/
      enableEditCat: true,
      selectedRowsCat: '',
      selectedRowsId: '',
      selectedRowsApps: '',
      openModal: false,
      showArchive: false,
      // readOnly: false,
      /** For application **/
    }
    this.gridOptions = {
      columnDefs: this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady: this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      // floatingFilter: true, // Show filter in each column
      // this is a simple property
      rowBuffer: 10, // no need to set this, the default is fine for almost all scenarios,
      onRowSelected: this.rowSelected,
      onSelectionChanged: this.onSelectionChanged.bind(this),
      isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
      doesExternalFilterPass: this.doesExternalFilterPass,
      // onRowClicked: this.RowClickEventHandler,
      // sideBar: true,
    }
    this.handleEditCat = this.handleEditCat.bind(this)
    this.closeEditCat = this.closeEditCat.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.appsAndReports.appsAndReportCount > 0) {
      this.setState({ rowData: nextProps.appsAndReports.appsAndReportList })
    }
    // if (nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly) {
    //   this.setState({readOnly: nextProps.appsAndReports.readOnly.read_only && JSON.parse(nextProps.appsAndReports.readOnly.read_only)})
    //   // this.setState({readOnly: true})
    // }
  }
  componentWillMount() {
    this.props.fetchApps()
  }
  componentDidMount() {
    // if (this.props.appsAndReports.readOnly !== undefined) {
    //   this.setState({readOnly: this.props.appsAndReports.readOnly.read_only && JSON.parse(this.props.appsAndReports.readOnly.read_only)})
    //   // this.setState({readOnly: true})
    // }
  }
  onGridReady = params => {
    this.gridApi = params.api
  }
  handleClick = params => {
    let url = '/apps/edit/' + params.data.id
    this.props.history.push(url)
  }
  handleEditCat = params => {
    this.props.openModal('editForm')
  }
  closeEditCat() {
    this.props.closeModal('editForm')
  }
  rowSelected(event) {
    // console.log(event)
    // return document.querySelector(cssSelector).checked === true
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("quickFilter").value)
  }
  onSelectionChanged(event) {
    this.setState({ enableEditCat: !event.api.getSelectedNodes().length >= 1 })
    let selectedRows = this.gridApi.getSelectedRows()
    let items = []
    selectedRows.forEach(function (selectedRow, index) {
      items.push(selectedRow)
    })
    this.setState({ selectedRowsApps: items })
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, function () {
      this.gridOptions.api.onFilterChanged()
      this.externalFilterChanged(name)
    })

  }
  externalFilterChanged() {
    this.gridOptions.api.onFilterChanged()
  }
  doesExternalFilterPass = node => {
    let status = node.data.archive === this.state.showArchive
    return status
  }
  isExternalFilterPresent() {
    return this.state.showArchive != 'false'
  }
  render() {
    const { classes, appsAndReports, config } = this.props
    const { selectedRowsApps } = this.state
    const configData = config && config.data
    return (
      <div className={classes.root}>
        <HeaderTitle title="App/Report" />
        <Helmet>
          <title>Manage Apps/Reports</title>
        </Helmet>
        {appsAndReports.loading && appsAndReports.loading.appsAndReportsLoading ? <CircularProgress size={50} className={classes.buttonProgress} />
          : appsAndReports.apiError
            ? <Grid container spacing={24} >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>An error occurred while retrieving Apps/Reports data. Please contact support if this issue persists.</p>
              </Grid>
            </Grid>
            : appsAndReports.appsAndReportCount >= 1 ? <Grid container spacing={24} className={classes.container}>
              <Grid item xs={6}>
                <Button disabled={configData.support} variant="contained" color="primary" className={classes.button} component={Link} to="/apps/add">
                  <Add className={classes.buttonIcon} />
                  Add
                  </Button>
                <Button variant="contained" color="primary" className={classes.button} disabled={this.state.enableEditCat || configData.support} onClick={() => this.handleEditCat()}>
                  <Edit className={classes.buttonIcon} />
                  Edit Categories
                  </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.gConfig.onBtExport.bind(this)}>
                  <Download className={classes.buttonIcon} />
                  Export
                  </Button>
                <FormGroup row className={classes.showArchive}>
                  <FormControlLabel
                    classes={{ label: classes.showArchiveLabel }}
                    control={
                      <Switch
                        checked={this.state.showArchive}
                        onChange={this.handleChange('showArchive')}
                        value="archive"
                        classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          bar: classes.colorBar,
                        }}
                      />
                    }
                    label="Show Archive"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.search} elevation={1}>
                  <IconButton className={classes.iconButton} aria-label="Search"><SearchIcon /></IconButton>
                  <InputBase className={classes.input} placeholder="Search List" onInput={this.onQuickFilterChanged.bind(this)} id="quickFilter" />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <div className={[classes.tableSize, 'ag-theme-material'].join(' ')}>
                  <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
                </div>
              </Grid>
            </Grid>
              : <Grid container spacing={24}>
                <Grid item xs={12}>
                  <p className={classes.boxDiv}><Error className={classes.icon} /> There are no Apps / Reports.</p>
                </Grid>
              </Grid>
        }
        {this.props.appsAndReports.modal.editForm &&
          <EditCategories open={this.props.appsAndReports.modal.editForm} onClose={this.closeEditCat} data={selectedRowsApps} />
        }
      </div>
    )
  }
}
AppsAndReport.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchApps: PropTypes.func,
  appsAndReports: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  appsAndReports: PropTypes.object,
  config: PropTypes.object,
}
const mapStateToProps = state => {
  const { appsAndReports, config } = state
  return {
    config,
    appsAndReports: appsAndReports,
    openModal,
    closeModal,
  }
}
export default connect(mapStateToProps, { fetchApps, openModal, closeModal })(withStyles(styles)(AppsAndReport))
