export const APP_CATEGORIES_LIST_API_CALL_INITIATED = 'APP_CATEGORIES_LIST_API_CALL_INITIATED'
export const APP_CATEGORIES_LIST_API_CALL_SUCCESS = 'APP_CATEGORIES_LIST_API_CALL_SUCCESS'
export const APP_CATEGORIES_LIST_API_CALL_FAIL = 'APP_CATEGORIES_LIST_API_CALL_FAIL'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const POST_APPS_CATEGORIES_API_CALL_FAIL = 'POST_APPS_CATEGORIES_API_CALL_FAIL'
export const POST_APPS_CATEGORIES_API_CALL_INITIATED = 'POST_APPS_CATEGORIES_API_CALL_INITIATED'
export const POST_APPS_CATEGORIES_API_CALL_SUCCESS = 'POST_APPS_CATEGORIES_API_CALL_SUCCESS'
export const DELETE_APPS_CATEGORIES_API_CALL_FAIL = 'DELETE_APPS_CATEGORIES_API_CALL_FAIL'
export const DELETE_APPS_CATEGORIES_API_CALL_INITIATED = 'DELETE_APPS_CATEGORIES_API_CALL_INITIATED'
export const DELETE_APPS_CATEGORIES_API_CALL_SUCCESS = 'DELETE_APPS_CATEGORIES_API_CALL_SUCCESS'
export const POST_APPS_CATEGORIES_API_CALL_ERROR = 'POST_APPS_CATEGORIES_API_CALL_ERROR'
export const CHECK_CATEGORIES_INITIATED = 'CHECK_CATEGORIES_INITIATED'
export const CHECK_CATEGORIES_SUCCESS = 'CHECK_CATEGORIES_SUCCESS'
export const CHECK_CATEGORIES_FAIL = 'CHECK_CATEGORIES_FAIL'