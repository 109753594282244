import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from "prop-types"
import { _ , uniq} from "lodash"
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Error from '@material-ui/icons/Error'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Modal} from 'nicollet-react'
import {fetchGroups,clearGroupList } from '../../store/groups/actionCreator'

const styles = theme => ({
  subParentContent: {
    flex: '1 1 auto',
    padding: '30px 40px 24px',
    overflowY: 'auto',
    // maxHeight: 700,
    // height: 'calc(100% - 96px)',
    // height: 400,
    height: 'calc(100vh - 50vh)',
  },
  subsActionStyle: {
    justifyContent: 'flex-end',
    // padding: '1em',
    borderTop: '2px solid #9e9e9ec4',
  },
  footerContentStyes: {
    position: 'relative',
    background: '#ffffff',
  },
  dialogParent: {
    // maxWidth: 'auto',
    width: 'auto',
    flex: '0 1 auto',
    // maxHeight: (!!document.documentMode === true && !!window.styleMedia === true) ? 'calc(100% - 16px)' : 'calc(100% - 96px)',
    display: 'flex',
    position: 'relative',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  /*****************************************************/
  button : {
    boxShadow: 'none',
    color: '#0b50dc',
    backgroundColor: "#FFF",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "#FFF",
    }
  },
  buttonDisabled : {
    color: '#818181 !important',
    backgroundColor: '#FFF !important',
    textTransform: 'none',
  },
  cancelButton :{
    boxShadow: 'none',
    backgroundColor: '#FFF',
    color: '#0049db',
    paddingRight: 20,
    textTransform: 'none',
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  buttonProgress: {
    color: '#FFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  tableWrapper: {
    minHeight: 200,
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableHd: {
    backgroundColor: '#f4f4f4',
    color : '#757575',
  },
  Hdrow: {
    color : '#818181',
  },
  inputStyle: {
    margin: '10px 0',
    padding: '5px 0',
    width: '55%',
    height: 40,
  },
})

class DisplayCategories extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      orderBy: 'ldsgroup',
      order: 'asc',
      selectedOption: ( props.selectedGrps) ? props.selectedGrps : [],
      grpList: [],
    }
    this.props.clearGroupList()
  }

  componentDidMount() {
    this.props.fetchGroups()
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps && this.props.groups !== nextProps.groups) {
      this.setStateValue(nextProps.groups.groupsList)
    }
  }
  setStateValue (data) {
    if (data && Object.keys(data).length > 0) {
      let selectedOption = this.state.selectedOption
      if(selectedOption && Array.isArray(selectedOption)) {
        let selectedObj = data.map(function (obj) {
          !!selectedOption.find(x => x.ldsgroup === obj.ldsgroup) ? obj.isAdded = true : obj.isAdded = false
          return { ...obj }
        })
        this.setState({grpList:selectedObj})
      }else{
        this.setState({grpList:data})
      }
    }
  }
  onAdd(user) {
    let newObj = (this.state.selectedOption) ? this.state.selectedOption.concat(user) : [user]
    this.setState({ selectedOption: newObj}, () => {
      this.setStateValue(this.state.grpList)
    })
  }
  handleFilter = event => {
    let filterResults = []
    let grpCount = 0
    filterResults = [...this.state.grpList].map(function (obj) {
      let newObj = { ...obj }
      if (newObj.ldsgroup && newObj.ldsgroup.toLowerCase().indexOf(event.currentTarget.value.toLowerCase()) !== -1) {
        newObj.show = true
        grpCount++
      } else {
        newObj.show = false
      }
      return newObj
    })
    this.setStateValue(filterResults)
  }
  desc (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting () {
    return this.state.order === 'desc' ? (a, b) => this.desc(a, b, this.state.orderBy) : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  handleRequestSort (property) {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({order, orderBy})
  }

  onSave= () => {
    this.props.onClose(this.state.selectedOption)
  }
  render () {
    const { classes,groups, open, onClose} = this.props
    const { selectedOption, order, orderBy } = this.state
    // let groupList =  groups.groupsList
    let groupList =  this.state.grpList.filter(grp => grp.show === undefined || grp.show === true)

    //const generatedList = boards.boardList.filter(board => board.show === undefined || board.show === true)

    return (
      <Modal isOpen={open} onClose={onClose} contentStyles={{overflow:'hidden',top: '55%', padding: 0}} closeButtonStyles={{right: 20}}>
        <div className={classes.dialogParent}>
          <div className={classes.subParentContent}>
            <h3 style={{margin:0}}> Add User Group </h3>
            <div className={classes.tableWrapper}>
              {groups.loading.groupsListLoading ?
                <CircularProgress size={50} className={classes.buttonProgress} />
                :groups.apiError ?
                  <Grid container spacing={24}>
                    <Grid item xs={12} className={classes.boxDiv}>
                      <p>An error occurred while retrieving Apps/Reports data. Please contact support if this issue persists.</p>
                    </Grid>
                  </Grid>
                  :groups.groupsListCount >= 1 ?
                    <React.Fragment>
                      <TextField
                        id="find"
                        type="text"
                        label="Find a User Group"
                        className={classes.inputStyle}
                        onChange={this.handleFilter.bind(this)}
                        variant="outlined"
                      />
                    <Table className={classes.table}>
                      <TableHead className={classes.tableHd}>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel className={classes.Hdrow} active={orderBy === 'ldsgroup'} direction={order} onClick={() => this.handleRequestSort('ldsgroup')}>
                              User Group
                            </TableSortLabel>
                          </TableCell>
                          {/*
                    <TableCell>
                      <TableSortLabel active={orderBy === 'provisioning'} direction={order} onClick={() => this.handleRequestSort('provisioning')}>
                        Provisioning
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel active={orderBy === 'partner_types'} direction={order} onClick={() => this.handleRequestSort('partner_types')}>
                        Partners Types
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel active={orderBy === 'statuses'} direction={order} onClick={() => this.handleRequestSort('statuses')}>
                      Status
                      </TableSortLabel>
                    </TableCell>
                    */}
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupList && groupList.sort(this.getSorting()).map((user, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.rowImage}>{user.ldsgroup}<span className={classes.name}>{user.sec_grp_n}</span></TableCell>
                            {/*
                    <TableCell>{user.provisioning}</TableCell>
                    <TableCell>{user.partner_types}</TableCell>
                    <TableCell>{user.statuses}</TableCell>
                  */}
                            <TableCell>
                              {user.isAdded ?
                                <Button variant="contained"
                                        color="primary"
                                        classes={{disabled : classes.buttonDisabled}}
                                        disabled={true}>
                                  Added
                                </Button>
                                :
                                <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.onAdd(user)}}>Add</Button>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    </React.Fragment>
                    : <Grid container spacing={24}>
                      <Grid item xs={12}><p className={classes.boxDiv}><Error className={classes.icon} /> There are no Apps / Reports.</p></Grid>
                    </Grid>
              }
            </div>
          </div>
          <Grid container className={classes.footerContentStyes}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subsActionStyle}>
              <div className={classes.buttonContainer}>
                <Button className={classes.cancelButton} onClick={onClose} disablefocusripple="true" color="primary" aria-label="close modal">
                  Cancel
                </Button>
                <Button disableRipple
                        disablefocusripple="true"
                        color="primary" aria-label="Submit"
                        disabled={selectedOption && !selectedOption.length}  o
                        onClick={this.onSave}>
                  Done
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    )
  }
}

DisplayCategories.propTypes = {
  classes: PropTypes.object.isRequired,
  groups: PropTypes.object,
  selectedGrps: PropTypes.array,
  grpList: PropTypes.object,
  fetchGroups: PropTypes.func,
  clearGroupList: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      selectedRowsId: PropTypes.array,
      selectedRowsCat: PropTypes.array,
    })
  ),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
}
const mapStateToProps = state => {
  const { groups } = state
  return {
    groups: groups,
  }
}
export default connect(mapStateToProps, {fetchGroups,clearGroupList})(withStyles(styles)(DisplayCategories))
