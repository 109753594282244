import {
  APPS_REPORTS_API_CALL_INITIATED,
  APPS_REPORTS_API_CALL_SUCCESS,
  APPS_REPORTS_API_CALL_FAIL,
  OPEN_MODAL,
  CLOSE_MODAL,
  APPS_REPORT_CATEGORIES_UPDATE,
  APPS_REPORT_CATEGORIES_UPDATE_SUCCESS,
  APPS_REPORT_CATEGORIES_UPDATE_FAIL,
  FETCH_APPS_REPORTS_API_CALL_INITIATED,
  FETCH_APPS_REPORTS_API_CALL_SUCCESS,
  FETCH_APPS_REPORTS_API_CALL_FAIL,
  CLEAR_APPS_REPORTS_API_CALL_INITIATED,
  POST_APPS_REPORTS_API_CALL_SUCCESS,
  POST_APPS_REPORTS_API_CALL_FAIL,
  POST_APPS_REPORTS_API_CALL_INITIATED,
  POST_APPS_REPORTS_API_CALL_ERROR,
  CHECK_APPS_INITIATED,
  CHECK_APPS_FAIL,
  CHECK_APPS_SUCCESS,
  CHECK_CONFIG,
  CONFIG_CALL_FAIL,
} from './actionType'

export const appsAndReportsState = {
  appsAndReportList: [],
  appsAndReportCount: 0,
  fetchApps: [],
  fetchAppsCount: 0,
  postSuccess: false,
  loading: {
    appsAndReportsLoading: true,
    updateLoading: false,
    postAppLoading: false,
  },
  modal: {
    editForm: false,
    addGrp: false,
  },
  toast: {
    message: '',
    open: false,
  },
}

export default function appsAndReports(state = appsAndReportsState, action = {}) {
  let loading
  let modal
  switch (action.type) {
    case CHECK_APPS_INITIATED:
      loading = {
        ...state.loading,
        appReportsLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case CHECK_APPS_SUCCESS:
      loading = {
        ...state.loading,
        appReportsLoading: false,
      }
      return {
        ...state,
        loading,
        appReportsCheck: action.data,
      }
    case CHECK_APPS_FAIL:
      loading = {
        ...state.loading,
        appReportsLoading: false,
      }
      return {
        ...state,
        loading,
        appReportsCheckError: action.error
      }
    case OPEN_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: true,
      }
      return {
        ...state,
        modal,
      }
    case CLOSE_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: false,
      }
      return {
        ...state,
        modal,
      }
    case APPS_REPORTS_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appsAndReportsLoading: true,
      }
      return {
        ...state,
        loading,
        postSuccess: false,
      }
    case APPS_REPORTS_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appsAndReportsLoading: false,
      }
      let appsAndReportList = action.data.result
      let appsAndReportCount = action.data.result.length
      return {
        ...state,
        loading,
        appsAndReportList,
        appsAndReportCount
      }
    case APPS_REPORTS_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        appsAndReportsLoading: false,
      }
    /****/
    case APPS_REPORT_CATEGORIES_UPDATE:
      loading = {
        ...state.loading,
        updateLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case APPS_REPORT_CATEGORIES_UPDATE_SUCCESS:
      loading = {
        ...state.loading,
        updateLoading: false,
      }
      return {
        ...state,
        loading,
      }
    case APPS_REPORT_CATEGORIES_UPDATE_FAIL:
      return {
        ...state,
        apiError: action.error,
        updateLoading: false,
      }
    /****/
    case FETCH_APPS_REPORTS_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appsAndReportsLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case FETCH_APPS_REPORTS_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appsAndReportsLoading: false,
      }
      let fetchApps = action.data.result
      let fetchAppsCount = (action.data.result) ? 1 : 0
      return {
        ...state,
        loading,
        fetchApps: fetchApps,
        fetchAppsCount: fetchAppsCount,
      }
    case FETCH_APPS_REPORTS_API_CALL_FAIL:
      loading = {
        ...state.loading,
        appsAndReportsLoading: false,
      }
      return {
        ...state,
        loading,
        apiError: action.data,
      }
    case CLEAR_APPS_REPORTS_API_CALL_INITIATED:
      return {
        ...state,
        loading,
        fetchApps: null,
        fetchAppsCount: null
      }
    /****/
    case POST_APPS_REPORTS_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        postAppLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case POST_APPS_REPORTS_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        postAppLoading: false,
      }
      return {
        ...state,
        loading,
        postSuccess: true,
        redirect : true
      }
    case POST_APPS_REPORTS_API_CALL_FAIL:
      loading = {
        ...state.loading,
        postAppLoading: false,
      }
      return {
        ...state,
        loading,
        apiError: action.error,
      }
    case POST_APPS_REPORTS_API_CALL_ERROR:
      loading = {
        ...state.loading,
        postAppLoading: false,
      }
      return {
        ...state,
        loading,
        status: action.status,
        text: action.text,
      }
    /****/
    default:
      return state
  }
}
