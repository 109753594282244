import { reactCellRendererFactory } from 'ag-grid-react'
import moment from 'moment-timezone'
import { displayTzFormat } from '../../Utills/dateHelper'
import apiConfig from '../../config/apiConfig'

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: "Group Name",
        field: "help_metric_group_name",
        width: 300,
        cellStyle: {color: "#0950dc", fontWeight: 600},
        cellRenderer: (params) => {
          let url = '/helpCategories/edit/'+ params.data.help_metric_group_id
          let link = document.createElement('a')
          link.href = url
          link.innerText = params.value
          link.addEventListener('click', (e) => {
            e.preventDefault()
            handleClick(params)
          })
          return link
        },
      },
      {
        headerName: "Description",
        field: "help_metric_group_description",
        width: 1000,
      },
    ]
  }
}
