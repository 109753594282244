import axios from 'axios'
import {
  GROUPS_LIST_API_CALL_INITIATED,
  GROUPS_LIST_API_CALL_SUCCESS,
  GROUPS_LIST_API_CALL_FAIL,
  GROUPS_LIST_CLEAR,
} from './actionType'

import apiConfig from '../../config/apiConfig'

export function fetchGroups () {
  return function (dispatch) {
    dispatch(groupsApiCallInitiated({
      type: GROUPS_LIST_API_CALL_INITIATED,
      data: [],
    }))
    return axios.get(`${apiConfig.adminApi.host}app_sec_groups_list`, {
      data: {},
    })
      .then(response => {
        dispatch(groupsApiCallSuccess(response.data.result))
      })
      .catch((error) => {
        dispatch(groupsApiCallFail(error))
      })
  }
}
export function groupsApiCallInitiated (data) {
  return {
    type: GROUPS_LIST_API_CALL_INITIATED,
    data,
  }
}
export function groupsApiCallSuccess (data) {
  return {
    type: GROUPS_LIST_API_CALL_SUCCESS,
    data,
  }
}
export function groupsApiCallFail (error) {
  return {
    type: GROUPS_LIST_API_CALL_FAIL,
    error,
  }
}
export function clearGroupList(error) {
  return {
    type: GROUPS_LIST_CLEAR,
    error,
  }
}
