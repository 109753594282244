import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Collapse from '@material-ui/core/Collapse'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    overflow: 'hidden',
    minWidth: 700,
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  fab: {
    position: 'absolute',
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
    height: '48px',
    width: '48px',
  },
  participantIcon: {
    marginRight: 10,
    height: 48,
    width: 48,
    borderRadius: 40,
    position: 'relative',
    fontSize: 16,
  },
  rowImage: {
    minWidth: 60,
    height: 65,
    position: 'relative',
  },
  name: {
    position: 'absolute',
    marginTop: 20,
  },
  tableWrapper: {
    overflowX: 'auto',
    width: '100%',
  },
  tableHead: {
    width: '100%',
  },
})

class userTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      orderBy: 'ldsgroup',
      order: 'asc',
      open: false,
      current: -1,
    }
  }

  componentWillMount () {
    const { groupList, provisioning } = this.props
    for (let i=0; i<groupList.length; i++) {
      if (provisioning && groupList[i].ldsgroup === provisioning.group_name) {
        groupList[i]['application_name'] = provisioning.application_name
        groupList[i]['partner_type'] = provisioning.partner_type
        groupList[i]['provisioning'] = provisioning.provisioning
      }
    }
  }

  expandAccess = (e) => {
    let current = e.currentTarget.dataset.id
    this.setState({ open: !this.state.open, current: current })
  }

  desc (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting () {
    return this.state.order === 'desc' ? (a, b) => this.desc(a, b, this.state.orderBy) : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  handleRequestSort (property) {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({order, orderBy})
  }
  render () {
    const {classes, groupList, provisioning} = this.props
    const {order, orderBy, open, current} = this.state
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>
                &nbsp;
              </TableCell>
              <TableCell style={{width: '40%'}}>
                <TableSortLabel
                  active={orderBy === 'ldsgroup'}
                  direction={order}
                  onClick={() => this.handleRequestSort('ldsgroup')}
                >
                  User Group
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'provisioning'}
                  direction={order}
                  onClick={() => this.handleRequestSort('provisioning')}
                >
                  Provisioning
                </TableSortLabel>
              </TableCell>
              <TableCell>
                &nbsp;
              </TableCell>
              <TableCell>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {provisioning && provisioning.sort(this.getSorting()).map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <IconButton aria-label="expand row" data-id={index} size="small" onClick={this.expandAccess}>
                    {open && (current==index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell className={classes.rowImage}>
                  {user.group_name}
                  <span className={classes.name}>{user.display_name}</span>
                </TableCell>
                <TableCell>
                  {user.provisioning}
                </TableCell>
                <TableCell>
                  <Collapse in={open && (current == index)} timeout="auto" unmountOnExit>
                    <Table size="small">
                      {user.partner_type && <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'partner_types'}
                              direction={order}
                              onClick={() => this.handleRequestSort('partner_types')}
                            >
                              Partners Types
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'statuses'}
                              direction={order}
                              onClick={() => this.handleRequestSort('statuses')}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>}
                      <TableBody>
                      {user.partner_type && user.partner_type.sort(this.getSorting()).map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            {item.name}
                          </TableCell>
                          <TableCell>
                            {item.status.join(', ')}
                          </TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Tooltip id="uploadManagerIcon" title="Delete" >
                    <IconButton disabled={this.props.disabled} className={classes.button} aria-label="Delete" onClick={() => {
                      this.props.onDelete(user)
                    }}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

userTable.propTypes = {
  classes: PropTypes.object.isRequired,
  groupList: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  provisioning: PropTypes.object,
}

export default withStyles(styles)(userTable)
