class Cookies {
  cookies = () => document.cookie && createObjectFromEntries(document.cookie.split(';').map(it => it.trim().split('=')))
  getAll = () => this.cookies()
  get = (value) => this.cookies()[value]
  set = (key, value) => { document.cookie = `${key}=${value}` }
  remove = (key) => { document.cookie = `${key}=; expires=${Date.now()};` }
}

function createObjectFromEntries (array) {
  return Object.assign({}, ...Array.from(array, ([k, v]) => ({[k]: v})))
}

export default Cookies
