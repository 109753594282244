import { withStyles, Grid, Button, Paper } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Add from '@material-ui/icons/Add'
import HelpCatGridConfig from './HelpCatGridConfig'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { getHelpCategories, openModal, closeModal, editHelpCategories, deleteHelpCategories } from '../../store/manageSupport/actionCreator'
import ManageHelpCategoriesForm from './ManageHelpCategoriesForm'

const categoriesStyle = theme => ({
  tableSize: {
    height: 'calc(100vh - 200px)',
  },
})

class ManageHelpCategories extends Component {
  constructor(props) {
    super(props)
    this.gConfig =  new HelpCatGridConfig()
    this.state = {
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        // width: 100
      },
      rowData:  [],
      // enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting : true,
      /** For application **/
      enableEditCat:true,
      selectedRowsCat:'',
      selectedRowsId:'',
      selectedRowsApps:'',
      openModal : false,
      isAdd : false,
      selectedRow: ''
      /** For application **/
    }
    this.gridOptions = {
      columnDefs : this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady :this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      // floatingFilter: true, // Show filter in each column
      // this is a simple property
      rowBuffer: 10, // no need to set this, the default is fine for almost all scenarios,
      onRowSelected: this.rowSelected,
      onSelectionChanged: this.onSelectionChanged.bind(this),
      onCellEditingStopped: this.onCellEditingStopped,
      // onRowClicked: this.RowClickEventHandler,
      // sideBar: true,
    }
  }
  componentWillMount () {
    this.props.getHelpCategories()
  }
  componentWillReceiveProps (nextProps) {
    if ((nextProps.helpCategories.data !== this.props.helpCategories.data) && nextProps.helpCategories.data && nextProps.helpCategories.data.length > 0) {
      this.setState({rowData:nextProps.helpCategories.data})
    }
    if (nextProps.editCategories !== this.props.editCategories && (nextProps.editCategories.deleteCatSuccess || nextProps.editCategories.editCatSuccess)) {
      this.props.getHelpCategories()
    }
  }
  onSelectionChanged(event) {
    let selectedRows = this.gridApi.getSelectedRows()
    this.setState({ rowSelected: selectedRows[0]})
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("searchCategories").value)
  }
  onGridReady = params => {
    this.gridApi = params.api
  }
  handleClick = params => {
    this.setState({selectedRow :params.data, isAdd: false },()=>{
      this.props.openModal('helpCatForm', 'Edit Help Categories')
    })
  }
  handleAddCat = () => {
    this.setState({selectedRow: null, isAdd: true}, () => {
      this.props.openModal('helpCatForm', 'Add Help Categories')
    })
  }
  modalClose = formName => {
    this.props.closeModal(formName)
  }
  render() {
    const { classes, helpCategories, manageSupport } = this.props
    return (
      <div>
        <HeaderTitle title="Manage Help Categories" />
        <Helmet>
          <title>Manage Help Categories</title>
        </Helmet>
        {helpCategories.getCatLoading ? <CircularProgress size={50} className={classes.buttonProgress} />
          : !helpCategories.getCatSuccess
            ? <Grid container spacing={24} >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>An error occurred while retrieving Help Categories data. Please contact support if this issue persists.</p>
              </Grid>
            </Grid>
            : <Grid container spacing={24} className={classes.container}>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.handleAddCat()}>
                    <Add className={classes.buttonIcon}/>
                    Add
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.search} elevation={1}>
                    <IconButton className={classes.iconButton} aria-label="Search"><SearchIcon /></IconButton>
                    <InputBase className={classes.input} placeholder="Search List"
                      onInput={this.onQuickFilterChanged.bind(this)}
                      id="searchCategories"
                    />
                  </Paper>
                </Grid>
                {helpCategories.data ? <Grid item xs={12}>
                  <div className={[classes.tableSize,'ag-theme-material full-row'].join(' ')}>
                    <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
                  </div>
                </Grid>
                : <Grid item xs={12}>
                    <p className={classes.boxDiv}><Error className={classes.icon} /> There are no Help Categories.</p>
                  </Grid>}
              </Grid>
        }
        {manageSupport.helpCatForm && <ManageHelpCategoriesForm
          formName={manageSupport.name}
          closeModal={this.modalClose}
          data={this.state.selectedRow}
          manageSupport={manageSupport}
          isAdd={this.state.isAdd}
          editHelpCategories={this.props.editHelpCategories}
          getHelpCategories={this.props.getHelpCategories}
          deleteHelpCategories={this.props.deleteHelpCategories}
        />}
      </div>
    )
  }
}

ManageHelpCategories.propTypes = {
  getHelpCategories: PropTypes.func.isRequired,
  helpCategories: PropTypes.object,
  classes: PropTypes.object,
  editHelpCategories: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  manageSupport: PropTypes.object,
  deleteHelpCategories: PropTypes.func,
  editCategories: PropTypes.object,
}

const mapStateToProps = state => ({
  helpCategories: state.getHelpCategories,
  editCategories: state.editHelpCategories,
  manageSupport: state.manageSupport,
})

const mapDispatchToProps = dispatch => ({
  getHelpCategories: () => dispatch(getHelpCategories()),
  editHelpCategories: (data) => dispatch(editHelpCategories(data)),
  deleteHelpCategories: (id, name) => dispatch(deleteHelpCategories(id, name)),
  openModal: (supportForm, name) => dispatch(openModal(supportForm, name)),
  closeModal: (formName) => dispatch(closeModal(formName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(categoriesStyle)(ManageHelpCategories))
