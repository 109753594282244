import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import moment from 'moment'
import PropTypes from 'prop-types'

const styles = theme => ({
  progressBarRequest: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      top: '4.5em',
    },
    [theme.breakpoints.down('lg')]: {
      top: '4.5em',
    },
    [theme.breakpoints.down('md')]: {
      top: '4.5em',
    },
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
    [theme.breakpoints.down('xs')]: {
      top: 0,
    },
  },

})

class SessionTimeout extends Component {
  constructor (props) {
    super(props)
    let oUser = this.props.LoginCtrl.getPayLoad('id_token')
    let idTokenExp = (oUser) ? oUser.exp : ''
    let idTokenExpUtc = (idTokenExp) ? moment(new Date(0).setUTCSeconds(idTokenExp)) : ''
    // idTokenExpUtc= moment(idTokenExpUtc).subtract(470, 'm') // To check by increase by minutes
    this.state = {
      warningTime: 5, // minutes // 480 => 8hours
      open: false,
      date: '',
      idTokenExpUtc: idTokenExpUtc,
      sessionTime: '',
    }
  }
  componentWillMount () {
    let currentTime = moment(new Date())
    let warnTime = moment(currentTime).add(this.state.warningTime, 'm').format('YYYY-MM-DD HH:mm:ss')
    let duration = (this.state.idTokenExpUtc) ? moment.duration(this.state.idTokenExpUtc.diff(warnTime)) : ''
    let asMillSec = (duration) ? duration.asMilliseconds() : ''
    let warnTime1 = moment(currentTime).format('YYYY-MM-DD HH:mm:ss')
    let actDuration = (this.state.idTokenExpUtc) ? moment.duration(this.state.idTokenExpUtc.diff(warnTime1)) : ''
    let actMillSec = (actDuration) ? actDuration.asMilliseconds() : ''
    this.setState({warningTime: asMillSec, sessionTime: actMillSec}, () => { this.setTimeout() })
  }
  warn = () => {
    this.setState({ open: true })
    this.timerID = setInterval(() => this.tick(), 1000)
  }
  setTimeout () {
    if (this.state.warningTime) {
      setTimeout(this.warn, this.state.warningTime)
    }
    if (this.state.sessionTime) {
      setTimeout(this.handleSignout, this.state.sessionTime)
    }
  }
  componentWillUnmount () {
    clearInterval(this.timerID)
  }
  tick () {
    let aduration = (this.state.idTokenExpUtc) ? moment.duration(this.state.idTokenExpUtc.diff(moment(new Date()))) : ''
    if (aduration) {
      // let hur = parseInt(aduration.asHours())
      let min = (parseInt(aduration.asMinutes()) % 60) > 0 ? (parseInt(aduration.asMinutes()) % 60) + ' minutes and ' : ''
      let sec = parseInt(aduration.asSeconds()) % 60
      let str = min + sec + ' seconds'
      this.setState({date: str})
    }
  }
  getTime () {
    return this.state.date
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  handleSignout = (e) => {
    this.props.signOut()
    this.handleClose()
    if (e) {
      fireflyHelper.clickFirefly(e)
    }
  }
  render () {
    // const { classes } = this.props
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">You will be logged out soon.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have been logged in for almost 8 hours. Whew! For security, you will be logged out of all Target tabs in {this.getTime()}. Save your work in all tabs so you do not lose anything.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SessionTimeout.propTypes = {
  LoginCtrl: PropTypes.object,
  signOut: PropTypes.func,
}

export default withStyles(styles)(SessionTimeout)
