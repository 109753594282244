export const REASONFORSUPPORT_API_CALL_INNITIATED =
  "REASONFORSUPPORT_API_CALL_INNITIATED";
export const REASONFORSUPPORT_API_CALL_SUCCESS =
  "REASONFORSUPPORT_API_CALL_SUCCESS";
export const REASONFORSUPPORT_API_CALL_FAIL = "REASONFORSUPPORT_API_CALL_FAIL";

/*************** Fetching By Id *********************/
export const REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED =
  "REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED";
export const REASONFORSUPPORT_BY_ID_API_CALL_SUCCESS =
  "REASONFORSUPPORT_BY_ID_API_CALL_SUCCESS";
export const REASONFORSUPPORT_BY_ID_API_CALL_FAIL =
  "REASONFORSUPPORT_BY_ID_API_CALL_FAIL";

/*************** Post Reason For Support Form *********************/
export const REASONFORSUPPORT_POST_API_CALL_INNITIATED =
  "REASONFORSUPPORT_POST_API_CALL_INNITIATED";
export const REASONFORSUPPORT_POST_API_CALL_SUCCESS =
  "REASONFORSUPPORT_POST_API_CALL_SUCCESS";
export const REASONFORSUPPORT_POST_API_CALL_FAIL =
  "REASONFORSUPPORT_POST_API_CALL_FAIL";
export const REASONFORSUPPORT_POST_API_CALL_FINAL = 'REASONFORSUPPORT_POST_API_CALL_FINAL'

/*************** Post Reason For Support Error *******************/
export const REASONFORSUPPORT_POST_API_CALL_ERROR = "REASONFORSUPPORT_POST_API_CALL_ERROR"

export const CHECK_REASON_SUCCESS = 'CHECK_REASON_SUCCESS'
export const CHECK_REASON_FAIL = 'CHECK_REASON_FAIL'
export const CHECK_REASON_INITIATED = 'CHECK_REASON_INITIATED'
