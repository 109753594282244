const SIGN_IN = 'auth/SIGN_IN'
const SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS'
const SIGN_OUT = 'auth/SIGN_OUT'
const PROMPT_SIGN_IN = 'auth/PROMPT_SIGN_IN'
const DECLINE_SIGN_IN = 'auth/DECLINE_SIGN_IN'
const PROMPT_NO_ACCESS = 'auth/PROMPT_NO_ACCESS'
const CLOSE_NO_ACCESS = 'auth/CLOSE_NO_ACCESS'
import { get, minBy } from 'lodash'
import { adGroupFuncList } from '../../config/adGroupsConfig'
import apiConfig from '../../config/apiConfig'
import { AD_EXTERNAL_BASIC } from '../../config/adGroups'

export const signIn = () => ({ type: SIGN_IN })

export const signInSuccess = payload => ({ type: SIGN_IN_SUCCESS, payload })

export const signOut = () => ({ type: SIGN_OUT })

export const promptToSignIn = () => ({ type: PROMPT_SIGN_IN })

export const declineSignIn = () => ({ type: DECLINE_SIGN_IN })

export const promptToNoAccess = () => ({ type: PROMPT_NO_ACCESS })

const formatAdGroups = (groups = []) => groups.reduce((result, group) => {
  group.split(',').forEach(attribute => {
    if (attribute.indexOf('CN=') === 0) result.push(attribute.split('CN=')[1])
  })
  return result
}, [])

export const getLoginUserAdGroupData = (memberOf = [], filterBy = 'priority') => minBy(adGroupFuncList.filter(e => (memberOf.indexOf(e.adGroupName) > -1)) || [], filterBy)
export const getAcessibleModuleList = (memberOf = []) => get(getLoginUserAdGroupData(memberOf), 'moduleList', [])
export const isValidADUser = ( memberOf = [] ) => (memberOf.length === 1 && memberOf.indexOf(AD_EXTERNAL_BASIC) > -1)

// export const formatUserInfo = (info = {}) => {
//   return {
//     email: info.mail,
//     firstName: info.firstname,
//     lanId: info.samaccountname,
//     lastName: info.lastname,
//     memberOf: formatAdGroups(info.memberof),
//     accessToken: info.accessToken,
//   }
// }
export const formatUserInfo = (info = {}) => {
  const { flags = {} } = apiConfig
  const { mail = '', firstname = '', samaccountname = '', lastname = '', memberof = [], accessToken = '', xIdToken = '' } = info
  const memberOfAdGroups = formatAdGroups(memberof) || []
  const { ENABLE_RBA_MODULES = true } = flags
  const accessibleModulesList = ENABLE_RBA_MODULES ? getAcessibleModuleList(memberOfAdGroups) : []
  const loginUserAdGroupData = ENABLE_RBA_MODULES ? getLoginUserAdGroupData(memberOfAdGroups) : {}
  const isProvisionedUser = ENABLE_RBA_MODULES ? isValidADUser(memberOfAdGroups) : false
  return {
    email: mail,
    firstName: firstname,
    lanId: samaccountname,
    lastName: lastname,
    memberOf: memberOfAdGroups,
    accessToken: accessToken,
    accessibleModules: accessibleModulesList,
    loginUserAdGroupData: loginUserAdGroupData,
    isProvisionedUser: isProvisionedUser,
    xIdToken: xIdToken,
  }
}

export const initialState = {
  ...formatUserInfo(),
  isAuthorized: false,
  popupCount: 0,
  popupType: null,
  authModalIsShown: false,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN:
      return {
        ...state,
        popupType: 'login',
        popupCount: state.popupCount + 1,
      }

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...formatUserInfo(payload),
        isAuthorized: true,
        authModalIsShown: false,
      }

    case SIGN_OUT:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        popupType: 'logout',
        popupCount: state.popupCount + 1,
      }
    case PROMPT_NO_ACCESS:
      return {
        ...state,
        noAccessModalShown: true,
      }
    case CLOSE_NO_ACCESS:
      return {
        ...state,
        noAccessModalShown: false,
      }
    case PROMPT_SIGN_IN:
      return {
        ...state,
        authModalIsShown: true,
      }

    case DECLINE_SIGN_IN:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        authModalIsShown: false,
      }

    default:
      return state
  }
}

export default reducer
