import { reactCellRendererFactory } from 'ag-grid-react';

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: "User Group Name",
        field: "ad_group_name",
        pinned: true,
        sort: "asc",
        width: 300,
        cellRenderer: (params) => {
          let link = document.createElement('a');
          link.href = '/';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            handleClick(params)
          });
          return link;
        }
      },
      {
        headerName: "LDS Group Description",
        field: "ad_group_desc",
        width: 500,
      },
    ]
  }
  onBtExport(){
    let params = {
      skipHeader: getBooleanValue("#skipHeader"),
      columnGroups: getBooleanValue("#columnGroups"),
      skipFooters: getBooleanValue("#skipFooters"),
      skipGroups: getBooleanValue("#skipGroups"),
      skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      allColumns: getBooleanValue("#allColumns"),
      onlySelected: getBooleanValue("#onlySelected"),
      fileName: 'Pol',
      sheetName: 'apps',
      exportMode: true
    };
    this.gridApi.exportDataAsCsv(params);
  }
}

function getBooleanValue(cssSelector) {
  // return document.querySelector(cssSelector).checked === true;
}
