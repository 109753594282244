import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'nicollet-react'
import {
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  withStyles,
  MenuItem,
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  FormLabel,
  Switch
} from '@material-ui/core'
import Select from 'react-select'
import ManageHelpCategories from './ManageHelpCategories'

const formStyles = theme => ({
  container: {
    padding: 10,
  },
  savebutton: {
    backgroundColor: '#0950dc',
    color: '#ffffff',
    margin: '0px 15px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  focused: {
    color: '#000000',
  },
  formControl: {
    minWidth: 120,
  },
  selectEl: {
    padding: 0,
  },
  selectElement: {
    width: '100%',
  },
  selectTitle: {
    marginBottom: 10,
  },
  switch: {
    color: '#0950dc',
  },
  scrollContainer: {
    overflowY: 'auto',
  },
})

class ManageSupportForm extends Component {
  constructor(props) {
    super(props)
    const {data} = this.props
    let defaultValue = this.props.helpCategories.data.filter(item => item.help_metric_group_name === 'Default')
    this.state = {
      label: data && data.metric_name,
      type: data ? data.root_type : 'Branch',
      supportType: data && data.support_type,
      supportOption: data && data.request_support && data.request_support.request_support_id,
      published: data ? data.published : false,
      displayOrder: data ? data.sort_order : null,
      formName: this.props.formName,
      helpMetricGroups: data ? data.help_metric_groups : defaultValue,
      checked: data && data.parent_node === 0 ? true : false,
      parent: false,
      queueName: data && data.queue_name,
      recordType: data && data.record_type,
      accountId: data && data.account_id
    }
  }

  componentWillMount () {
    this.dataObj = this.state
  }

  componentDidMount () {
    if (this.state.formName !== 'Add' && this.props.data) {
      let supportOptionData = this.props.supportOptions && this.props.supportOptions.find(item => item.id === (this.props.data.request_support && this.props.data.request_support.request_support_id))
      this.setState({ supportOption: supportOptionData && supportOptionData.id })
    }
    if (this.state.formName === 'Add') {
      this.setState({
        label: '',
        type: 'Branch',
        supportType: '',
        supportOption: null,
        published: false,
        displayOrder: 0,
        helpMetricGroups: this.props.helpCategories.data.filter(item => item.help_metric_group_name === 'Default')
      })
    }
    if (this.props.helpCategories.data.length > 0) {
      let suggestions = this.props.helpCategories.data.map(item => ({
        label: item.help_metric_group_name,
        value: item.help_metric_group_id,
      }))
      this.setState({ suggestions: suggestions })
    }
  }

  saveToDb = () => {
    let x = this
    const { label, type, supportOption, formName, published, displayOrder, helpMetricGroups, parent, queueName, recordType, accountId } = this.state
    let obj = {
      "metric_id": this.props.data && this.props.data.metric_id,
      "metric_name": x.dataObj.label !== label ? label : null,
      // "current_parent_id": this.props.data && this.props.data.current_parent_id,
      // "support_type": type === 'Leaf' ? 'Service Now' : null,
      "new_parent_id": parent ? "MA==" : null,
      "forced_action": parent ? false : null,
      "support_option_id": (type === 'Leaf' && x.dataObj.supportOption !== supportOption) ? supportOption : null,
      "root_type": x.dataObj.type !== type ? type : null,
      "published": x.dataObj.published !== published ? published : null,
      "sort_order": displayOrder === 0 ? null : displayOrder,
      "help_groups": x.dataObj.helpMetricGroups !== helpMetricGroups ? helpMetricGroups.map(item => item.help_metric_group_id).toString() : null,
      "queue_name": queueName,
      "record_type": recordType,
      "account_id": accountId,
    }
    let objForAdd = {
      "metric_name": label,
      // "support_type": type === 'Leaf' ? 'Service Now' : null,
      "support_option_id": supportOption ? supportOption : null,
      "root_type": type,
      "published": published ? published : false,
      "sort_order": displayOrder,
      "help_groups": helpMetricGroups.map(item => item.help_metric_group_id).toString(),
      "queue_name": queueName,
      "record_type": recordType,
      "account_id": accountId,
    }
    if (formName !== 'Add') {
      this.props.editSupport(obj)
    } else {
      this.props.addSupport(objForAdd)
    }
    this.closeModal()
  }
  closeModal = () => {
    this.props.closeModal('supportForm')
  }
  handleChange = name => event => {
    if (name === 'published') {
      this.setState({ [name]: event.target.value === 'true' })
    } else {
      this.setState({ [name]: event.target.value })
    }
  }
  // handleBlur = () => {
  //   if (this.state.type === 'Leaf') {
  //     this.props.checkForRedundancy(this.state.label, this.state.type)
  //   }
  // }
  typeChange = (event) => {
    this.setState({ type: event.target.value })
  }
  chipSelected = event => {
    let helpCats = event.map(item => item.value)
    let helpData = []
    this.props.helpCategories.data.forEach(item => {
      helpCats.forEach(i => {
        if(i === item.help_metric_group_id){
          helpData.push(item)
        }
      })
    })
    this.setState({ helpMetricGroups: helpData })
  }
  render() {
    const { label, type, published, supportOption, displayOrder, suggestions, helpMetricGroups } = this.state
    const { classes, supportOptions, formName } = this.props
    const salesforceSelected = supportOptions.find(item => item.id === this.state.supportOption)?.destination_system_name === 'SalesForce'
    return (
      <Modal isOpen={true} onClose={this.closeModal} contentStyles={{ maxWidth: '700px', display: 'flex', flexDirection: 'column' }}>
        <Grid container className={classes.container}>
          <h2>{formName} Node</h2>
        </Grid>
        <Grid container className={classes.scrollContainer}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container className={classes.container}>
              <TextField inputProps={{ maxLength: 255 }} color="default" id="outlined-label" label="Label" type="text" variant="outlined" value={label} onChange={this.handleChange('label')} />
            </Grid>
            <Grid container className={classes.container}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup row aria-label="position" name="position" defaultValue="top" value={type} onChange={this.typeChange}>
                  <FormControlLabel
                    value="Branch"
                    control={<Radio color="default" />}
                    label="Branch"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Leaf"
                    control={<Radio color="default" />}
                    label="Leaf"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {type === 'Leaf' && <Grid container className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Support Option</InputLabel>
                <MaterialSelect
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={supportOption}
                  onChange={this.handleChange('supportOption')}
                  placeholder="Select a support option"
                  className={classes.selectEl}
                >
                  {supportOptions && supportOptions.length>0 && supportOptions.map(item => (
                      (!item.archive && <MenuItem value={item.id}>{item.reason_for_support}</MenuItem>)
                    ))}
                </MaterialSelect>
              </FormControl>
            </Grid>}
            {salesforceSelected && <Grid container className={classes.container}>
              <TextField
                label="Add queue name"
                value={this.state.queueName}
                onChange={this.handleChange('queueName')}
              />
            </Grid>}
            {salesforceSelected && <Grid container className={classes.container}>
              <TextField
                label="Add record type"
                value={this.state.recordType}
                onChange={this.handleChange('recordType')}
              />
            </Grid>}
            {salesforceSelected && <Grid container className={classes.container}>
              <TextField
                label="Add account id"
                value={this.state.accountId}
                onChange={this.handleChange('accountId')}
              />
            </Grid>}
            <Grid container className={classes.container}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Published</FormLabel>
                <RadioGroup row aria-label="position" name="position" defaultValue="top" value={published} onChange={this.handleChange('published')}>
                  <FormControlLabel
                    value={true}
                    control={<Radio color="default" />}
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="default" />}
                    label="No"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container className={classes.container}>
              <TextField color="default" id="outlined-label" label="Display Order" type="number" variant="outlined" value={displayOrder} onChange={this.handleChange('displayOrder')} />
            </Grid>
            <Grid container className={classes.container}>
              {/* <Typography className={classes.selectTitle}>Help Categories</Typography> */}
              <FormLabel component="legend" className={classes.selectTitle}>Help Categories</FormLabel>
              <Select
                options={suggestions}
                isMulti
                name="Help Categories"
                className={[classes.selectElement, "basic-multi-select"].join(' ')}
                classNamePrefix="select"
                onChange={this.chipSelected}
                defaultValue={helpMetricGroups ? helpMetricGroups.map(item => ({
                  label: item.help_metric_group_name,
                  value: item.help_metric_group_id,
                })) : []}
              />
            </Grid>
            {formName !== 'Add' && <Grid container className={classes.container}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    className={classes.switch}
                    disabled={this.state.checked}
                    onChange={e => this.setState({parent: !this.state.checked})}
                    name="parent"
                    inputProps={{ 'aria-label': 'convert to parent node' }}
                  />
                }
                label="Convert to Parent Node"
              />
            </Grid>}
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={[classes.container, classes.buttonContainer].join(' ')}>
          <Button className={classes.cancelbutton} onClick={this.closeModal}>Cancel</Button>
          <Button disabled={this.state.supportOption === null && type === 'Leaf'} className={classes.savebutton} onClick={this.saveToDb}>Save</Button>
        </Grid>
      </Modal>
    )
  }
}

ManageSupportForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object,
  fetchSupportOptions: PropTypes.func,
  supportOptions: PropTypes.array.isRequired,
  editSupport: PropTypes.func,
  formName: PropTypes.string,
  addSupport: PropTypes.func,
  helpCategories: PropTypes.object,
  // checkForRedundancy: PropTypes.func,
  // checkNode: PropTypes.object,
}

export default withStyles(formStyles)(ManageSupportForm)
