import { CHECK_CONFIG, CONFIG_CALL_FAIL } from './actionType'

function dataMapping (data) {
  let result = data.map(item => {
    switch(item.access_name){
      case "ADMIN": return { "admin": item.has_access }
      case "SUPPORT": return {"support" : item.has_access}
      case "CONTENT": return {"content" : item.has_access}
      default: return {"admin" : true}
    }
  })
  const output = Object.assign({},...result)
  return output
}

export default function config(state = [], action = {}) {
  switch(action.type){
    case CHECK_CONFIG:
      return {
        ...state,
        success: true,
        data: dataMapping(action.data.result)
      }
    case CONFIG_CALL_FAIL:
      return {
        ...state,
        success: false,
        error: action.error
      }
    default:
      return state
  }
}