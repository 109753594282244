import {
  CATEGORIES_LIST_API_CALL_INITIATED,
  CATEGORIES_LIST_API_CALL_SUCCESS,
  CATEGORIES_LIST_API_CALL_FAIL,
} from './actionType'

export const categoriesState = {
  categoriesList: [],
  categoriesListCount: 0,
  loading: {
    categoriesListLoading: true,
  },
  modal: {
    editForm: false,
  },
  toast: {
    message: '',
    open: false,
  },
}

export default function categories (state = categoriesState, action = {}) {
  let loading
  let modal
  switch (action.type) {
    case CATEGORIES_LIST_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        categoriesListLoading: true,
      }
      return {
        ...state, loading,
      }
    case CATEGORIES_LIST_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        categoriesListLoading: false,
      }
      let categoriesList = action.data
      let categoriesListCount = action.data.length
      return {
        ...state, loading,
        categoriesList: categoriesList,
        categoriesListCount: categoriesListCount,
      }
    case CATEGORIES_LIST_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        categoriesListLoading: false,
      }
    default:
      return state
  }
}
