import { reactCellRendererFactory } from 'ag-grid-react'
import moment from 'moment-timezone'
import { displayTzFormat } from '../../Utills/dateHelper'
import apiConfig from '../../config/apiConfig'

const style = {
  'white-space': 'normal',
  'line-height': '12px',
  'display': 'flex',
  'flex-direction': 'column',
  'justify-content': 'center'
}

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: '#',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
        suppressMenu: true,
        pinned: true,
        cellStyle: style,
      },
      {
        headerName: "Log Id",
        field: "user_action_audit_log_id",
        width: 90,
        pinned: true,
        cellStyle: style,
      },
      {
        headerName: "Superuser Id",
        field: "super_user_id",
        width: 120,
        cellStyle: style,
      },
      {
        headerName: "Superuser Email Id",
        field: "super_user_email_id",
        // pinned: true,
        // sort: "asc",
        // comparator: stringComparator,
        width: 200,
        cellStyle: style,
        cellRenderer: (params) => {
          let url = 'mailto://' + params.value
          let link = document.createElement('a')
          link.href = url
          link.innerText = params.value
          link.addEventListener('click', (e) => {
            e.preventDefault()
            handleClick(params)
          })
          return link
        }
      },
      {
        headerName: "Superuser Name",
        field: "super_user_name",
        width: 150,
        cellStyle: style,
      },
      {
        headerName: "Impersonated User Id",
        field: "impersonated_user_id",
        width: 140,
        cellStyle: style,
      },
      {
        headerName: "Impersonated User Comp.",
        field: "impersonated_user_company",
        width: 190,
        // valueGetter: getFeedbackDate,
        cellStyle: style,
        // filter: 'agDateColumnFilter',
        // filterParams: {
        //   comparator
        // }
      },
      {
        headerName: "Impersonated User Email",
        field: "impersonated_user_email_id",
        width: 180,
        cellStyle: style,
      },
      {
        headerName: "Impersonated User Name",
        field: "impersonated_user_name",
        width: 190,
        // valueGetter: getDate,
        cellStyle: style,
        // filter: 'agDateColumnFilter',
        // filterParams: {
        //   comparator
        // }
      },
      {
        headerName: "Action",
        field: "action",
        width: 100,
        cellStyle: style,
      },
      {
        headerName: "Created Time",
        field: "created_time_stamp",
        valueGetter: getDate,
        filter: 'agDateColumnFilter',
        width: 140,
        cellStyle: style,
      },
      {
        headerName: "Updated Time",
        field: "updated_time_stamp",
        valueGetter: getUpdatedDate,
        filter: 'agDateColumnFilter',
        width: 140,
        cellStyle: style,
      }
    ]
  }
  onBtExport() {
    let checked = this.state && this.state.enableEditCat ? false : true;
    let params = {
      skipHeader: false,
      columnGroups: getBooleanValue("#columnGroups"),
      skipFooters: getBooleanValue("#skipFooters"),
      skipGroups: getBooleanValue("#skipGroups"),
      skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      allColumns: getBooleanValue("#allColumns"),
      onlySelected: checked,
      fileName: 'Pol | Audit Logs',
      sheetName: 'apps',
      exportMode: true,
      columnKeys: ['user_action_audit_log_id','super_user_id','super_user_email_id','super_user_name','impersonated_user_id','impersonated_user_company','impersonated_user_email_id','impersonated_user_name','action','created_time_stamp','updated_time_stamp']
    }

    this.gridApi.exportDataAsCsv(params)
  }
}

function getBooleanValue(cssSelector) {
  return true
}

function stringComparator(valueA, valueB) {
  if ((valueA === null && valueB === null) || (valueA === undefined && valueB === undefined)) {
    return 0;
  }

  if (valueA === null || valueA === undefined) {
    return -1;
  }
  if (valueB === null || valueB === undefined) {
    return 1;
  }

  let upperValueA = valueA.toUpperCase();
  let upperValueB = valueB.toUpperCase();
  return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
}

function getDate(params) {
  if (params.data.created_time_stamp !== undefined) {
    return moment(params.data.created_time_stamp).format('DD-MMM-YYYY HH-mm')
  }
  return params.data.created_time_stamp
}

function getUpdatedDate(params) {
  if (params.data.updated_time_stamp !== undefined) {
    return moment(params.data.updated_time_stamp).format('DD-MMM-YYYY HH-mm')
  }
  return params.data.updated_time_stamp
}

function comparator(filterLocalDateAtMidnight, cellValue) {
  let cellDate = moment(cellValue).format('YYYY-MM-DD')
  let filterLocalDate = moment(filterLocalDateAtMidnight).format('YYYY-MM-DD')
  if (filterLocalDate == cellDate) {
    return 0
  }
  if (cellDate < filterLocalDate) {
    return -1;
  }
  if (cellDate > filterLocalDate) {
    return 1;
  }
}
