import React, { Component } from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import PropTypes from "prop-types"
import { withStyles } from '@material-ui/core/styles'
import {Modal} from 'nicollet-react'
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import {fetchCategories } from '../../store/categories/actionCreator'
import {updateAppCategories } from '../../store/appsAndReport/actionCreator'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop:10,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button : {
    marginLeft: 20,
  },
  search: {
    width:'60%',
    float: 'right',
  },
  cancelButton :{
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#0049db',
    paddingRight: 20,
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  subHead:{
    marginTop: 0,
    color: '#959595',
  },
  content: {
    color :'#888887',
  }
})
class EditCategories extends Component {
  constructor(props) {
    super(props)
    this.state= {
      appLength : 0,
      selectedApps: [],
      selectedOption: [],
      selectedRowIds: [],
    }
  }
  componentDidMount(){
    this.props.fetchCategories()
    let appList = (this.props.data) ? Array.prototype.map.call(this.props.data, s => s.title).toString() : []
    let myData = this.props.data
    let appsLength = (myData) ? myData.length : 0
    this.setState({
      selectedApps:  (appList) ? appList : [],
      appLength: appsLength,
      selectedRowIds: (this.props.data) ? this.props.data : [],
      selectedOption: this.getCategories(),
    })
  }
  onSave = () => {
    this.props.updateAppCategories(this.state)
  }
  getCategories = () => {
    let catList = []
    let myData = this.props.data
    if(myData) {
      myData.map(function (obj) {
        if (obj.category_admin_vo_list.length > 0) {
          obj.category_admin_vo_list.forEach(function (obj) {
            catList.push({ label: obj.app_group_name, value: obj.app_group_id })
          })
        }
      })
    }
    let output = _.uniqBy(catList, 'label')
    return output
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
  }
  render() {
    const {classes, open = false, categories } = this.props
    const {appLength, selectedApps, selectedOption} = this.state
    return (
      <Modal isOpen={open} onClose={this.props.onClose} contentStyles={{maxWidth: '700px' }}>
        <h3 style={{marginBottom:0}}> Edit App Categories </h3>
        {appLength > 0 &&
        <p className={classes.subHead}> {appLength} Apps Selected: {selectedApps} </p>
        }
        <div>
          <Select
            name="categories"
            value={selectedOption}
            isMulti
            onChange={this.handleChange}
            options={categories.categoriesList}
            placeholder='Categories/Uses'
          />
        </div>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <p className={classes.content}>This allows vendors to find app/report more quickly, especially if they are new and don’t know the name of it. Have a suggestion for a new category? Contact the product owner for Partners Online</p>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <div className={classes.buttonContainer}>
              <Button className={classes.cancelButton} onClick={this.props.onClose}>Cancel</Button>
              <Button variant="contained" color="primary" disabled={!selectedOption.length}  onClick={this.onSave}>Save</Button>
            </div>
          </Grid>
        </Grid>
      </Modal>

    )
  }
}
EditCategories.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      selectedRowsId: PropTypes.array,
      selectedRowsCat: PropTypes.array,
    })
  ),
  onClose: PropTypes.func.isRequired,
  updateAppCategories: PropTypes.func,
  fetchCategories: PropTypes.func,
  open: PropTypes.bool,
}
const mapStateToProps = state => {
  const { categories } = state
  return {
    categories: categories,
  }
}
export default connect(mapStateToProps, {fetchCategories,updateAppCategories})(withStyles(styles)(EditCategories))
