import React, { Component } from 'react'
import PropTypes from "prop-types"
import { withStyles } from '@material-ui/core/styles'
import { Modal } from 'nicollet-react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Zoom from '@material-ui/core/Zoom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import WarningIcon from '@material-ui/icons/Warning';
import { connect } from 'react-redux'
// import { showModal } from '../../store/notification/actionCreator'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { postCategoriesError } from '../../store/appCategories/actionCreator'

const styles = theme => ({
  progress: {
    marginLeft: '4%',
    marginTop: '4%',
  },
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: 10,
  },
  button: {
    marginLeft: 20,
  },
  cancelButton: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#0049db',
    paddingRight: 20,
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  content: {
    color: '#888887',
  },
  linkDelete: {
    color: '#bb423a',
    textDecoration: 'none',
    fontSize: 14,
  },
  buttonProgress: {
    color: '#FFF',
    marginRight: 10,
  },
  alertBar: {
    backgroundColor: '#ffa000',
    color: '#FFF',
    padding: '5px 5px 15px 10px',
    borderRadius: 4,
    width: '80%',
  },
  alertIcon: {
    top: 10,
    position: 'relative',
    padding: 5,
  },
  errorIcon: {
    color: 'red',
    margin: 8,
  },
  correctIcon: {
    color: 'green',
    margin: 8,
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
})
class CategoriesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categoriesName: '',
      categoriesOrder: 1,
      categoriesId: '',
      categoriesDesc: '',
      categoriesStatus: 'active',
      isAdd: props.isAdd,
      showConfirmModal: false,
      readOnly: false
    }
  }
  componentDidMount() {
    if (!this.props.isAdd) {
      this.setState({
        categoriesName: this.props.selectedRow.app_group_name,
        categoriesOrder: this.props.selectedRow.sequence,
        categoriesId: this.props.selectedRow.app_group_id,
        categoriesDesc: this.props.selectedRow.app_group_desc,
        categoriesStatus: this.props.selectedRow.status,
      })
    }
    if (this.props.appsAndReports.readOnly !== undefined) {
      this.setState({readOnly: JSON.parse(this.props.appsAndReports.readOnly.read_only)})
      // this.setState({readOnly: true})
    }
  }
  onSave = () => {
    this.props.updateAppCategories(this.state)
  }
  handleChange = name => event => {
    if(name === 'categoriesName'){
      this.props.checkAppCategories(event.target.value)
    }
    this.setState({ [name]: event.target.value });
  }
  handleDeleteModal = () => {
    this.setState(prevState => ({ showConfirmModal: !prevState.showConfirmModal }));
  }
  handleDelete = (id, isMapped) => event => {
    if (this.state.readOnly) {
      return false
    }
    this.props.deleteCategories(id, isMapped)
    this.handleDeleteModal()
    if (isMapped === 1) {
      this.props.onClose()
    }
  }
  focusToNewCat = () => {
    this.props.onClose()
    this.props.postCategoriesError()
  }
  handleBlur = (event) => {
    this.props.checkAppCategories(event.target.value)
  }
  render() {
    const { classes, open = false, data, appCategories } = this.props
    const { readOnly } = this.state
    let catCnt = (data.appCategoriesListCount) ? data.appCategoriesListCount + 3 : 5
    const { categoriesName, categoriesOrder, showConfirmModal, isAdd, categoriesId, categoriesDesc } = this.state
    let type = (categoriesId) ? 'Edit' : 'Add'
    let showDeleteModal = (data.appCategoriesDelete.length > 0 && showConfirmModal)
    return (
      <Modal isOpen={open} onClose={this.focusToNewCat} contentStyles={{ maxWidth: '700px' }}>
        {!showDeleteModal && !appCategories.status &&
          <React.Fragment>
            <h3> {type} an App Category </h3>
            <Divider variant="middle" style={{ margin: '0 0 16px 0' }} />
            <Grid container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <TextField
                  id="category_name"
                  type="text"
                  label="Category Name"
                  helperText="Ex: Item Setup"
                  value={categoriesName || ''}
                  variant="outlined"
                  onChange={this.handleChange('categoriesName')}
                  onBlur={this.handleBlur}
                  required
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                {appCategories.loading && appCategories.loading.appCategoryLoading && <CircularProgress size={25} className={classes.progress} />}
                {appCategories.appCategoryCheck && appCategories.appCategoryCheck.exist && categoriesName && <Typography className={classes.icons}><CancelIcon className={classes.errorIcon} /><span style={{color:'red'}}>Already Exists</span></Typography>}
                {appCategories.appCategoryCheck && !appCategories.appCategoryCheck.exist && categoriesName && <CheckCircleIcon className={classes.correctIcon} />}
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <TextField
                  id="seqOrder"
                  // select
                  type="number"
                  label="Order"
                  value={categoriesOrder}
                  onChange={this.handleChange('categoriesOrder')}
                  style={{ width: 'auto', marginTop: 25 }}
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  helperText="1 displays at the top of the filter list on the Apps & Reports page."
                  margin="normal"
                >
                  {/* {[...Array(catCnt)].map((x, i) => (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))} */}
                </TextField>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <TextField
                  id="description"
                  type="text"
                  label="Description"
                  value={categoriesDesc || ''}
                  variant="outlined"
                  onChange={this.handleChange('categoriesDesc')}
                  multiline={true}
                  inputProps={{
                    maxLength: 255,
                  }}
                  rows="5"
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.content}>
                  Note: this category will only display on the Apps & Reports page if an app and report is associated with
                  it, and the user has access to that app or report.
              </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Grid container>
                  <Grid item xs={6}>
                    {!isAdd && categoriesId &&
                      <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                        <a className={classes.linkDelete} href="#" onClick={this.handleDelete(categoriesId, 0)}>
                          Delete
                        </a>
                      </Typography>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.buttonContainer}>
                      <Button className={classes.cancelButton} onClick={this.props.onClose}>Cancel</Button>
                      <Button variant="contained" color="primary" disabled={!categoriesName || (appCategories.appCategoryCheck && appCategories.appCategoryCheck.exist) || readOnly} onClick={this.onSave}>Save</Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        {showDeleteModal && !appCategories.status &&
          <Zoom in={showDeleteModal} style={{ transitionDelay: showDeleteModal ? '100ms' : '0ms' }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.alertBar}><WarningIcon className={classes.alertIcon} /> {data.appCategoriesDelete && <span><b>{data.appCategoriesDelete}</b> is Associated with "{categoriesName}" , </span>}
                  Do you want to delete  ? </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <div className={classes.buttonContainer}>
                  <Button className={classes.cancelButton} onClick={this.handleDeleteModal}>No</Button>
                  <Button variant="contained" color="primary" onClick={this.handleDelete(categoriesId, 1)}>
                    {data.loading.appCategoriesDeleteLoading &&
                      <CircularProgress size={20} className={classes.buttonProgress} />
                    } Yes
              </Button>
                </div>
              </Grid>
            </Grid>
          </Zoom>
        }
        {appCategories && appCategories.status &&
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <p className={classes.alertBar}><WarningIcon className={classes.alertIcon} />{appCategories.text} </p>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={this.focusToNewCat}>
                  OK
                </Button>
              </div>
            </Grid>
          </Grid>
        }
      </Modal>
    )
  }
}
CategoriesForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  selectedRow: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  deleteCategories: PropTypes.func.isRequired,
  updateAppCategories: PropTypes.func.isRequired,
  open: PropTypes.bool,
  isAdd: PropTypes.bool,
  appCategories: PropTypes.object,
  postCategoriesError: PropTypes.func,
  checkAppCategories: PropTypes.func.isRequired,
  appsAndReports: PropTypes.object,
}

const mapStateToProps = state => {
  const { appCategories, appsAndReports } = state
  return {
    appCategories,
    appsAndReports,
  }
}

const mapDispatchToProps = dispatch => ({
  postCategoriesError: data => dispatch(postCategoriesError('', ''))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CategoriesForm))
