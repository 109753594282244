import Cookies from '../Utils/Cookies'
import Storage from '../Utils/Storage'
import { getPayload } from '../Utils/Utilities'

let LoginCtrl = {
  logout: () => {
    let storage = new Storage()
    let cookies = new Cookies()
    // let logOutUrl = apiConfig.auth.logout + '?TARGET=' + window.location.href
    storage.remove('id_token')
    storage.remove('access_token')
    storage.remove('targetRedirectUrl')
    storage.remove('polnotification')
    storage.remove('logId')
    cookies.remove('SSOSESSIONINFO')
    window.location.reload()
    // window.location.href = logOutUrl
  },
  getPayLoad: () => {
    const idPayload = getPayload('id_token')
    return idPayload
  },
}

export default LoginCtrl