import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Grid, Button, Paper, InputBase, CircularProgress } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { AgGridReact } from 'ag-grid-react'
import { Helmet } from 'react-helmet'
import GridConfig from './GridConfig'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { fetchSupport, openModal, closeModal, fetchSupportOptions, editSupport, addSupport, deleteSupport, getHelpCategories } from '../../store/manageSupport/actionCreator'
import ManageSupportForm from './ManageSupportForm'

const manageSupportStyle = theme => ({
  search: {
    width: '60%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    height: 35,
    marginBottom: 10,
    border: '1px solid #c0c0c0',
  },
  input: {
    width: 'calc(100% - 70px)',
    border: 0,
  },
  button: {
    textTransform: 'none',
    color: '#c0c0c0',
    backgroundColor: '#ffffff',
    border: '1px solid #c0c0c0',
    height: 35,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#0950dc',
    },
    '&:focus': {
      color: '#ffffff',
      backgroundColor: '#0950dc',
    },
  },
  table: {
    height: "calc(100vh - 127px)",
    width: '100%',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
})

class ManageSupport extends Component {
  constructor(props) {
    super(props)
    this.gConfig = new GridConfig()
    this.state = {
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        filter: true,
        autoHeight: true,
      },
      rowData: [],
      enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      rowSelection: "single",
      suppressRowClickSelection: false,
      suppressCellSelection: true,
      enableSorting: true,
      enableEditCat: true,
      rowSelected: null,
      forcedAction: false,
    }
    this.gridOptions = {
      columnDefs: this.state.columnDefs,
      getNodeChildDetails: function(parent) {
        if(parent.child_metric && parent.child_metric.length > 0) {
          return {
            group: true,
            children: parent.child_metric,
            expanded: false,
          };
        }
        else {
          return {
            group: false,
            expanded: false,
          };
        }
      },
      icons: {
        groupExpanded: '<i class="fas fa-angle-up" />',
        groupContracted: '<i class="fas fa-angle-down" />'
      },
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady: this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      onRowSelected: this.rowSelected.bind(this),
      onSelectionChanged: this.onSelectionChanged.bind(this),
      rowDragManaged: true,
      animateRows: true,
      sideBar: true,
      onColumnResized: this.onColumnResized,
      onRowDragEnd: this.onRowDragEnd,
    }
  }
  
  componentDidMount() {
    this.props.fetchSupport()
    this.props.fetchSupportOptions()
    this.props.getHelpCategories()
  }
  componentWillReceiveProps (nextProps) {
    if (!nextProps.updateSupport.postLoading && nextProps.updateSupport.postSuccess && this.props.updateSupport.postSuccess !== nextProps.updateSupport.postSuccess) {
      this.props.closeModal('supportForm')
      nextProps.fetchSupport()
    }
    if (!nextProps.addSupportData.addLoading && nextProps.addSupportData.addSuccess && this.props.addSupportData.addSuccess !== nextProps.addSupportData.addSuccess) {
      this.props.closeModal('supportForm')
      nextProps.fetchSupport()
    }
    if (nextProps.updateSupport.postSuccess && this.props.updateSupport.postSuccess !== nextProps.updateSupport.postSuccess && nextProps.updateSupport.result.warning !== ('' || null)) {
      window.confirm(nextProps.updateSupport.result.warning) ? this.editForcedAction() : null
    }
    if (!nextProps.delSupport.delLoading && nextProps.delSupport.delSuccess && this.props.delSupport.delSuccess !== nextProps.delSupport.delSuccess) {
      nextProps.fetchSupport()
    }
  }
  editForcedAction = () => {
    let obj = {...this.state.dragEventData, forced_action: true}
    this.props.editSupport(obj)
  }
  onRowDragEnd = event => {
    let nodeName = event.node.data.metric_name
    let nodeId = event.node.data.metric_id
    let currentParent = event.node.data.current_parent_id
    let newParent = event.overNode.data.metric_id
    let supportType = event.node.data.support_type
    let requestSupportId = event.node.data.request_support && event.node.data.request_support.request_support_id
    let rootType = event.node.data.root_type
    let published = event.node.data.published
    let queueName = event.node.data.queue_name
    let recordType = event.node.data.record_type
    let accountId = event.node.data.account_id

    let obj = {
      "metric_id": nodeId,
      // "metric_name": nodeName,
      // "current_parent_id": currentParent,
      "new_parent_id": newParent,
      // "support_type": supportType,
      // "support_option_id": requestSupportId,
      // "root_type": rootType,
      // "published": published,
      "forced_action": this.state.forcedAction,
      "queue_name": queueName,
      "record_type": recordType,
      "account_id": accountId
    }
    this.props.editSupport(obj)
    this.setState({ dragEventData: obj })
  }
  onColumnResized = (params) => {
    params.api.resetRowHeights();
  }
  onGridReady = params => {
    this.gridApi = params.api
  }
  handleClick = params => {
    // this.setState({selectedRow :params.data, isAdd: false },()=>{
    //   this.props.openModal('editForm')
    // })
  }
  rowSelected(event) {
    this.setState({ rowSelected: event.data })
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("quickFilter").value)
  }
  onSelectionChanged(event) {
    let selectedRows = this.gridApi.getSelectedRows()
    this.setState({ rowSelected: selectedRows[0]})
  }
  externalFilterChanged() {
    this.gridOptions.api.onFilterChanged()
  }
  doesExternalFilterPass = node => {
    let status = node.data.archive === this.state.showArchive
    return status
  }
  isExternalFilterPresent() {
    return this.state.showArchive != 'false'
  }
  buttonClick = name => event => {
    let x = this
    if ((name === 'Edit') && x.state.rowSelected !== null) {
      this.props.openModal('supportForm', name)
    }
    if (name === 'Add') {
      this.props.openModal('supportForm', name)
    }
    if (name === 'Publish' || name === 'Unpublish') {
      this.publishNode(name)
    }
    if ((name === 'Delete') && x.state.rowSelected !== null) {
      this.confirmDelete()
    }
    if ((name === 'Categories')) {
      this.props.history.push('/manageHelpCategories')
    }
  }
  confirmDelete = () => {
    window.confirm('Are you sure you want to delete this node ? This action will permanently delete the node.') ? this.deleteNode() : null
  }
  deleteNode = () => {
    this.props.deleteNode({
      metric_id: this.state.rowSelected.metric_id,
    })
  }
  publishNode = name => {
    let published = ''
    if (name === 'Publish') {
      published = true
    }
    if (name === 'Unpublish') {
      published = false
    }
    this.setState(prevState => ({rowSelected: {...prevState.rowSelected, published: published}}), () => {
      this.props.editSupport({
        published: this.state.rowSelected.published,
        metric_id: this.state.rowSelected.metric_id,
      })
    })
  }
  modalClose = formName => {
    this.props.closeModal(formName)
  }

  render() {
    const {supportData, classes, supportOptions, updateSupport, helpCategories} = this.props
    return (
      <div className={classes.root}>
        <HeaderTitle title="Manage Support Paths" />
        <Helmet>
          <title>Manage Support Paths</title>
        </Helmet>
        <Grid container>
          <Grid item md={7} lg={6} xl={5} className={classes.item}>
            <Button className={classes.button} onClick={this.buttonClick('Add')}>+Add</Button>
            <Button className={classes.button} onClick={this.buttonClick('Edit')}>Edit</Button>
            <Button className={classes.button} onClick={this.buttonClick('Delete')}>Delete</Button>
            <Button className={classes.button} onClick={this.buttonClick('Publish')}>Publish</Button>
            <Button className={classes.button} onClick={this.buttonClick('Unpublish')}>Un-publish</Button>
            <Button className={classes.button} onClick={this.gConfig.onBtExport.bind(this)}>Export</Button>
            <Button className={classes.button} onClick={this.buttonClick('Categories')}>Manage help categories</Button>
          </Grid>
          {/* <Grid item md={2} lg={2} /> */}
          <Grid item md={5} lg={6} xl={7}>
            <Paper className={classes.search} elevation={0}>
              <IconButton className={classes.iconButton} aria-label="Search"><SearchIcon /></IconButton>
              <InputBase className={classes.input} placeholder="Search List" onInput={this.onQuickFilterChanged.bind(this)} id="quickFilter" />
            </Paper>
          </Grid>
        </Grid>
        {supportData.getLoading ? <CircularProgress size={50} className={classes.buttonProgress} /> : <Grid container>
          <div className={[classes.table, 'ag-theme-material'].join(' ')}>
            {supportData.getSuccess && <AgGridReact rowData={supportData.data} gridOptions={this.gridOptions} />}
          </div>
        </Grid>}
        {supportData.supportForm && <ManageSupportForm
          formName={supportData.name}
          closeModal={this.modalClose}
          data={this.state.rowSelected}
          supportOptions={supportOptions}
          editSupport={this.props.editSupport}
          addSupport={this.props.addSupport}
          // checkForRedundancy={this.props.checkForRedundancy}
          checkNode={supportData.checkNode}
          helpCategories={helpCategories}
        />}
      </div>
    )
  }
}

ManageSupport.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchSupport: PropTypes.func,
  supportData: PropTypes.array.isRequired,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  fetchSupportOptions: PropTypes.func,
  supportOptions: PropTypes.array,
  editSupport: PropTypes.func,
  addSupport: PropTypes.func,
  // checkForRedundancy: PropTypes.func,
  addSupportData: PropTypes.object,
  updateSupport: PropTypes.object,
  deleteNode: PropTypes.func,
  delSupport: PropTypes.object,
  history: PropTypes.object,
  getHelpCategories: PropTypes.func,
  helpCategories: PropTypes.object,
}

const mapStateToProps = state => ({
    supportData: state.manageSupport,
    supportOptions: state.supportOptions.data,
    updateSupport: state.updateSupport,
    addSupportData: state.addSupport,
    delSupport: state.delSupport,
    helpCategories: state.getHelpCategories,
  })

const mapDispatchToProps = dispatch => ({
    fetchSupport: () => dispatch(fetchSupport()),
    openModal: (supportForm, name) => dispatch(openModal(supportForm, name)),
    closeModal: (formName) => dispatch(closeModal(formName)),
    fetchSupportOptions: () => dispatch(fetchSupportOptions()),
    editSupport: (data) => dispatch(editSupport(data)),
    addSupport: (data) => dispatch(addSupport(data)),
    deleteNode: (data) => dispatch(deleteSupport(data)),
    getHelpCategories: () => dispatch(getHelpCategories())
    // checkForRedundancy: (metricName, rootType) => dispatch(checkForRedundancy(metricName, rootType))
  })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(manageSupportStyle)(ManageSupport))
