import { reactCellRendererFactory } from 'ag-grid-react'

export default class ColumnDefinitionFactory {
  createColDefs(readOnly) {
    return [
      {
        headerName: "POL Site Name",
        field: "POLSiteName",
        sort: "asc",
      },
      {
        headerName: "POL Site URL",
        field: "POLSiteUrl",
        width: "450",
        cellRenderer: (params) => {
          if (params.data.readOnly) {
            let link = document.createElement('span')
            link.innerText = params.value
            return link
          }
          let url = params.data.POLSiteUrl
          let link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          link.innerText = params.value
          return link
        }
      },
    ]
  }
}
