import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { Icon, Text } from 'react-praxis-components/SideNav/index'
import AppsIcon from '@material-ui/icons/Apps'
import ProjectIcon from '@material-ui/icons/DeviceHub'
import GroupIcon from '@material-ui/icons/Group'
import ArchiveIcon from '@material-ui/icons/Archive'
import CategoryIcon from '@material-ui/icons/Category'
import { Helmet } from 'react-helmet'
import HeaderTitle from '../../components/Header/HeaderTitle'
import {praxis} from "../../config/themeConfig";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
  paper:{
    minHeight: 180,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    height: '35px',
    width: '35px',
    position: 'relative',
    top: 10,
    // color: praxis.lightBlue,
  },
  link: {
    fontSize: 18,
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  grids: {
    padding: 10
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

class HomePage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      config: []
    }
  }
  componentDidMount(){
    if (this.props.config.success) {
      this.setState({config: this.props.config.data})
    }
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.config.success && (nextProps.config.data !== this.props.config.data)) {
      this.setState({config: nextProps.config.data})
    }
  }
  
  render() {
    const { classes, headerTitle } = this.props
    const { config } = this.state
    return (
      <React.Fragment>
        <HeaderTitle title="Home" />
          <Helmet><title>{headerTitle}</title></Helmet>
          {this.props.config.success ? <Grid container direction="row" justify="center" alignItems="center">
          {(config.admin || config.support) && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper}>
              <Link component={RouterLink} to="/apps" className={classes.link}>
                <Icon>
                  <AppsIcon className={classes.icon}/>
                </Icon>
                Apps & Reports
              </Link>
            </Paper>
          </Grid>}
          {config.admin && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper}>
              <Link component={RouterLink} to="/allContent" className={classes.link}>
                <Icon>
                  <ArchiveIcon className={classes.icon} />
                </Icon>
                All Content (SharePoint)
              </Link>
            </Paper>
          </Grid>}
          {config.admin && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/reason" className={classes.link}>
                <Icon>
                  <ProjectIcon className={classes.icon}/>
                </Icon>
                Reasons for Support
              </Link>
            </Paper>
          </Grid>}
          {config.admin && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/appCategories" className={classes.link}>
                <Icon>
                  <CategoryIcon className={classes.icon}/>
                </Icon>
                App Categories
              </Link>
            </Paper>
          </Grid>}
          {config.admin && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/appGroups" className={classes.link}>
                <Icon>
                  <GroupIcon className={classes.icon}/>
                </Icon>
                App User Groups
              </Link>
            </Paper>
          </Grid>}
          {(config.admin || config.content) && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/contentFeedback" className={classes.link}>
                <Icon>
                  <GroupIcon className={classes.icon}/>
                </Icon>
                Content Feedback
              </Link>
            </Paper>
          </Grid>}
          {config.admin && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/audit" className={classes.link}>
                <Icon>
                  <GroupIcon className={classes.icon}/>
                </Icon>
                Audit Logs
              </Link>
            </Paper>
          </Grid>}
          {(config.admin || config.support) && <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/manage-support" className={classes.link}>
                <Icon>
                  <GroupIcon className={classes.icon}/>
                </Icon>
                Manage Support
              </Link>
            </Paper>
          </Grid>}
          {(config.admin || config.content) &&  <Grid item xs={12} lg={2} className={classes.grids}>
            <Paper className={classes.paper} >
              <Link component={RouterLink} to="/search-fields" className={classes.link} style={{ textAlign: 'center' }}>
                <Icon>
                  <GroupIcon className={classes.icon}/>
                </Icon>
                Manage Search Attributes & Weights
              </Link>
            </Paper>
          </Grid>}
        </Grid>
        : <Grid container spacing={24} >
        <Grid item xs={12} className={classes.boxDiv}>
          <p>An error occurred while retrieving Configuration data for the user. Please contact support if this issue persists.</p>
        </Grid>
      </Grid>}
      </React.Fragment>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  headerTitle: PropTypes.string,
  appsAndReports: PropTypes.object,
  config: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { appsAndReports, config } = state
  return {
    appsAndReports,
    config,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(HomePage));
