import { reactCellRendererFactory } from 'ag-grid-react'
import moment from 'moment-timezone'
import axios from 'axios'
import { displayTzFormat } from '../../Utills/dateHelper'
import apiConfig from '../../config/apiConfig'

const style = {
  'white-space': 'normal',
  'line-height': '18px',
  'display': 'flex',
  'flex-direction': 'column',
  'justify-content': 'center'
}

const style1 = {
  'white-space': 'normal',
  'line-height': '18px',
  'display': 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'background-color': '#f9ebd7'
}

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: '#',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
        suppressMenu: true,
        pinned: true,
        cellStyle: style,
      },
      {
        headerName: "Owner",
        field: "contact",
        width: 200,
        pinned: true,
        cellStyle: style,
        // cellRenderer: (params) => {
        //   let link  = document.createElement('a')
        //   link.href = `/contentFeedback/edit/${params.data.id}`
        //   link.innerText = params.data.contact
        //   return link
        // }
      },
      {
        headerName: "ID",
        field: "item_id",
        width: 100,
        cellStyle: style,
      },
      {
        headerName: "Title",
        field: "content_title",
        // pinned: true,
        // sort: "asc",
        comparator: stringComparator,
        width: 250,
        cellStyle: style,
        cellRenderer: (params) => {
          if (params.data.file_relative_url === undefined) {
            if (params.data.browser_url) {
              let url = params.data.browser_url
              let link = document.createElement('a')
              link.href = `${apiConfig.hostUrl}`+url
              link.innerText = params.data.content_title
              link.addEventListener('click', (e) => {
                e.preventDefault()
                handleClick(params)
              })
              return link
            }
            let link = params.data.content_title
            return link
          }
          let url = 'https://targetonline.sharepoint.com' + params.data.file_relative_url
          let link = document.createElement('a')
          link.href = url
          link.innerText = params.data.content_title
          link.addEventListener('click', (e) => {
            e.preventDefault()
            handleClick(params)
          })
          return link
        }
      },
      {
        headerName: "Page Helpful?",
        field: "content_is_helpful",
        width: 100,
        cellStyle: style,
      },
      {
        headerName: "FB Date",
        field: "feedback_date",
        width: 150,
        comparator: comparator,
        valueGetter: getFeedbackDate,
        cellStyle: style,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator
        }
      },
      {
        headerName: "Feedback Comments",
        field: "feedback_comments",
        width: 320,
        cellStyle: style,
      },
      {
        headerName: "Content Owner Action",
        field: "author_comment",
        width: 200,
        cellStyle: style1,
        editable: true,
        onCellValueChanged: params => {
          axios.post(`${apiConfig.adminApi.cms}/content/edit_feedback_report`,params.data)
          // axios.post(`http://localhost:8080/cms-service/api/v1/content/edit_feedback_report`,params.data,{headers:{"user-i":"z004zd3"}})
          .then()
          .catch(err => {throw err})
        }
      },
      {
        headerName: "Resolved",
        field: "resolved",
        width: 120,
        cellStyle: style1,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: resolvedValues,
        },
        valueGetter: params => {
          if (params.data.resolved === undefined) {
            params.data.resolved = 'No'
            return 'No'
          }
          return params.data.resolved
        },
        onCellValueChanged: params => {
          axios.post(`${apiConfig.adminApi.cms}/content/edit_feedback_report`,params.data)
          // axios.post(`http://localhost:8080/cms-service/api/v1/content/edit_feedback_report`,params.data,{headers:{"user-i":"z004zd3"}})
          .then()
          .catch(err => {throw err})
        }
      },
      {
        headerName: "Last Edited",
        field: "last_edited",
        width: 120,
      },
      {
        headerName: "Owner Action Date",
        field: "author_comment_date",
        width: 180,
      },
      {
        headerName: "Site Type",
        field: "site_type",
        width: 120,
        cellStyle: style,
      },
      {
        headerName: "Topic/ App/Rpt",
        field: "topic",
        width: 200,
        cellStyle: style,
      },
      {
        headerName: "Content Type",
        field: "content_type",
        width: 120,
        cellStyle: style,
      },
      {
        headerName: "Abstract",
        field: "content_abstract",
        width: 300,
        cellStyle: style,
      },
      {
        headerName: "Last Modified Date",
        field: "last_modified_date",
        width: 170,
        comparator: comparator,
        valueGetter: getDate,
        cellStyle: style,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator
        }
      },
      {
        headerName: "External/Internal User",
        field: "is_internal",
        width: 180,
        cellStyle: style,
      },
      {
        headerName: "Users BAID",
        field: "baa_id",
        hide: true,
        width: 120,
        cellStyle: style,
      },
      /*{
        headerName: "Published",
        field: "published",
        width: 120,
        cellStyle: style,
      },*/
    ]
  }
  onBtExport() {
    let checked = this.state && this.state.enableEditCat ? false : true;
    let params = {
      skipHeader: false,
      columnGroups: getBooleanValue("#columnGroups"),
      skipFooters: getBooleanValue("#skipFooters"),
      skipGroups: getBooleanValue("#skipGroups"),
      skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      allColumns: getBooleanValue("#allColumns"),
      onlySelected: checked,
      fileName: 'Pol | ContentFeedback',
      sheetName: 'apps',
      exportMode: true,
      columnKeys: ['contact','item_id','content_title','content_is_helpful','feedback_date','feedback_comments','author_comment','resolved','last_edited','author_comment_date','site_type','topic','content_type','content_abstract','last_modified_date','is_internal','baa_id','published']
    }

    this.gridApi.exportDataAsCsv(params)
  }
}

function getBooleanValue(cssSelector) {
  return true
}

function stringComparator(valueA, valueB) {
  if ((valueA === null && valueB === null) || (valueA === undefined && valueB === undefined)) {
    return 0;
  }

  if (valueA === null || valueA === undefined) {
    return -1;
  }
  if (valueB === null || valueB === undefined) {
    return 1;
  }

  let upperValueA = valueA.toUpperCase();
  let upperValueB = valueB.toUpperCase();
  return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
}

function getDate(params) {
  if (params.data.last_modified_date !== undefined) {
    return moment(params.data.last_modified_date).format('DD-MMM-YYYY')
  }
  return params.data.last_modified_date
}

function getFeedbackDate(params) {
  if (params.data.feedback_date !== undefined) {
    return moment(params.data.feedback_date).format('DD-MMM-YYYY')
  }
  return params.data.feedback_date
}

function comparator(filterLocalDateAtMidnight, cellValue) {
  let cellDate = moment(cellValue).format('YYYY-MM-DD')
  let filterLocalDate = moment(filterLocalDateAtMidnight).format('YYYY-MM-DD')
  if (filterLocalDate == cellDate) {
    return 0
  }
  if (cellDate < filterLocalDate) {
    return 1;
  }
  if (cellDate > filterLocalDate) {
    return -1;
  }
}

const resolvedValues = ['Yes', 'No']
