import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'nicollet-react'
import PropTypes from 'prop-types'
import { withStyles, Grid, TextField, Typography, Button } from '@material-ui/core'

const styles = theme => ({
  container: {
    marginTop: 10,
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  cancelButton: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#0049db',
    paddingRight: 20,
  },
  linkDelete: {
    color: '#bb423a',
    textDecoration: 'none',
    textTransform: 'inherit',
  },
})

class ManageHelpCategoriesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupName: '',
      description: '',
      id: null,
    }
  }
  componentDidMount () {
    let { data } = this.props
    this.setState(prevState => ({
      groupName: data ? data.help_metric_group_name : prevState.groupName,
      description: data ? data.help_metric_group_description : prevState.description,
      id: data ? data.help_metric_group_id : prevState.help_metric_group_id,
    }))
  }
  componentWillReceiveProps (nextProps) {
    if (!nextProps.editHelpCat.editCatLoading && nextProps.editHelpCat.editCatSuccess && nextProps.editHelpCat.editCatSuccess !== this.props.editHelpCat.editCatSuccess) {
      this.props.getHelpCategories()
      this.closeModal()
    }
  }
  closeModal = () => {
    this.props.closeModal('helpCatForm')
  }
  handleChange = name => event => {
    this.setState({
      [name] : event.target.value,
    })
  }
  handleDelete = (id, name) => {
    window.confirm('This Action may result in deleting any association you will have in the Help Metric. Are you sure you want to continue?') ? this.deleteCategory(id, name) : null
  }
  deleteCategory = (id, name) => {
    this.props.deleteHelpCategories(id, name)
    this.closeModal()
  }
  onSave = () => {
    let obj = {
      "help_metric_group_id": this.state.id,
      "help_metric_group_name": this.state.groupName,
      "help_metric_group_description": this.state.description,
    }
    this.props.editHelpCategories(obj)
  }
  render() {
    const { formName, classes, isAdd, data } = this.props
    let categoriesId = data && data.help_metric_group_id
    const { groupName, description } = this.state
    return (
      <Modal isOpen={true} onClose={this.closeModal} contentStyles={{ maxWidth: '700px' }}>
        <Grid container className={classes.container}>
          <h2>{formName}</h2>
        </Grid>
        <Grid container className={classes.container}>
          <TextField inputProps={{ maxLength: 255 }} color="default" id="group-name" variant="outlined" label="Group Name" type="text" value={groupName} onChange={this.handleChange('groupName')} />
        </Grid>
        <Grid container className={classes.container}>
          <TextField
            inputProps={{ maxLength: 255 }}
            id="description"
            type="text"
            label="Description"
            value={description}
            variant="outlined"
            onChange={this.handleChange('description')}
            multiline={true}
            rows="5"
            margin="normal"
          />
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            {!isAdd && categoriesId &&
              <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                <Button className={classes.linkDelete} onClick={e => this.handleDelete(categoriesId, groupName)}>
                  Delete
                </Button>
              </Typography>
            }
          </Grid>
          <Grid item xs={6}>
            <div className={classes.buttonContainer}>
              <Button className={classes.cancelButton} onClick={this.closeModal}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={this.onSave}>Save</Button>
            </div>
          </Grid>
        </Grid>
      </Modal>
    )
  }
}

ManageHelpCategoriesForm.propTypes = {
  data: PropTypes.object,
  editHelpCat: PropTypes.object,
  getHelpCategories: PropTypes.func,
  closeModal: PropTypes.func,
  editHelpCategories: PropTypes.func,
  formName: PropTypes.string,
  classes: PropTypes.object,
  isAdd: PropTypes.bool,
  deleteHelpCategories: PropTypes.func,
}

const mapStateToProps = state => ({
  editHelpCat: state.editHelpCategories,
})

export default connect(mapStateToProps)(withStyles(styles)(ManageHelpCategoriesForm))
