import axios from 'axios'
import {
  APP_GROUPS_LIST_API_CALL_INITIATED,
  APP_GROUPS_LIST_API_CALL_SUCCESS,
  APP_GROUPS_LIST_API_CALL_FAIL,
  OPEN_MODAL,
  CLOSE_MODAL,
  POST_APPS_GROUPS_API_CALL_FAIL,
  POST_APPS_GROUPS_API_CALL_INITIATED,
  POST_APPS_GROUPS_API_CALL_SUCCESS,
  DELETE_APPS_GROUPS_API_CALL_FAIL,
  DELETE_APPS_GROUPS_API_CALL_INITIATED,
  DELETE_APPS_GROUPS_API_CALL_SUCCESS,
  POST_APPS_GROUPS_API_CALL_ERROR,
  CHECK_GROUPS_INITIATED,
  CHECK_GROUPS_SUCCESS,
  CHECK_GROUPS_FAIL,
} from './actionType'

import apiConfig from '../../config/apiConfig'
import { TEXT_ERROR_DEFAULT, TEXT_ERROR_DUPLICATE, TEXT_SUCCESS_APP_GROUP_UPDATED, TEXT_SUCCESS_APP_GROUP_ADDED } from '../../constants/notifications'
import { showNotification } from '../notification/actionCreator'
import { POST_APPS_CATEGORIES_API_CALL_ERROR } from '../appCategories/actionType'

export function fetchGroups () {
  return function (dispatch) {
    dispatch(appGroupsApiCallInitiated({
      type: APP_GROUPS_LIST_API_CALL_INITIATED,
      data: [],
    }))
    return axios.get(`${apiConfig.adminApi.host}adGroups`, {
      data: {},
    })
      .then(response => {
        dispatch(appGroupsApiCallSuccess(response.data.result))
      })
      .catch((error) => {
        dispatch(appGroupsApiCallFail(error))
      })
  }
}
export function appGroupsApiCallInitiated (data) {
  return {
    type: APP_GROUPS_LIST_API_CALL_INITIATED,
    data,
  }
}
export function appGroupsApiCallSuccess (data) {
  return {
    type: APP_GROUPS_LIST_API_CALL_SUCCESS,
    data,
  }
}
export function appGroupsApiCallFail (error) {
  return {
    type:APP_GROUPS_LIST_API_CALL_FAIL,
    error,
  }
}

export function openModal(modalName) {
  return {
    type: OPEN_MODAL,
    modalName,
  }
}

export function closeModal(modalName) {
  return {
    type: CLOSE_MODAL,
    modalName,
  }
}
/***************************/
export function checkGroups (data) {
  return function(dispatch) {
    dispatch(checkGroupsInitiated())
    return axios.get(`${apiConfig.adminApi.host}adGroups/validate?title=${data}`)
      .then(response => {
        dispatch(checkGroupsSuccess(response.data.result))
      })
      .catch(error => {
        dispatch(checkGroupsFail(error))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
      })
  }
}
export function checkGroupsInitiated(){
  return {
    type: CHECK_GROUPS_INITIATED
  }
}
export function checkGroupsSuccess(data){
  return {
    type: CHECK_GROUPS_SUCCESS,
    data,
  }
}
export function checkGroupsFail(error){
  return {
    type: CHECK_GROUPS_FAIL,
    error,
  }
}

/***************************/
export function updateAppGroups(postData) {
  let txt=''
  if(postData.isAdd) {
    txt = TEXT_SUCCESS_APP_GROUP_UPDATED
  }
  else{
    txt = TEXT_SUCCESS_APP_GROUP_ADDED
  }
  let data = {
    ad_group_id: postData.adGroupId,
    ad_group_name: postData.adGroupName,
    ad_group_desc: postData.adGroupDesc,
  }
  // if(postData.isAdd){
  //   // data = _.omit(data, ['ad_group_id'])
  // }
  return function(dispatch) {
    dispatch(postAppGroupsListInitiated())
    return axios.post(`${apiConfig.adminApi.host}adGroups`, data)
      .then(response => {
        dispatch(showNotification(true, txt, 'success'))
        dispatch(postAppGroupsListCallSuccess())
        dispatch(fetchGroups())
        dispatch(closeModal('editAppGroup'))
      })
      .catch((e) => {
        dispatch(postAppGroupsListCallFail())
        dispatch(postAppGroupsListCallError(e.response.status, e?.response?.data?.meta_info?.error?.message))
        // if(e.response && e.response.status && e.response.status === 400 || 500){
        //   dispatch(postAppGroupsListCallError(e.response.status, postData.adGroupName+TEXT_ERROR_DUPLICATE+"App Group."))
        // }else{
        //   dispatch(postAppGroupsListCallError(e.response.status, TEXT_ERROR_DEFAULT))
        // }
        // if(e.response.status === 400){
        //   dispatch(showNotification(true, e.response.data.meta_info.error.message, 'error'))
        // }else if(e.response.status === 500){
        //   dispatch(showNotification(true, postData.adGroupName+TEXT_ERROR_DUPLICATE, 'error'))
        // }else{
        //   dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
        // }
      })
  }
}
export function postAppGroupsListInitiated() {
  return {
    type: POST_APPS_GROUPS_API_CALL_INITIATED,
  }
}

export function postAppGroupsListCallSuccess(data) {
  return {
    type: POST_APPS_GROUPS_API_CALL_SUCCESS,
    data,
  }
}

export function postAppGroupsListCallFail(error) {
  return {
    type: POST_APPS_GROUPS_API_CALL_FAIL,
    error,
  }
}

export function postAppGroupsListCallError(status, text) {
  return {
    type: POST_APPS_GROUPS_API_CALL_ERROR,
    status,
    text,
  }
}
/***************************/

export function deleteAdGroups (id, isMapped) {
  let data= {id : id}
  if(isMapped === 1){
    data.confirm_delete = true
  }
  return function(dispatch) {
    (isMapped === 1) ? dispatch(deleteAppGroupInitiated()) : ''
    return axios.delete(`${apiConfig.adminApi.host}deleteAdGroups`, { data: data })
      .then(response => {
        if(response.data.result !== 'SUCCESS') {
          dispatch(deleteAppGroupSuccess(response.data.result))
        } else {
          dispatch(closeModal('editAppGroup'))
          dispatch(fetchGroups())
          dispatch(showNotification(true, 'App Group deleted successfully', 'success'))
        }
      })
      .catch((e) => {
        dispatch(deleteAppGroupFail(e))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
      })
  }
}
export function deleteAppGroupFail (data) {
  return {
    type: DELETE_APPS_GROUPS_API_CALL_FAIL,
    data,
  }
}
export function deleteAppGroupSuccess (data) {
  return {
    type: DELETE_APPS_GROUPS_API_CALL_SUCCESS,
    data,
  }
}
export function deleteAppGroupInitiated (data) {
  return {
    type: DELETE_APPS_GROUPS_API_CALL_INITIATED,
    data,
  }
}
