import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import GroupWork from '@material-ui/icons/GroupWork'
import HeaderTitle from "../../components/Header/HeaderTitle"
import EditForm from './EditForm'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop:10,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
    width: '68%'
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    maxWidth: '68%',
  },
  icon:{
    top: 18,
    position: 'relative',
    width: 50,
    height: 50,
  }
})
class EditAppsAndReport extends Component {
  constructor(props) {
    super(props)
    this.state= {
      value: 0,
      type: (this.props.match.params.type === 'edit'),
    }
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }
  render() {
    const {classes} = this.props
    const {value, type} = this.state

    return (
      <div className={classes.root}>
        <HeaderTitle title="Manage App/Report" />
        <Helmet>
          <title>Manage App/Report</title>
        </Helmet>
        <div className={classes.root}>
          <EditForm fetchProperties = {this.props} isEdit={type} />
        </div>
      </div>

    )
  }
}
EditAppsAndReport.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
}
export default withStyles(styles)(EditAppsAndReport)

