class Storage {
  set (key, value) {
    return localStorage.setItem(key, value)
  }

  get (key) {
    return localStorage.getItem(key)
  }

  remove (key) {
    return localStorage.removeItem(key)
  }

  clearstorage () {
    return localStorage.clear()
  }
}

export default Storage