import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  align: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
})

function Unauthorized(props) {
  const { classes } = props
  return (
    <div className={classes.align}>
      <h1>You are unauthorized</h1>
      <h2>Kindly contact support if the issue persists</h2>
    </div>
  )
}

Unauthorized.propTypes = {
  classes: {},
}

export default (withStyles(styles)(Unauthorized))
