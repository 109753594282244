import { reactCellRendererFactory } from 'ag-grid-react'
import NumericEditor from './NumericEditor';

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: "Categories Name",
        field: "app_group_name",
        pinned: true,
        sort: "asc",
        width: 300,
        cellRenderer: (params) => {
          let url = '/appCategories/edit/'+ params.data.app_group_id
          let link = document.createElement('a')
          link.href = url
          link.innerText = params.value
          link.addEventListener('click', (e) => {
            e.preventDefault()
            handleClick(params)
          })
          return link
        }
      },
      {
        headerName: "Order",
        field: "sequence",
        // editable: true,
        cellEditorFramework: NumericEditor,
        getQuickFilterText: () => '',
      },
      {
        headerName: "Status",
        field: "status",
        getQuickFilterText: () => '',
      },
      {
        headerName: "Description",
        field: "app_group_desc",
      },
    ]
  }
}
