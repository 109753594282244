import React from "react";
import { shape, string, func, bool, object, PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import ArchiveIcon from '@material-ui/icons/Archive';
import AppsIcon from "@material-ui/icons/Apps";
import ProjectIcon from "@material-ui/icons/DeviceHub";
import GroupIcon from "@material-ui/icons/Group";
import CategoryIcon from "@material-ui/icons/Category";

import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import SideNav, {
  Footer,
  Header,
  Icon,
  Link,
  Text
} from "react-praxis-components/SideNav";
import UserBar from "react-praxis-components/UserBar";
import { withAuth } from '@praxis/component-auth'
import LoginCtrl from '../Auth/LoginCtrl'
import { fetchConfig } from './../../store/config/actionCreator'
import Notifications from "../Notifications/Notifications";
import { closeSideNav, openSideNav } from "../../store/layout/actionCreator";
import AppHeader from "../Header/Header";
import HomePage from "../../Page/HomePage/HomePage";
import AppsAndReport from "../../Page/AppsAndReport/AppsAndReport";
import EditAppsAndReport from "../../Page/AppsAndReport/EditAppsAndReport";
import ReasonSupport from "../../Page/ReasonSupport/ReasonSupport";
import EditReasonForSupport from "../../Page/ReasonSupport/EditReasonForSupport";
import AppCategories from "../../Page/AppCategories/AppCategories";
import AppGroups from "../../Page/AppGroups/AppGroups";
import SeachFieldWeightage from "../../Page/SearchFieldWeightage/SearchFieldWeightage";
import ContentFeedback from "../../Page/ContentFeedback/ContentFeedback";
import NotFound from "../../Page/NotFoundPage/NotFoundPage";
// import Auth from "../Auth/Auth";
import { signIn, signOut } from "../../store/auth";
import AllContent from "../../Page/AllContent/AllContent";
import AuditLogs from "../../Page/AuditLogs/AuditLogs"
import Unauthorized from '../../Page/Unauthorize/Unauthorized'
import SessionTimeout from '../Utils/SessionTimeout'
import ManageSupport from "../../Page/ManageSupport/ManageSupport";
import ManageHelpCategories from "../../Page/ManageSupport/ManageHelpCategories";
import EditContentFeedback from "../../Page/ContentFeedback/EditContentFeedback";
import { showNotification } from './../../store/notification/actionCreator'
import configureHttpInterceptor from './../Utils/httpInterceptor'

const styles = theme => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: 48
    },
    [theme.breakpoints.up("sm")]: {
      height: 64
    }
  },
  contentContainer: {
    padding: theme.spacing.unit // Default <Grid container> spacing is 16. Use 8px padding for bug in material-ui Grid. See https://github.com/callemall/material-ui/issues/7466#issuecomment-316356427
  },
  sideNavFooterList: {
    color: theme.typography.body1.color,
    display: "flex",
    flexWrap: "wrap",
    fontSize: theme.typography.caption.fontSize,
    listStyle: "none",
    margin: 0,
    padding: theme.spacing.unit * 1.5,
    "& li": {
      padding: theme.spacing.unit / 2
    },
    "& a": {
      color: theme.typography.caption.color,
      textDecoration: "none",
      transition: theme.transitions.create("color", {
        duration: theme.transitions.duration.shorter
      }),
      "&:hover": {
        color: theme.palette.primary[500]
      }
    }
  },
  activeLink: {
    backgroundColor: "rgba(158,158,158,.2)"
  }
});

export class Layout extends React.Component {
  constructor(props){
    super(props)
    configureHttpInterceptor(props.history, showNotification)
    this.state = {
      readOnly: []
    }
  }
  componentWillMount(){
    if ((this.props.session?.userInfo?.lanId !== '') && this.props.isAuthorized()) {
      this.props.fetchConfig(this.props.session?.userInfo?.lanId)
    }
  }
  componentDidMount(){
    if (this.props.config.success) {
      this.setState({readOnly: this.props.config.data})
    }
  }
  componentWillReceiveProps(nextProps){
    if ((nextProps.config.success) && (nextProps.config.data !== this.props.config.data)) {
      this.setState({readOnly: nextProps.config.data})
    }
    if ((nextProps.session?.userInfo?.lanId !== '') && (nextProps.session !== this.props.session) && (nextProps.config.success === undefined)) {
      configureHttpInterceptor(nextProps.history, showNotification)
      nextProps.fetchConfig(nextProps.session?.userInfo?.lanId)
    }
  }

  static propTypes = {
    classes: object,
    headerTitle: string,
    openSideNav: func,
    closeSideNav: func,
  };

  static defaultProps = {
    classes: {},
    headerTitle: undefined,
    layoutActions: {},
    notificationActions: {},
  };

  sideNavLinksList = () => {
    const { classes = {} } = this.props;
    const { readOnly } = this.state
    return (
      <div>
          <Link
            to="/"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <HomeIcon />
            </Icon>
            <Text>HOME</Text>
          </Link>
        {(readOnly.admin || readOnly.support) && (
          <Link
            to="/apps"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <AppsIcon />
            </Icon>
            <Text>Apps & Reports</Text>
          </Link>
        )}
        {readOnly.admin && (
          <Link
            to="/allContent"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <ArchiveIcon />
            </Icon>
            <Text>All Content (SharePoint)</Text>
          </Link>
        )}
        {readOnly.admin && (
          <Link
            to="/reason"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <ProjectIcon />
            </Icon>
            <Text>Reasons for Support</Text>
          </Link>
        )}
        {readOnly.admin && (
          <Link
            to="/appCategories"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <CategoryIcon />
            </Icon>
            <Text>App Categories</Text>
          </Link>
        )}
        {readOnly.admin && (
          <Link
            to="/appGroups"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <GroupIcon />
            </Icon>
            <Text>App User Groups</Text>
          </Link>
        )}
        {(readOnly.admin || readOnly.content) && (
          <Link
            to="/contentFeedback"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <GroupIcon />
            </Icon>
            <Text>Content Feedback</Text>
          </Link>
        )}
        {readOnly.admin && (
          <Link
            to="/audit"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <GroupIcon />
            </Icon>
            <Text>Audit Logs</Text>
          </Link>
        )}
        {(readOnly.admin || readOnly.support) && (
          <Link
            to="/manage-support"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <GroupIcon />
            </Icon>
            <Text>Manage Support</Text>
          </Link>
        )}
        {(readOnly.admin || readOnly.content) && (
          <Link
            to="/search-fields"
            onClick={this.handleClick}
            exact
            activeClassName={classes.activeLink}
          >
            <Icon>
              <GroupIcon />
            </Icon>
            <Text>Manage Search Attributes & Weights</Text>
          </Link>
        )}
      </div>
    );
  };
  handleMenuButtonClick = () => {
    if (this.props.sideNavIsOpen) {
      this.props.closeSideNav();
    } else {
      this.props.openSideNav();
    }
  };

  handleCloseSideNav = () => {
    if (this.props.sideNavIsOpen) {
      this.props.closeSideNav();
    }
  };
  handleClick = event => {
    this.handleCloseSideNav();
  };

  handleSignOut = () => {
    this.props.logout()
  }

  render() {
    const {
      classes,
      headerTitle,
      sideNavIsOpen,
      login,
      logout
    } = this.props;
    const { readOnly } = this.state
    const isAuthorized = this.props.isAuthorized()
    return (
      <div>
        <Helmet
          defaultTitle="Partners Online admin"
          titleTemplate="Partners Online admin : %s"
        />
        {/* <Auth /> */}
        <Notifications />
        <SessionTimeout LoginCtrl={LoginCtrl} signOut={this.handleSignOut} />
        <SideNav isOpen={sideNavIsOpen} onClose={this.handleCloseSideNav}>
          <Header>
            <UserBar
              displayName={this.props.session?.userInfo?.email}
              handleSignIn={login}
              handleSignOut={logout}
              isAuthorized={this.props.isAuthorized()}
            />
          </Header>
          {this.sideNavLinksList()}
          <Footer>
            <ul className={classes.sideNavFooterList}>
              <li>
                <a href="https://www.partnersonline.com">Partners Online</a>
              </li>
            </ul>
          </Footer>
        </SideNav>
        {isAuthorized && (
          <AppHeader
            title={headerTitle}
            menuAction={() => this.handleMenuButtonClick()}
          />
        )}
        {isAuthorized ? (
          <div className={classes.contentContainer}>
            <Grid container>
              <Grid item xs={12}>
                {/* routes */}
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  {(readOnly.admin || readOnly.support) && <Route exact path="/apps/" component={AppsAndReport} />}
                  {(readOnly.admin || readOnly.support) && <Route exact path="/apps/:type?/:id?" component={EditAppsAndReport}/>}
                  {readOnly.admin && <Route exact path="/reason" component={ReasonSupport} />}
                  {readOnly.admin && <Route exact path="/reason/:status?/:id?" component={EditReasonForSupport}/>}
                  {readOnly.admin && <Route exact path="/appCategories" component={AppCategories}/>}
                  {readOnly.admin && <Route exact path="/appGroups" component={AppGroups} />}
                  {readOnly.admin && <Route exact path="/audit" component={AuditLogs} />}
                  {(readOnly.admin || readOnly.support) && <Route exact path="/manage-support" component={ManageSupport} />}
                  {(readOnly.admin || readOnly.support) && <Route exact path="/manageHelpCategories" component={ManageHelpCategories} />}
                  {readOnly.admin && <Route exact path="/allContent" component={AllContent} />}
                  {(readOnly.admin || readOnly.content) && <Route exact path="/contentFeedback" component={ContentFeedback} />}
                  {(readOnly.admin || readOnly.content) && <Route exact path="/contentFeedback/edit/:id" component={EditContentFeedback} />}
                  {(readOnly.admin || readOnly.content) && <Route exact path="/search-fields" component={SeachFieldWeightage} />}
                  {!readOnly.admin && !readOnly.support && !readOnly.content && <Route exact path="/unauthorized" component={Unauthorized} />}
                  <Route component={NotFound} />
                </Switch>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className="home-bg">
            <Dialog
              open={this.props.open}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                style={{ textAlign: "center" }}
              >
                <span style={{ fontSize: "28px", fontWeight: "bolder" }}>
                  Welcome to POL Admin
                </span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    style={{ width: "200px", height: "50px" }}
                    //color="secondary"
                    onClick={this.props.login()}
                  >
                    Log in
                  </Button>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

Layout.propTypes = {
  sideNavIsOpen: PropTypes.bool,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  open: PropTypes.bool,
  openSideNav: PropTypes.func,
  closeSideNav: PropTypes.func,
  closeNoAccessPopUp: PropTypes.func,
  history: PropTypes.object,
  fetchConfig: PropTypes.func,
  appsAndReports: PropTypes.object,
  auth: PropTypes.object,
  config: PropTypes.array.isRequired,
  isAuthorized: PropTypes.func,
  session: PropTypes.object,
  login: PropTypes.func,
  logout: PropTypes.func,
};
Layout.defaultProps = {
  classes: {},
  headerTitle: undefined,
  headerIcon: undefined,
  layoutActions: {},
  notificationActions: {},
  open: true
};

const mapStateToProps = state => ({
  headerTitle: state.layout.headerTitle,
  sideNavIsOpen: state.layout.sideNavIsOpen,
  appsAndReports: state.appsAndReports,
  config: state.config,
});

const mapDispatchToProps = {
  fetchConfig,
  openSideNav,
  closeSideNav,
};

const mapAuthToProps = auth => ({
  ...auth,
})

export default withAuth(mapAuthToProps)(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Layout)));
