import axios from 'axios'
import _ from 'lodash'
import {
  APPS_REPORTS_API_CALL_INITIATED,
  APPS_REPORTS_API_CALL_SUCCESS,
  APPS_REPORTS_API_CALL_FAIL,
  OPEN_MODAL,
  CLOSE_MODAL,
  APPS_REPORT_CATEGORIES_UPDATE,
  APPS_REPORT_CATEGORIES_UPDATE_SUCCESS,
  APPS_REPORT_CATEGORIES_UPDATE_FAIL,
  FETCH_APPS_REPORTS_API_CALL_INITIATED,
  FETCH_APPS_REPORTS_API_CALL_SUCCESS,
  FETCH_APPS_REPORTS_API_CALL_FAIL,
  CLEAR_APPS_REPORTS_API_CALL_INITIATED,
  POST_APPS_REPORTS_API_CALL_FAIL,
  POST_APPS_REPORTS_API_CALL_INITIATED,
  POST_APPS_REPORTS_API_CALL_SUCCESS,
  POST_APPS_REPORTS_API_CALL_ERROR,
  CHECK_APPS_INITIATED,
  CHECK_APPS_SUCCESS,
  CHECK_APPS_FAIL,
  CHECK_CONFIG,
  CONFIG_CALL_FAIL,
} from './actionType'

import { TEXT_SUCCESS_APP_ADDED, TEXT_SUCCESS_APP_UPDATED, TEXT_ERROR_DEFAULT, TEXT_ERROR_DUPLICATE} from "../../constants/notifications";
import { showNotification } from '../notification/actionCreator'
import apiConfig from '../../config/apiConfig'

export function fetchApps() {
  return function(dispatch) {
    dispatch(apiCallInitiated({
      type: APPS_REPORTS_API_CALL_INITIATED,
      data: [],
    }))
    return axios.get(`${apiConfig.adminApi.host}admin_app_list`, {
      data: {},
    })
      .then(response => {
        dispatch(apiCallSuccess(response.data))
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
      })
  }
}
export function apiCallInitiated(data) {
  return {
    type: APPS_REPORTS_API_CALL_INITIATED,
    data,
  }
}
export function apiCallSuccess(data) {
  return {
    type: APPS_REPORTS_API_CALL_SUCCESS,
    data,
  }
}
export function apiCallFail(error) {
  return {
    type: APPS_REPORTS_API_CALL_FAIL,
    error,
  }
}

export function openModal(modalName) {
  return {
    type: OPEN_MODAL,
    modalName,
  }
}

export function closeModal(modalName) {
  return {
    type: CLOSE_MODAL,
    modalName,
  }
}

export function updateAppCategories(data) {
  let postData = UpdateObj(data)
  return function(dispatch) {
    dispatch(updateAppCategoriesInitiated())
    return axios.post(`${apiConfig.adminApi.host}edit_categories`, {
      app_rpt_admin_vo_list: postData.app_rpt_admin_vo_list,
      admin_app_report_list: postData.admin_app_report_list,
    })
      .then(response => {
        dispatch(closeModal('editForm'))
        dispatch(updateAppCategoriesCallSuccess())
        dispatch(fetchApps())
      })
      .catch((e) => {
        dispatch(updateAppCategoriesCallFail())
        dispatch(closeModal('editForm'))
      })
  }
}
export function updateAppCategoriesInitiated() {
  return {
    type: APPS_REPORT_CATEGORIES_UPDATE,
  }
}

export function updateAppCategoriesCallSuccess(data) {
  return {
    type: APPS_REPORT_CATEGORIES_UPDATE_SUCCESS,
    data,
  }
}

export function updateAppCategoriesCallFail(error) {
  return {
    type: APPS_REPORT_CATEGORIES_UPDATE_FAIL,
    error,
  }
}

/****/
export function fetchEditApps(param) {
  return function(dispatch) {
    dispatch(fetchEditAppsInitiated({
      type: FETCH_APPS_REPORTS_API_CALL_INITIATED,
      data: [],
    }))
    return axios.get(`${apiConfig.adminApi.host}admin_app_report/` + param, {
      data: {},
    })
      .then(response => {
        dispatch(fetchEditAppsCallSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchEditAppsCallFail(error))
      })
  }
}

export function fetchEditAppsInitiated(data) {
  return {
    type: FETCH_APPS_REPORTS_API_CALL_INITIATED,
    data,
  }
}
export function clearEditApps(data) {
  return {
    type: CLEAR_APPS_REPORTS_API_CALL_INITIATED,
    data,
  }
}
export function fetchEditAppsCallSuccess(data) {
  return {
    type: FETCH_APPS_REPORTS_API_CALL_SUCCESS,
    data,
  }
}

export function fetchEditAppsCallFail(data) {
  return {
    type: FETCH_APPS_REPORTS_API_CALL_FAIL,
    data,
  }
}

/***************************/
export function postAppsReports(data, isEdit) {
  let txt=''
  if(isEdit) {
    txt = TEXT_SUCCESS_APP_UPDATED
  }
  else{
    txt = TEXT_SUCCESS_APP_ADDED
  }
  return function(dispatch) {
    dispatch(postAppsReportsInitiated())
    return axios.post(`${apiConfig.adminApi.host}edit_apps_reports`, data)
      .then(response => {
        dispatch(showNotification(true, txt, 'success'))
        dispatch(postAppsReportsCallSuccess())
      })
      .catch((e) => {
        dispatch(postAppsReportsCallFail())
        dispatch(postAppsReportsCallError(true, e?.response?.data?.meta_info?.error?.message))
        // if(e.response && e.response.status && e.response.status === 400 || 500){
        //   dispatch(postAppsReportsCallError(true, data.title+TEXT_ERROR_DUPLICATE+"App/Report."))
        // }else{
        //   dispatch(postAppsReportsCallError(true, TEXT_ERROR_DEFAULT))
        // }
      })
  }
}
export function postAppsReportsInitiated() {
  return {
    type: POST_APPS_REPORTS_API_CALL_INITIATED,
  }
}

export function postAppsReportsCallSuccess(data) {
  return {
    type: POST_APPS_REPORTS_API_CALL_SUCCESS,
    data,
  }
}

export function postAppsReportsCallFail(error) {
  return {
    type: POST_APPS_REPORTS_API_CALL_FAIL,
    error,
  }
}

export function postAppsReportsCallError(status, text) {
  return {
    type: POST_APPS_REPORTS_API_CALL_ERROR,
    status,
    text,
  }
}
/***************************/

export function UpdateObj(data) {
  let selectedRowIds = data.selectedRowIds
  let cats = data.selectedOption
  let result = cats.map(function(o) {
    return Object.assign({
      app_group_id: o.value,
      app_group_name: o.label
    }, _.omit(o, 'label', 'value'))
  })
  let ids = selectedRowIds.map(function(val) {
    return Object.assign({
      id: val.id,
    })
  })
  let obj = {
    app_rpt_admin_vo_list: ids,
    admin_app_report_list: result
  }
  return obj
}
/*****************/
export function checkAppsAndReports(data) {
  return function (dispatch) {
    dispatch(checkAppsInitiated())
    return axios.get(`${apiConfig.adminApi.host}admin_app_report/validate?title=${data}`)
      .then(response => {
        dispatch(checkAppsSuccess(response.data.result))
      })
      .catch(error => {
        dispatch(checkAppsFail(error))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
      })
  }
}
export function checkAppsInitiated() {
  return {
    type: CHECK_APPS_INITIATED
  }
}
export function checkAppsSuccess(data) {
  return {
    type: CHECK_APPS_SUCCESS,
    data,
  }
}
export function checkAppsFail(error) {
  return {
    type: CHECK_APPS_FAIL,
    error,
  }
}
