export const commonModuleList = [
    "home",
    "login",
    "projects",
    "supportform",
    "comments"
]

export const adGroupFuncList = [
    {
        moduleList: [...commonModuleList],
        priority: 5,
        adGroupName: "OAUTH2-ASSETHUB-EXTERNAL"
    },
    {
        moduleList: [
            ...commonModuleList,
            "search",
            "groups",
            "boards",
            "upload",
            "viewallfiles"
        ],
        priority: 4,
        adGroupName: "OAUTH2-ASSETHUB-EXTERNAL-ADVANCED"
    },
    {
        moduleList: [
            ...commonModuleList,
            "search",
            "groups",
            "boards",
            "upload",
            "viewallfiles"
        ],
        priority: 3,
        adGroupName: "APP-OAUTH2-ASSETHUB"
    },
    {
        moduleList: [
            ...commonModuleList,
            "search",
            "groups",
            "boards",
            "upload",
            "viewallfiles",
            "ADID",
            "userrights"
        ],
        priority: 2,
        adGroupName: "APP-OAUTH2-ASSETHUB-RIGHTS"
    },
    {
        moduleList: [
            ...commonModuleList,
            "search",
            "groups",
            "boards",
            "upload",
            "viewallfiles",
            "ADID",
            "userrights",
            "spywatch"
        ],
        priority: 1,
        adGroupName: "APP-OAUTH2-ASSETHUB-ADMIN"
    }
]
