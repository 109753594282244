import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Error from '@material-ui/icons/Error'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Add from '@material-ui/icons/Add'
import HeaderTitle from "../../components/Header/HeaderTitle"
import GridConfig from './GridConfig'
import AppGroupsForm from './AppGroupsForm'
import { fetchGroups,openModal, closeModal, updateAppGroups, deleteAdGroups, checkGroups} from '../../store/appGroups/actionCreator'
import "../../styles/tabel.css"

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '99%',
  },
  container: {
    marginTop:10,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button : {
    marginLeft: 20,
  },
  search: {
    width:'60%',
    float: 'right',
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 24,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  input:{
    width: 'calc(100% - 70px)',
    border: 0,
  },
  tableSize:{
    height: "calc(100vh - 185px)",
    width: "100%"
  },
})
class LdsAdGroup extends Component {
  constructor(props) {
    super(props)
    this.gConfig =  new GridConfig()
    this.state = {
      columnDefs: this.gConfig .createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        // width: 100
      },
      rowData:  [],
      // enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting : true,
      /** For application **/
      selectedRowsApps:'',
      openModal : false,
      isAdd : false,
      selectedRow: ''
      /** For application **/
    }
    this.gridOptions = {
      columnDefs : this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady :this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      rowBuffer: 10, // no need to set this, the default is fine for almost all scenarios,
      onRowSelected: this.rowSelected,
      enableCellTextSelection: true,
    }
    this.handleAppGroup = this.handleAppGroup.bind(this)
    this.closeAppGroup = this.closeAppGroup.bind(this)
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.appGroups.appGroupsListCount > 0){
      this.setState({rowData:nextProps.appGroups.appGroupsList})
    }
    if (nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly) {
      this.setState({readOnly: nextProps.appsAndReports.readOnly.read_only && JSON.parse(nextProps.appsAndReports.readOnly.read_only)})
      // this.setState({readOnly: true})
    }
  }
  componentWillMount() {
    this.props.fetchGroups()
  }
  onGridReady = params => {
    this.gridApi = params.api
  }
  handleClick = params => {
    this.setState({selectedRow :params.data, isAdd: false },()=>{
      this.props.openModal('editAppGroup')
    })
  }
  handleAppGroup = params => {
    this.setState({isAdd :true },()=>{
      this.props.openModal('editAppGroup')
    })
  }
  updateAppGroup = params => {
    this.props.updateAppGroups(params)
  }
  deleteAdGroups = (id, isMapped) => {
    //= id => isMapped => {
    this.props.deleteAdGroups(id, isMapped)
  }
  closeAppGroup () {
    this.props.closeModal('editAppGroup')
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("searchLdsGroups").value)
  }
  checkAppGroup = (param) => {
    this.props.checkGroups(param)
  }
  render() {
    const {classes, appGroups} = this.props
    const {selectedRow,isAdd,readOnly} = this.state
    return (
      <div className={classes.root}>
        <HeaderTitle title="App LDS Group" />
        <Helmet>
          <title>Manage App LDS Groups</title>
        </Helmet>
        {appGroups.loading && appGroups.loading.appGroupsListLoading ? <CircularProgress size={50} className={classes.buttonProgress} />
          : appGroups.apiError
            ? <Grid container spacing={24} >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>An error occurred while retrieving App LDS Group data. Please contact support if this issue persists.</p>
              </Grid>
            </Grid>
            : appGroups.appGroupsListCount >= 1 ? <Grid container spacing={24} className={classes.container}>
                <Grid item xs={6}>
                  <Button disabled={readOnly} variant="contained" color="primary" className={classes.button} onClick={() => this.handleAppGroup()}>
                    <Add className={classes.buttonIcon}/>
                    Add
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.search} elevation={1}>
                    <IconButton className={classes.iconButton} aria-label="Search"><SearchIcon /></IconButton>
                    <InputBase className={classes.input} placeholder="Search List" onInput={this.onQuickFilterChanged.bind(this)} id="searchLdsGroups" />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                    <div className={[classes.tableSize, 'ag-theme-material full-row'].join(' ')}>
                    <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
                  </div>
                </Grid>
              </Grid>
              : <Grid container spacing={24}>
                <Grid item xs={12}>
                  <p className={classes.boxDiv}><Error className={classes.icon} /> There are no App Categories.</p>
                </Grid>
              </Grid>
        }
        {this.props.appGroups.modal.editAppGroup &&
          <AppGroupsForm
            open={this.props.appGroups.modal.editAppGroup}
            appGroupsProps={this.props.appGroups}
            updateAppGroup={this.updateAppGroup}
            onClose={this.closeAppGroup}
            data={appGroups}
            selectedRow={selectedRow}
            isAdd={isAdd}
            deleteAdGroups={this.deleteAdGroups}
            checkAppGroup={this.checkAppGroup}
          />
        }
      </div>
    )
  }
}
LdsAdGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchGroups: PropTypes.func,
  updateAppGroups: PropTypes.func,
  appGroups :PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteAdGroups: PropTypes.func,
  checkGroups: PropTypes.func,
  appsAndReports: PropTypes.object,
}
const mapStateToProps = state => {
  const { appGroups, appsAndReports } = state
  return {
    appGroups: appGroups,
    appsAndReports,
    openModal,
    closeModal,
  }
}
export default connect(mapStateToProps, {fetchGroups, openModal, closeModal, updateAppGroups,deleteAdGroups, checkGroups})(withStyles(styles)(LdsAdGroup))


