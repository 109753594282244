import axios from 'axios'
import { CHECK_CONFIG, CONFIG_CALL_FAIL } from './actionType'
import apiConfig from '../../config/apiConfig'

// const configData = {
//   "meta_info": {
//       "request_id": "f1549531-4983-4038-8252-8e8228ad5d0d",
//       "request_url": "GET /pol-service/api/v1/admin/config",
//       "response_time": 4868
//   },
//   "result": [
//       {
//           "access_name": "SUPPORT",
//           "has_access": false
//       },
//       {
//           "access_name": "CONTENT",
//           "has_access": true
//       },
//       {
//           "access_name": "ADMIN",
//           "has_access": false
//       }
//   ]
// }

export function fetchConfig(user) {
  return dispatch => axios.get(`${apiConfig.adminApi.host}config`,{headers: {"login":"true"}})
      .then(response => {
        dispatch(checkConfig(response.data))
      })
      .catch(error => {
        dispatch(configFail(error.response))
      })
  // return dispatch => {
  //   setTimeout(() => {
  //     dispatch(checkConfig(configData))
  //   })
  // }
}

export function checkConfig (data) {
  // console.log(data)
  return {
    type: CHECK_CONFIG,
    data,
  }
}

export function configFail (error) {
  return {
    type: CONFIG_CALL_FAIL,
    error,
  }
}
