import {
  CONTENT_CALL_INITIATED,
  CONTENT_CALL_FAIL,
  CONTENT_CALL_SUCCESS,
  CONTENTBYID_CALL_INITIATED,
  CONTENTBYID_CALL_SUCCESS,
  CONTENTBYID_CALL_FAIL,
  EDITCONTENT_CALL_FAIL,
  EDITCONTENT_CALL_INITIATED,
  EDITCONTENT_CALL_SUCCESS
} from './actionType'

export default function contents(state = [], action = {}) {
  let loading
  switch (action.type) {
    case CONTENT_CALL_INITIATED:
      loading = {
        ...state.loading,
        contentListLoading: true
      }
      return {
        ...state,
        loading
      }
    case CONTENT_CALL_SUCCESS:
      loading = {
        ...state.loading,
        contentListLoading: false
      }
      let contentList =
        (action.data && action.data.result && action.data.result.content) || []
      let result =
        (action.data && action.data.result && action.data.result) || {}
      let content = contentList.sort(function(a, b) {
        let a_date = new Date(a.feedback_date)
        let b_date = new Date(b.feedback_date)
        if (a.contact === b.contact) {
          if (a.item_id === b.item_id) {
            return a_date > b_date ? 1 : -1
          }
          return Number(a.item_id) > Number(b.item_id) ? 1 : -1
        }
        return a.contact &&
          a.contact.toUpperCase() > b.contact &&
          b.contact.toUpperCase()
          ? 1
          : -1
      })
      return {
        ...state,
        loading,
        apiError: false,
        contentFeedback: content,
        result: result
      }
    case CONTENT_CALL_FAIL:
      loading = {
        ...state.loading,
        contentListLoading: false
      }
      return {
        ...state,
        loading,
        apiError: true
      }
    default:
      return state
  }
}

export function contentById(state = [], action = {}) {
  switch (action.type) {
    case CONTENTBYID_CALL_INITIATED:
      return {
        loading: true,
        apiError: false
      }
    case CONTENTBYID_CALL_SUCCESS:
      return {
        loading: false,
        apiError: false,
        content: action.data.result
      }
    case CONTENTBYID_CALL_FAIL:
      return {
        loading: false,
        apiError: true
      }
    default:
      return state
  }
}

export function editContent(state = [], action = {}) {
  switch (action.type) {
    case EDITCONTENT_CALL_INITIATED:
      return {
        loading: true,
        apiError: false
      }
    case EDITCONTENT_CALL_SUCCESS:
      return {
        loading: false,
        apiError: false,
        success: action.data.result
      }
    case EDITCONTENT_CALL_FAIL:
      return {
        loading: false,
        apiError: true
      }
    default:
      return state
  }
}
