export const FETCH_FIELDS_REQUEST = 'FETCH_FIELDS_REQUEST';
export const FETCH_FIELDS_SUCCESS = 'FETCH_FIELDS_SUCCESS';
export const FETCH_FIELDS_FAILURE = 'FETCH_FIELDS_FAILURE';

export const UPDATE_FIELDS_REQUEST = 'UPDATE_FIELDS_REQUEST';
export const UPDATE_FIELDS_SUCCESS = 'UPDATE_FIELDS_SUCCESS';
export const UPDATE_FIELDS_FAILURE = 'UPDATE_FIELDS_FAILURE';

export const FETCH_SEARCH_DATA_REPORT = 'FETCH_SEARCH_DATA_REPORT';
export const FETCH_SEARCH_DATA_REPORT_SUCCESS = 'FETCH_SEARCH_DATA_REPORT_SUCCESS';
export const FETCH_SEARCH_DATA_REPORT_FAILURE = 'FETCH_SEARCH_DATA_REPORT_FAILURE';

export const FETCH_SUMMARY_REPORT = 'FETCH_SUMMARY_REPORT';
export const FETCH_SUMMARY_REPORT_SUCCESS = 'FETCH_SUMMARY_REPORT_SUCCESS';
export const FETCH_SUMMARY_REPORT_FAILURE = 'FETCH_SUMMARY_REPORT_FAILURE';
export const CLEAR_SELECTED_FILES = 'CLEAR_SELECTED_FILES';