import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { Link, Modal } from 'nicollet-react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Info from '@material-ui/icons/Info'
import DatePicker from "react-datepicker"
import { addHours, addMinutes } from "date-fns"
import Select, { components } from 'react-select'
import "../../styles/datepicker.css"
import DateRange from '@material-ui/icons/DateRange'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Error from '@material-ui/icons/Error'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Divider from '@material-ui/core/Divider/Divider'
import ChipInput from 'material-ui-chip-input'
import WarningIcon from '@material-ui/icons/Warning'
// import Chip from '@material-ui/core/Chip'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { closeModal, fetchEditApps, openModal, clearEditApps, postAppsReports, checkAppsAndReports, postAppsReportsCallError } from '../../store/appsAndReport/actionCreator'
import { fetchCategories } from '../../store/categories/actionCreator'
import AddGroups from './AddGroups'
import UserTable from './UserTable'
import { setTzFormat, displayTzFormat } from '../../Utills/dateHelper'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // marginTop:10,
    padding: '0 20px 20px 20px',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: 20,
  },
  buttonContainer: {
    float: 'right',
    padding: 12,
  },
  buttonAcronym: {
    backgroundColor: '#fff',
    color: '#a1a1a0',
    paddingRight: 20,
    textTransform: 'none'
  },
  search: {
    width: '60%',
    float: 'right',
  },
  alertBar: {
    backgroundColor: '#ffa000',
    color: '#FFF',
    padding: '5px 5px 15px 10px',
    borderRadius: 4,
    width: '80%',
  },
  alertIcon: {
    top: 10,
    position: 'relative',
    padding: 5,
  },
  contentTitle: {
    fontWeight: 500,
    fontSize: 24,
    color: '#333333',
    marginTop: '1em',
  },
  inputStyle: {
    padding: '5px 10px',
  },
  formFieldRoot: {
    width: '100%',
    border: '1px solid #66666659',
    color: '#666666',
  },
  inputUnderline: {
    // '&:hover': {
    //   borderBottom: '0px solid #66666659',
    // },
  },
  formContainerStyle: {
    margin: '12px 0',
  },
  captionText: {
    // fontWeight: 500,
    fontSize: 14,
    color: '#666666',
    marginBottom: 5,
  },
  linkStyle: {
    color: '#0049DB',
    textDecoration: 'none',
  },
  linkArchive: {
    color: '#bb423a',
    textDecoration: 'none',
  },
  dateContainer: {
    display: 'flex',
    marginTop: '1em',
  },
  dateFieldBlock: {
    width: '25%',
    display: 'flex',
    marginBottom: 25,
  },
  datePickerIconStyle: {
    position: 'relative',
    top: 10,
    right: '25%',
    cursor: 'pointer',
  },
  saveContainer: {
    position: 'absolute',
    right: 20,
    top: '20%',
    width: '28%',
    border: '1px solid #d6d6d6!important',
    boxShadow: '0 1px 0 0 rgba(0,0,0,.1)!important',
    borderRadius: '2px!important',
    padding: 20,
    // [theme.breakpoints.down('xl')]: {
    //   maxWidth: '32%',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   maxWidth: '32%',
    // },
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: '32%',
    // },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: 0,
      top: '1em',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      right: 0,
      top: '1em',
      width: '100%',
    },
  },
  saveButtonStyle: {
    background: '#0049DB',
    color: '#fff',
    '&:hover': {
      backgroundColor: "#0762db",
    },
    // marginTop: '1em',
    "&$buttonDisabled": {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: "#FFF",
      border: '1px solid rgba(0, 0, 0, 0.26)'
    }
  },
  buttonDisabled: {},
  alertText: {
    border: '1px solid #b85300',
    margin: '20px 0',
    padding: 10,
    display: 'flex',
  },
  publishContentFinal: {
    color: '#b85300',
    marginLeft: 10,
    marginTop: 2,
  },
  infoIconStyle: {
    width: 20,
    height: 20,
    fill: '#b85300',
  },
  buttonProgress: {
    color: '#FFF',
    margin: '0 20px',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radioLabel: {
    flexDirection: 'row',
  },
  textField: {
    marginTop: 15,
  },
  cancelButton: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#0049db',
    marginRight: 20,
  },
  placeHolderStyle: {
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: 1,
    width: 'auto',
    color: '#c6c6c6',
    fontSize: 12,
    marginTop: -6,
    marginLeft: 10,
    padding: '0 5px',
  },
  errorIcon: {
    color: 'red',
    margin: 8,
  },
  correctIcon: {
    color: 'green',
    margin: 8,
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
})
const customControlStyles = {
  menu: () => ({
    zIndex: 2,
    border: '1px solid #dedddd',
  }),
  multiValue: () => ({
    backgroundColor: '#dedddd',
    borderRadius: 16,
    display: 'inline-flex',
    padding: '5px 5px 0 5px',
    margin: '5px 5px 5px 0',
  }),
  multiValueRemove: () => ({
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer'
    }
  }),
}
const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <CancelIcon style={{ color: 'rgba(0, 0, 0, 0.26)' }} />
  </components.MultiValueRemove>
);
class EditAppsAndReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      selectedOption: [],
      title: '',
      displayName: '',
      url: '',
      archive: false,
      description: '',
      browserRequirement: '',
      publishStartDate: null, //new Date(),
      publishEndDate: null, //new Date(),
      outageStartDate: null, //new Date(),
      outageEndDate: null, //new Date(),
      isEdit: props.isEdit,
      iagB2BAppName: '',
      outage: 'online',
      externalApp: 'N',
      published: 'active',
      appOrReport: 'R',
      showArchiveModal: false,
      technicalContacts: [],
      scrumMaster: [],
      productDirector: [],
      productOwner: [],
      grpList: [{
        description: '',
        ldsgroup: [],
        partner_types: '',
        provisioning: '',
        statuses: '',
        user_access_id: '',
      }],
      mandatoryField: true,
      // readOnly: false,
    }
    this.publishStartDateOpen = React.createRef()
    this.publishEndDateOpen = React.createRef()
    this.outageStartDateOpen = React.createRef()
    this.outageEndDateOpen = React.createRef()
  }
  componentDidMount() {
    if (this.state.isEdit) {
      let param = ''
      if (this.props.fetchProperties && this.props.fetchProperties.match.params.id) {
        param = this.props.fetchProperties.match.params.id
        this.setState({ id: param })
      }
      this.props.fetchEditApps(param)
    } else {
      this.props.clearEditApps()
    }
    // if (this.props.appsAndReports.readOnly !== undefined) {
    //   this.setState({readOnly: JSON.parse(this.props.appsAndReports.readOnly.read_only)})
    //   // this.setState({readOnly: true})
    // }
    this.props.fetchCategories()
    this.setStateValue(this.props.appsAndReports.fetchApps)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && this.props.appsAndReports.fetchApps !== nextProps.appsAndReports.fetchApps) {
      this.setStateValue(nextProps.appsAndReports.fetchApps)
    }
    if (nextProps && nextProps.appsAndReports.postSuccess) {
      this.props.fetchProperties.history.push('/apps')
    }
    // if (nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly) {
    //   this.setState({readOnly: JSON.parse(nextProps.appsAndReports.readOnly.read_only)})
    //   // this.setState({readOnly: true})
    // }
  }
  setStateValue(obj) {
    if (obj && Object.keys(obj).length > 0) {
      let objData = obj
      this.setState({
        title: objData.title,
        displayName: objData.display_name,
        url: objData.url,
        description: objData.description,
        archive: objData.archive,
        outage: objData.outage,
        published: (objData.published === 'active') ? true : false, //(objData.published) ? objData.published : 'online',
        externalApp: (objData.external_app === 'Y') ? 'Y' : 'N',  // Third party application / No
        appOrReport: (objData.app_or_report) ? objData.app_or_report : 'A',
        grpList: objData.user_acess_admin_vo_list,
        provisioning_data: objData.user_access_admin_provisioning_vos,
        browserRequirement: (objData.browser_requirement) ? objData.browser_requirement : '',
        selectedOption: this.getCategories(objData),
        publishStartDate: (objData.publishing_start_date) ? displayTzFormat(objData.publishing_start_date) : null,
        publishEndDate: (objData.publishing_end_date) ? displayTzFormat(objData.publishing_end_date) : null,
        outageStartDate: (objData.outage_start_date) ? displayTzFormat(objData.outage_start_date) : null,
        outageEndDate: (objData.outage_end_date) ? displayTzFormat(objData.outage_end_date) : null,
        productDirector: objData.product_director ? this.getUsers(objData.product_director) : [],
        productOwner: objData.product_owner ? this.getUsers(objData.product_owner) : [],
        scrumMaster: objData.scrum_master ? this.getUsers(objData.scrum_master) : [],
        technicalContacts: objData.technical_contacts ? this.getUsers(objData.technical_contacts) : [],
        notes: objData.notes,
        iagB2BAppName: objData.iag_b2b_app_name,
        edit: false
      })
    }
  }
  getUsers = (string) => {
    let str
    if (string) {
      str = (string && string.indexOf(',') > -1) ? string.split(',') : [string]
    }
    return str
  }
  getCategories = (myData) => {
    let catList = []
    if (myData && myData.category_admin_vo_list) {
      myData.category_admin_vo_list.forEach(function (obj) {
        catList.push({ label: obj.app_group_name, value: obj.app_group_id })
      })
    }
    let output = _.uniqBy(catList, 'label')
    return output
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
  }
  handleChangeField = name => event => {
    if (name === 'title') {
      this.props.checkAppsAndReports(event.target.value)
    }
    this.setState({ [name]: event.target.value })
  }
  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked })
  }
  removeGroup = (data = {}) => {
    let rmGrp = this.state.grpList.filter(task => task.ldsgroup !== data.group_name)
    this.setState({ grpList: rmGrp })
  }
  handleDateChange = (dateName, dateValue) => {
    this.setState({
      [dateName]: dateValue
    })
  }
  handleAddGrp = e => {
    if (this.props.config.data && this.props.config.data.support) {
      return false
    }
    e.preventDefault()
    this.props.openModal('addGrp')
  }
  closeAddGrp = params => {
    if (params.length > 0) {
      this.setState({ grpList: params })
    }
    this.props.closeModal('addGrp')
  }

  enableButton = () => {
    const {
      title, url, description, displayName,
      outage, externalApp, published
    } = this.state
    const { appsAndReports } = this.props
    let isValid = (!title || !displayName || !description || !url || !this.state.publishStartDate || (appsAndReports.appReportsCheck && appsAndReports.appReportsCheck.exist))
    return isValid
  }
  handleArchive = e => {
    if (this.props.config.data && this.props.config.data.support) {
      return false
    }
    this.setState({ showArchiveModal: true })
  }
  toggleModal = e => {
    // Published inactive
    this.setState(prevState => ({ showArchiveModal: !prevState.showArchiveModal }));
  }
  onSave = type => () => {
    const {
      id, title, url, description, selectedOption, browserRequirement, grpList, displayName,
      publishEndDate, publishStartDate, outageStartDate, outageEndDate, isEdit,
      outage, externalApp, published, appOrReport, archive,
      productDirector, productOwner, scrumMaster, technicalContacts, notes, iagB2BAppName,
    } = this.state
    let grpIds = ''
    let userIds = []
    if (selectedOption) {
      grpIds = selectedOption.map(function (val) {
        return Object.assign({
          app_group_id: val.value,
        })
      })
    }
    if (grpList) {
      userIds = grpList.map(function (val) {
        return Object.assign({
          user_access_id: val.user_access_id,
        })
      })
    }
    let postData = {
      "id": id,
      "title": title,
      "display_name": displayName,
      "logical_name": displayName,
      "url": url,
      "description": description,
      "browser_requirement": browserRequirement,
      "outage": outage,
      "published": (published) ? 'active' : 'inactive',
      "external_app": (externalApp === 'Y') ? 'Y' : 'N',
      "category_admin_vo_list": grpIds,
      "publishing_start_date": setTzFormat(publishStartDate),
      "publishing_end_date": setTzFormat(publishEndDate),
      "outage_start_date": setTzFormat(outageStartDate),
      "outage_end_date": setTzFormat(outageEndDate),
      "user_acess_admin_vo_list": userIds,
      "app_or_report": appOrReport,
      "archive": archive,
      "product_director": productDirector.toString(),
      "product_owner": productOwner.toString(),
      "scrum_master": scrumMaster.toString(),
      "technical_contacts": technicalContacts.toString(),
      "notes": notes,
      "edit": isEdit,
      "iag_b2b_app_name": iagB2BAppName,
      isEdit: isEdit,
    }
    if (isEdit) {
      if (type === 'Archive') {
        postData.archive = true
      } else if (type === 'Unarchive') {
        postData.archive = false
      }
    }
    if (!isEdit) {
      postData = _.omit(postData, ['id', 'isEdit'])
    }
    this.props.postAppsReports(postData, isEdit)
  }
  handleAddChip = (chip, prop) => {
    // Don't include duplicates
    if (!this.state[prop].includes(chip)) {
      this.setState(prevState => ({
        [prop]: [...prevState[prop], chip],
      }))
    }
  }
  handleDeleteChip = (chip, prop) => {
    this.setState(prevState => ({
      [prop]: prevState[prop].filter(tag => tag !== chip),
    }))
  }
  focusToNewCat = () => {
    this.props.closeModal('editForm')
    this.props.postAppsReportsCallError()
  }
  keyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault()
    }
  }
  textPasted = () => {
    navigator.clipboard.readText()
      .then(text => {
        this.setState({ description: text.replace(/(\r\n|\n|\r)/gm, ' ') })
      })
      .catch(err => {
        /* eslint-disable */
        console.error('Failed to read clipboard contents: ', err);
        /* eslint-enable */
      });
  }
  render() {
    const { classes, categories, appsAndReports, config } = this.props
    const {
      title, url, description, selectedOption, browserRequirement, grpList, displayName,
      publishEndDate, publishStartDate, outageStartDate, outageEndDate, isEdit,
      externalApp, appOrReport, published, outage, archive,
      productDirector, productOwner, scrumMaster, technicalContacts, notes, provisioning_data, iagB2BAppName,
    } = this.state
    let configData = config && config.data
    let txtArchive = archive ? 'Unarchive' : 'Archive'
    const keyCode = [188, 9, 13]
    return (
      <div className={classes.root}>
        <Helmet>
          <title>Manage App/Report</title>
        </Helmet>
        <Grid container className={classes.container}>
          <div className={classes.tableWrapper}>
            {isEdit && appsAndReports.loading.appsAndReportsLoading ?
              <CircularProgress size={50} className={classes.buttonProgress} />
              : isEdit && appsAndReports.apiError ?
                <Grid container spacing={24}>
                  <Grid item xs={12} className={classes.boxDiv}>
                    <p>An error occurred while retrieving Apps/Reports data. Please contact support if this issue persists.</p>
                  </Grid>
                </Grid>
                : (isEdit && appsAndReports.fetchAppsCount > 0) || (!appsAndReports.fetchApps && !isEdit) ?
                  <React.Fragment>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <Typography className={classes.contentTitle}>Basics</Typography>
                      <FormLabel component="legend" style={{ paddingTop: 10 }}>Type</FormLabel>
                      <RadioGroup
                        aria-label="Application Type"
                        name="App Type"
                        className={classes.radioGroup}
                        value={appOrReport}
                        onChange={this.handleChangeField('appOrReport')}>
                        <FormControlLabel
                          value="A"
                          control={<Radio color="primary" />}
                          label="App"
                          labelPlacement="start"
                          style={{ marginLeft: -10 }}
                          disabled={configData.support}
                          className={classes.radioLabel}
                        />
                        <FormControlLabel
                          value="R"
                          control={<Radio color="primary" />}
                          label="Report"
                          labelPlacement="start"
                          disabled={configData.support}
                          className={classes.radioLabel}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid container lg={12} md={12} sm={12} xs={12}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          id="apps-and-reports"
                          type="text"
                          value={title || ''}
                          label="Apps/Report Name"
                          onChange={this.handleChangeField('title')}
                          variant="outlined"
                          disabled={configData.support}
                          className={classes.textField}
                          required
                        />
                      </Grid>
                      <Grid className={classes.icons} item lg={2} md={2} sm={12} xs={12}>
                        {appsAndReports.loading && appsAndReports.loading.appReportsLoading && <CircularProgress size={25} className={classes.progress} />}
                        {appsAndReports.appReportsCheck && appsAndReports.appReportsCheck.exist && title && <Typography className={classes.icons}><CancelIcon className={classes.errorIcon} /><span style={{ color: 'red' }}>Already Exists</span></Typography>}
                        {appsAndReports.appReportsCheck && !appsAndReports.appReportsCheck.exist && title && <CheckCircleIcon className={classes.correctIcon} />}
                      </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <Typography variant="caption" className={classes.captionText}>
                        App/report name as it will be displayed in the app/report list.
                      </Typography>
                      <TextField
                        id="apps-and-reports-b2b-name"
                        type="text"
                        value={iagB2BAppName || ''}
                        label="Apps/Report B2B Name"
                        onChange={this.handleChangeField('iagB2BAppName')}
                        variant="outlined"
                        disabled={configData.support}
                        className={classes.textField}
                        required
                      />
                      <TextField
                        id="acronym"
                        type="text"
                        value={displayName || ''}
                        label="Acronym"
                        style={{ width: '10%', minWidth: 100 }}
                        onChange={this.handleChangeField('displayName')}
                        variant="outlined"
                        disabled={configData.support}
                        className={classes.textField}
                        required
                      />
                      {/*<Typography variant="caption" className={classes.captionText}>This match the app/report acronym used in SharePoint online, the “info & Training” link won’t get hooked up to the right app/report overview content. </Typography>*/}
                      <Typography variant="caption" className={classes.captionText}>
                        Unique 2-6 character acronym that represents the application report.
                      </Typography>
                      <TextField
                        id="app-report-url"
                        type="text"
                        value={url || ''}
                        label="App/Report Url "
                        onChange={this.handleChangeField('url')}
                        variant="outlined"
                        disabled={configData.support}
                        className={classes.textField}
                        required
                      />
                      <TextField
                        id="description"
                        type="text"
                        label="Description"
                        multiline={true}
                        inputProps={{
                          maxLength: 250,
                        }}
                        rows="5"
                        value={description || ''}
                        onChange={this.handleChangeField('description')}
                        variant="outlined"
                        className={classes.textField}
                        style={{ marginTop: 20 }}
                        onKeyPress={this.keyPress}
                        disabled={configData.support}
                        onPaste={this.textPasted}
                        required
                      />
                      <Typography variant="caption" className={classes.captionText} style={{ paddingBottom: 20 }}>
                        Description that is displayed below the app/report name in the app/report list (maximum 250 chars).
                      </Typography>
                      {/*
                      <Typography variant="caption" className={classes.captionText}>
                        Display under the app/report name on the “App & Report” page.
                      </Typography>
                      */}
                      {selectedOption.length > 1 && <span className={classes.placeHolderStyle}>Categories/Uses</span>}
                      <Select
                        className={classes.select}
                        name="categories"
                        value={selectedOption}
                        isMulti
                        isDisabled={configData.support}
                        onChange={this.handleChange}
                        options={categories.categoriesList}
                        placeholder='Categories/Uses'
                        styles={customControlStyles}
                        components={{ MultiValueRemove }}
                      />
                      {/* <Typography variant="caption" className={classes.captionText} style={{ paddingTop: 20 }}>This allows vendors to find you app/report more quickly especially if they are new and don’t know the name of it. Have a suggestion for new category? Contact product owner for Partners Online.</Typography> */}
                      <FormLabel component="legend" style={{ paddingTop: 10 }}>Third party application</FormLabel>
                      <RadioGroup
                        aria-label="Third party"
                        name="Third party"
                        className={classes.radioGroup}
                        value={externalApp}
                        onChange={this.handleChangeField('externalApp')}>
                        <FormControlLabel
                          value="N"
                          control={<Radio color="primary" />}
                          label="No"
                          labelPlacement="start"
                          disabled={configData.support}
                          style={{ marginLeft: -10 }}
                          className={classes.radioLabel}
                        />
                        <FormControlLabel
                          value="Y"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled={configData.support}
                          labelPlacement="start"
                          className={classes.radioLabel}
                        />
                      </RadioGroup>
                      <TextField
                        id="browser-requirements"
                        type="text"
                        value={browserRequirement || ''}
                        label="Browser Requirements"
                        onChange={this.handleChangeField('browserRequirement')}
                        variant="outlined"
                        disabled={configData.support}
                        className={classes.textField}
                      />
                      <Typography variant="caption" className={classes.captionText}>See the template of what to include
                        {!configData.support && <Link
                          href="https://confluence.target.com/x/Ep2eBw"
                          target="_blank"
                          className={classes.linkStyle}
                          style={{ marginLeft: 4, color: '#0049DB' }}
                        >here</Link>}.
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.contentTitle}>Users with Access</Typography>
                      {grpList && grpList.length > 0 &&
                        <UserTable disabled={configData.support} groupList={grpList} provisioning={provisioning_data} onDelete={this.removeGroup} />
                      }
                      <Typography variant="caption" className={classes.captionText} style={{ marginTop: 12 }}>
                        <a className={classes.linkStyle} href="#" onClick={this.handleAddGrp}>+ Add Group</a>
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {/* <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                        Time in CST. A reminder will be sent via email to administrator of Partners Online 24 hours before the publish start date and end date.
                      </Typography> */}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.contentTitle}>Outage</Typography>
                      <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                        Display this app/report as disabled / offline on Partners Online by adding an outage. This does NOT post an app alert.
                        It simply disables the app launch button.
                        {/* To add an alert message to the Partners Online CMS site on SharePoint. */}
                      </Typography>
                      <Grid container className={classes.dateContainer}>
                        <Grid item lg={4} md={4} sm={12} xs={12} className={classes.dateFieldBlock}>
                          {outageStartDate && <span className={classes.placeHolderStyle}>Outage Start Date</span>}
                          <DatePicker placeholderText="Outage Start Date"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            onChange={(value) => this.handleDateChange("outageStartDate", value)}
                            selected={outageStartDate} minDate={new Date()}
                            ref={this.outageStartDateOpen}
                          />
                          <DateRange className={classes.datePickerIconStyle}
                            onClick={() => { this.outageStartDateOpen.current.setOpen(true) }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} className={classes.dateFieldBlock}>
                          {outageEndDate && <span className={classes.placeHolderStyle}>Outage End Date</span>}
                          <DatePicker
                            placeholderText="Outage End Date (optional)"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            onChange={(value) => this.handleDateChange("outageEndDate", value)}
                            selected={outageEndDate}
                            minDate={(outageStartDate) ? addMinutes(new Date(outageStartDate), 10) : new Date()}
                            ref={this.outageEndDateOpen}
                          />
                          <DateRange className={classes.datePickerIconStyle}
                            onClick={() => { this.outageEndDateOpen.current.setOpen(true) }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Typography className={classes.contentTitle}>Basics</Typography>
                        <Typography variant="caption" className={classes.captionText}>Separate multiple names with a comma.</Typography>
                        <ChipInput
                          label="Product Director"
                          data-cy="tag-chip-input"
                          value={[...productDirector]}
                          onAdd={chip => this.handleAddChip(chip, 'productDirector')}
                          onDelete={chip => this.handleDeleteChip(chip, 'productDirector')}
                          variant="outlined"
                          disabled={configData.support}
                          className={classes.textField}
                          newChipKeyCodes={keyCode}
                        />
                        <ChipInput
                          label="Product Owner"
                          data-cy="tag-chip-input"
                          value={[...productOwner]}
                          onAdd={chip => this.handleAddChip(chip, 'productOwner')}
                          onDelete={chip => this.handleDeleteChip(chip, 'productOwner')}
                          variant="outlined"
                          disabled={configData.support}
                          className={classes.textField}
                          newChipKeyCodes={keyCode}
                        />
                        <ChipInput
                          label="Scrum Master"
                          data-cy="tag-chip-input"
                          value={[...scrumMaster]}
                          onAdd={chip => this.handleAddChip(chip, 'scrumMaster')}
                          onDelete={chip => this.handleDeleteChip(chip, 'scrumMaster')}
                          variant="outlined"
                          disabled={configData.support}
                          className={classes.textField}
                          newChipKeyCodes={keyCode}
                        />
                        <ChipInput
                          label="Technical Contact"
                          data-cy="tag-chip-input"
                          value={[...technicalContacts]}
                          onAdd={chip => this.handleAddChip(chip, 'technicalContacts')}
                          onDelete={chip => this.handleDeleteChip(chip, 'technicalContacts')}
                          variant="outlined"
                          disabled={configData.support}
                          className={classes.textField}
                          newChipKeyCodes={keyCode}
                        />
                        <TextField
                          id="notes"
                          type="text"
                          label="Notes"
                          multiline={true}
                          inputProps={{
                            maxLength: 255,
                          }}
                          rows="5"
                          value={notes || ''}
                          onChange={this.handleChangeField('notes')}
                          variant="outlined"
                          disabled={configData.support}
                          className={classes.textField}
                          style={{ marginTop: 20 }}
                        // required
                        />
                        <Typography variant="caption" className={classes.captionText}>Up to 255 characters allowed.</Typography>
                      </Grid>
                    </Grid>
                    {isEdit &&
                      <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 20 }}>
                        <Divider />
                        <Typography variant="caption" className={classes.captionText} style={{ marginTop: 10 }}>
                          <a className={classes.linkArchive} href="#" onClick={this.handleArchive}>
                            {txtArchive}
                          </a>
                        </Typography>
                      </Grid>
                    }
                    <Grid item lg={4} md={4} sm={12} xs={12} className={classes.saveContainer}>
                      <Grid container className={classes.dateContainer}>
                        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.dateFieldBlock}>
                          {publishStartDate && <span className={classes.placeHolderStyle}>Publish Start Date *</span>}
                          <DatePicker placeholderText="Publish Start Date *"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            onChange={(value) => this.handleDateChange("publishStartDate", value)}
                            selected={publishStartDate}
                            disabled={configData.support}
                            minDate={new Date()}
                            ref={this.publishStartDateOpen}
                            variant="outlined"
                            required
                          />
                          <DateRange className={classes.datePickerIconStyle}
                            onClick={() => { this.publishStartDateOpen.current.setOpen(true) }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.dateFieldBlock}>
                          {publishEndDate && <span className={classes.placeHolderStyle}>Publish End Date</span>}
                          <DatePicker placeholderText="Publish End Date (optional)"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            disabled={configData.support}
                            onChange={(value) => this.handleDateChange("publishEndDate", value)}
                            selected={publishEndDate}
                            minDate={addHours(new Date(publishStartDate), 1)}
                            ref={this.publishEndDateOpen}
                          />
                          <DateRange className={classes.datePickerIconStyle}
                            onClick={() => { this.publishEndDateOpen.current.setOpen(true) }}
                          />
                        </Grid>
                      </Grid>
                      {/* <div> */}
                        {/* <FormLabel component="legend" style={{ paddingTop: 10 }}>Published </FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={published}
                              disabled={configData.support}
                              onChange={this.handleChangeChecked('published')}
                              value="active"
                            />
                          }
                          label="Active"
                        /> */}
                        {/* <FormLabel component="legend">Outage</FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={outage}
                              disabled={configData.support}
                              onChange={this.handleChangeChecked('outage')}
                              value="online"
                            />
                          }
                          label="Online"
                        /> */}
                        {/* <Typography variant="caption" className={classes.captionText}>
                          The application will not be displayed, if Published is inactive and Outage is offline.
                        </Typography> */}
                      {/* </div> */}
                      {/*<Typography variant="caption" className={classes.captionText}>Revisions: {'9'}</Typography>
                       <Typography variant="caption" className={classes.captionText}>
                      <a className={classes.linkStyle} href="#">View Past Versions</a></Typography>
                      */}
                      <Typography variant="caption" className={classes.alertText}>
                        <Info />
                        <span className={classes.publishContentFinal}>All required fields must have values for app to published.</span>
                      </Typography>
                      <Button
                        classes={{ root: classes.saveButtonStyle, disabled: classes.buttonDisabled }}
                        disabled={this.enableButton()}
                        onClick={this.onSave('add')}>
                        Save
                        {this.props.appsAndReports && this.props.appsAndReports.loading && this.props.appsAndReports.loading.postAppLoading ?
                          <CircularProgress size={24} className={classes.buttonProgress} /> : ''}
                      </Button>
                    </Grid>
                  </React.Fragment>
                  : <Grid container spacing={24}>
                    <Grid item xs={12}><p className={classes.boxDiv}><Error className={classes.icon} /> There are no Apps / Reports.</p></Grid>
                  </Grid>
            }
          </div>
        </Grid>
        {this.props.appsAndReports.modal.addGrp &&
          <AddGroups open={this.props.appsAndReports.modal.addGrp} onClose={this.closeAddGrp} selectedGrps={grpList} />
        }
        <Modal isOpen={this.state.showArchiveModal || appsAndReports.status} contentStyles={{ maxWidth: '320px' }} closeButton={false} onClose={this.toggleModal}>
          {appsAndReports && !appsAndReports.status &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.content}>
                  Do you want to {txtArchive} "{title}" ?
              </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <div className={classes.buttonContainer}>
                  <Button className={classes.cancelButton} onClick={this.toggleModal}>No</Button>
                  <Button variant="contained" color="primary" onClick={this.onSave(txtArchive)}>Yes</Button>
                </div>
              </Grid>
            </Grid>
          }
          {appsAndReports && appsAndReports.status &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.alertBar}><WarningIcon className={classes.alertIcon} />{appsAndReports.text} </p>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <div className={classes.buttonContainer}>
                  <Button variant="contained" color="primary" onClick={this.focusToNewCat}>
                    OK
                </Button>
                </div>
              </Grid>
            </Grid>
          }
        </Modal>
      </div>
    )
  }
}
EditAppsAndReport.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  categories: PropTypes.object,
  fetchEditApps: PropTypes.func,
  postAppsReports: PropTypes.func,
  fetchCategories: PropTypes.func,
  clearEditApps: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  fetchProperties: PropTypes.object,
  appsAndReports: PropTypes.object,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  postAppsReportsCallError: PropTypes.func.isRequired,
  checkAppsAndReports: PropTypes.func,
  config: PropTypes.object,
}
EditAppsAndReport.contextTypes = {
  router: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
  const { appsAndReports, categories, config } = state
  return {
    appsAndReports: appsAndReports,
    config,
    categories: categories,
    openModal,
    closeModal,
  }
}
// const mapDispatchToProps = dispatch => ({
//   postAppsReportsCallError: data => dispatch(postAppsReportsCallError('', ''))
// })
export default connect(mapStateToProps, { postAppsReportsCallError, fetchEditApps, fetchCategories, openModal, closeModal, clearEditApps, postAppsReports, checkAppsAndReports })(withStyles(styles)(EditAppsAndReport))
