export const GET_SUPPORT_SUCCESS = 'GET_SUPPORT_SUCCESS'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const UPDATE_SUPPORT_SUCCESS = 'UPDATE_SUPPORT_SUCCESS'
export const GET_OPTIONS = 'GET_OPTIONS'
export const UPDATE_SUPPORT_INIT = 'UPDATE_SUPPORT_INIT'
export const UPDATE_SUPPORT_FAIL = 'UPDATE_SUPPORT_FAIL'
export const GET_SUPPORT_FAIL = 'GET_SUPPORT_FAIL'
export const GET_SUPPORT_INIT = 'GET_SUPPORT_INIT'
export const POST_SUPPORT_SUCCESS = 'POST_SUPPORT_SUCCESS'
export const POST_SUPPORT_FAIL = 'POST_SUPPORT_FAIL'
export const POST_SUPPORT_INIT = 'POST_SUPPORT_INIT'
export const DELETE_SUPPORT_INIT = 'DELETE_SUPPORT_INIT'
export const DELETE_SUPPORT_FAIL = 'DELETE_SUPPORT_FAIL'
export const DELETE_SUPPORT_SUCCESS = 'DELETE_SUPPORT_SUCCESS'
export const GET_HELP_CATEGORIES_INIT = 'GET_HELP_CATEGORIES_INIT'
export const GET_HELP_CATEGORIES_SUCCESS = 'GET_HELP_CATEGORIES_SUCCESS'
export const GET_HELP_CATEGORIES_FAIL = 'GET_HELP_CATEGORIES_FAIL'
export const EDIT_HELP_CATEGORIES_INIT = 'EDIT_HELP_CATEGORIES_INIT'
export const EDIT_HELP_CATEGORIES_SUCCESS = 'EDIT_HELP_CATEGORIES_SUCCESS'
export const EDIT_HELP_CATEGORIES_FAIL = 'EDIT_HELP_CATEGORIES_FAIL'
export const DELETE_HELP_CATEGORIES_SUCCESS = 'DELETE_HELP_CATEGORIES_SUCCESS'
