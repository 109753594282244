import {
  APP_CATEGORIES_LIST_API_CALL_INITIATED,
  APP_CATEGORIES_LIST_API_CALL_SUCCESS,
  APP_CATEGORIES_LIST_API_CALL_FAIL,
  POST_APPS_CATEGORIES_API_CALL_FAIL,
  POST_APPS_CATEGORIES_API_CALL_INITIATED,
  POST_APPS_CATEGORIES_API_CALL_SUCCESS,
  CLOSE_MODAL,
  OPEN_MODAL,
  DELETE_APPS_CATEGORIES_API_CALL_FAIL,
  DELETE_APPS_CATEGORIES_API_CALL_INITIATED,
  DELETE_APPS_CATEGORIES_API_CALL_SUCCESS,
  POST_APPS_CATEGORIES_API_CALL_ERROR,
  CHECK_CATEGORIES_SUCCESS,
  CHECK_CATEGORIES_FAIL,
  CHECK_CATEGORIES_INITIATED,
} from './actionType'

export const appCategoriesState = {
  appCategoriesList: [],
  appCategoriesListCount: 0,
  appCategoriesDelete: [],
  loading: {
    appCategoriesListLoading: true,
    appCategoriesDeleteLoading: false,
  },
  modal: {
    editForm: false,
  },
  toast: {
    message: '',
    open: false,
  },
}

export default function appCategories (state = appCategoriesState, action = {}) {
  let loading
  let modal
  switch (action.type) {
    case CHECK_CATEGORIES_INITIATED:
      loading = {
        ...state.loading,
        appCategoryLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case CHECK_CATEGORIES_SUCCESS:
      loading = {
        ...state.loading,
        appCategoryLoading: false,
      }
      return {
        ...state,
        loading,
        appCategoryCheck: action.data,
      }
    case CHECK_CATEGORIES_FAIL:
      loading = {
        ...state.loading,
        appCategoryLoading: false,
      }
      return {
        ...state,
        loading,
        appCategoryCheckError: action.error
      }
    case APP_CATEGORIES_LIST_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appCategoriesListLoading: true,
      }
      return {
        ...state, loading,
      }
    case APP_CATEGORIES_LIST_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appCategoriesListLoading: false,
      }
      let categoriesList = action.data
      let categoriesListCount = action.data.length
      return {
        ...state, loading,
        appCategoriesList: categoriesList,
        appCategoriesListCount: categoriesListCount,
      }
    case APP_CATEGORIES_LIST_API_CALL_FAIL:
      return {
        ...state,
        appCategoriesError: action.error,
        appCategoriesListLoading: false,
      }
    case OPEN_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: true,
      }
      return {
        ...state,
        appCategoriesDelete:[],
        modal,
      }
    case CLOSE_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: false,
      }
      return {
        ...state,
        appCategoriesDelete:[],
        modal,
      }
    /****/
    case POST_APPS_CATEGORIES_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        postAppCategoriesLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case POST_APPS_CATEGORIES_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        postAppCategoriesLoading: false,
      }
      return {
        ...state,
        loading,
        postAppCategoriesSuccess: true,
        redirect : true
      }
    case POST_APPS_CATEGORIES_API_CALL_FAIL:
      loading = {
        ...state.loading,
        postAppCategoriesLoading: false,
      }
      return {
        ...state,
        loading,
        apiError: action.error,
      }
    case POST_APPS_CATEGORIES_API_CALL_ERROR:
      loading = {
        ...state.loading,
        postAppCategoriesLoading: false,
      }
      return {
        ...state,
        loading,
        status: action.status,
        text: action.text,
      }
    
    /****/
    case DELETE_APPS_CATEGORIES_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appCategoriesDeleteLoading: true,
      }
      return {
        ...state, loading,
      }
    case DELETE_APPS_CATEGORIES_API_CALL_FAIL:
      loading = {
        ...state.loading,
        appCategoriesDeleteLoading: false,
      }
      return {
        ...state,
        loading,
        apiError: action.error,
      }
    case DELETE_APPS_CATEGORIES_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appCategoriesDeleteLoading: false,
      }
      let categoriesDeleteData = action.data
      return {
        ...state, loading,
        appCategoriesDelete: categoriesDeleteData,
      }
    /***/
    default:
      return state
  }
}
