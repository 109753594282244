// import { createStore, applyMiddleware } from 'redux'
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
// import rootReducer from './index'
//
// export const middleware = []
//
// if (process.env.NODE_ENV !== 'production') {
//   middleware.push(reduxImmutableStateInvariant())
// }
//
// export default function configureStore() {
//   return createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(...middleware))
//   )
// }

import { createStore, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reduxThunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'

import rootReducer from './index'

export const middleware = [
  reduxThunk,
  promiseMiddleware(),
]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant())
}

export default function configureStore () {
  return createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(...middleware)
    ))
}
