import { reactCellRendererFactory } from 'ag-grid-react'
import Moment from 'moment-timezone'
import { displayTzFormat } from '../../Utills/dateHelper'

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: '#',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
        suppressMenu: true,
        pinned: true
      },
      {
        headerName: "Archive",
        field: "archive",
        width: 120,
        hide: true,
      },
      {
        headerName: "App Name",
        field: "title",
        pinned: true,
        sort: "asc",
        comparator: stringComparator,
        width: 200,
        cellRenderer: (params) => {
          let url = '/apps/edit/' + params.data.id
          let link = document.createElement('a')
          link.href = url
          link.innerText = params.value
          link.addEventListener('click', (e) => {
            e.preventDefault()
            handleClick(params)
          })
          return link
        }
      },
      {
        headerName: "Acronym",
        field: "display_name",
        width: 120,
        comparator: stringComparator
      },
      {
        headerName: "Description ",
        field: "description",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Categories",
        field: "category_admin_vo_list",
        valueGetter: getCategories,
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
        getQuickFilterText: () => '',
      },
      {
        headerName: "URL",
        field: "url",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
        getQuickFilterText: () => '',
        cellRenderer: (params) => {
          let link = document.createElement('a')
          link.href = params.value
          link.innerText = params.value
          link.target = "_blank"
          return link
        }
      },
      {
        headerName: "User Group (IDM)",
        field: "user_access_admin_user_group",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
        width: 300,
        valueGetter: getUser,
        cellRenderer: function(params){
          let row = document.createElement('div')
          row.innerHTML = params.value
          return row
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "User Group (DB)",
        field: "user_access_admin_user_group_db",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
        width: 300,
        valueGetter: getUserDB,
        cellRenderer: function(params){
          let row = document.createElement('div')
          row.innerHTML = params.value
          return row
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "Partner Type",
        field: "user_access_admin_partner_type",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
        width: 500,
        valueGetter: getPartnerTypes,
        cellRenderer: function(params){
          return params.value
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "Partner Type Export",
        field: "user_access_admin_partner_type_export",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
        width: 500,
        hide: true,
        valueGetter: getPartnerTypesExport,
        cellRenderer: function(params){
          return params.value
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "3rd Party",
        field: "external_app",
        width: 120,
        valueGetter: function (params) {
          return params.data.external_app === 'Y' ? 'Yes' : 'No'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "App/Report",
        field: "app_or_report",
        width: 130,
        valueGetter: function (params) {
          return params.data.app_or_report === 'A' ? 'App' : 'Report'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "Published",
        field: "published",
        width: 120,
        valueGetter: function (params) {
          return params.data.published === 'active' ? 'Yes' : 'No'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "Publish Start",
        field: "publishing_start_date",
        width: 145,
        valueGetter: function (params) {
          return displayTzFormat(params.data.publishing_start_date)
        },
        getQuickFilterText: () => '',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator
        }
      },
      {
        headerName: "Publish End",
        field: "publishing_end_date",
        width: 145,
        valueGetter: function (params) {
          return displayTzFormat(params.data.publishing_end_date)
        },
        getQuickFilterText: () => '',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator
        }
      },
      {
        headerName: "Outage",
        field: "outage",
        width: 110,
        valueGetter: function (params) {
          return params.data.outage === 'online' ? 'No' : 'Yes'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: "Outage Start",
        field: "outage_start_date",
        width: 145,
        valueGetter: function (params) {
          return displayTzFormat(params.data.outage_start_date)
        },
        getQuickFilterText: () => '',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator
        }
      },
      {
        headerName: "Outage End",
        field: "outage_end_date",
        width: 145,
        valueGetter: function (params) {
          return displayTzFormat(params.data.outage_end_date)
        },
        getQuickFilterText: () => '',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator
        }
      },
      {
        headerName: "Product Director",
        field: "product_director",
        // width: 145,
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Product Owner",
        field: "product_owner",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        // width: 145,
        cellClass: "cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Scrum Master",
        field: "scrum_master",
        width: 145,
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Technical Contact",
        field: "technical_contacts",
        // width: 145,
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        autoHeight: true,
      },
      {
        headerName: "Notes",
        field: "notes",
        cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
        cellClass: "cell-wrap-text",
        cellRenderer: (params) => {
          if (params.data.notes !== null){
            return params.data.notes.replace(/\r\n|\r|\n/igm,'<br/>')
          }
          return params.data.notes
        },
        autoHeight: true,
        getQuickFilterText: () => '',
      },
    ]
  }
  onBtExport() {
    let checked = this.state && this.state.enableEditCat ? false : true;
    let params = {
      skipHeader: false,
      columnGroups: getBooleanValue("#columnGroups"),
      skipFooters: getBooleanValue("#skipFooters"),
      skipGroups: getBooleanValue("#skipGroups"),
      skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      allColumns: getBooleanValue("#allColumns"),
      onlySelected: checked,
      fileName: 'Pol | AppsAndReport',
      sheetName: 'apps',
      exportMode: true,
      columnKeys: ['title', 'display_name', 'description',
        'category_admin_vo_list', 'url', 'user_acess_admin_vo_list', 'archive',
        'iag_b2b_prtnr_type_mapping', 'user_access_admin_user_group', 'user_access_admin_partner_type_export', 'external_app', 'app_or_report', 'published',
        'publishing_start_date', 'publishing_end_date', 'outage', 'outage_start_date', 'outage_end_date',
        'product_director', 'product_owner', 'scrum_master', 'technical_contacts', 'notes'
      ]
    }

    this.gridApi.exportDataAsCsv(params)
  }
}

// this is a simple cell renderer, putting together static html, no
// need to use React for it.
function getCategories(params) {
  let cat = params.data.category_admin_vo_list
  let result = cat.map(function (val) {
    return val.app_group_name
  }).join(', ')
  return result
}
function getUser(params) {
  if(params.data.user_access_admin_provisioning_vos !== null){
    let cat = params.data.user_access_admin_provisioning_vos
    let result = cat.map(function (val) {
      return val.group_name
    }).join('<hr>')
    return result
  }
  return params.data.user_access_admin_provisioning_vos
}
function getUserDB(params) {
  if(params.data.user_acess_admin_vo_list !== null){
    let cat = params.data.user_acess_admin_vo_list
    let result = cat.map(function (val) {
      return val.ldsgroup
    }).join('<hr>')
    return result
  }
  return params.data.user_access_admin_vo_list
}
function getPartnerTypes(params){
  if(params.data.user_access_admin_provisioning_vos !== null){
    let provisioningData = params.data.user_access_admin_provisioning_vos
    let result = provisioningData.map(item => {
      if(item.provisioning !== 'Manual'){
        return item.partner_type_string
      }else{
        return item.provisioning
      }
    }).join('<hr>')
    return result
  }
  return params.data.user_access_admin_provisioning_vos
}
function getPartnerTypesExport(params){
  if(params.data.user_access_admin_provisioning_vos !== null){
    let provisioningData = params.data.user_access_admin_provisioning_vos
    let result = provisioningData.map(item => {
      if(item.provisioning !== 'Manual'){
        return item.partner_type_no_html
      }else{
        return item.provisioning
      }
    }).join('<hr>')
    return result
  }
  return params.data.user_access_admin_provisioning_vos
}

function sortAlphaNum(a, b) {
  let checkAlphabet1 = a.replace(/[^a-zA-Z]/g, "");
  let checkAlphabet2 = b.replace(/[^a-zA-Z]/g, "");
  if (checkAlphabet1 === checkAlphabet2) {
    let base10Number1 = parseInt(a.replace(/[^0-9]/g, ""), 10);
    let base10Number2 = parseInt(b.replace(/[^0-9]/g, ""), 10);
    return base10Number1 === base10Number2 ? 0 : base10Number1 > base10Number2 ? 1 : -1;
  } else {
    return checkAlphabet1 > checkAlphabet2 ? 1 : -1;
  }
}

function getBooleanValue(cssSelector) {
  return true
}

function stringComparator (valueA, valueB){
  if ((valueA === null && valueB === null) || (valueA === undefined && valueB === undefined)) {
    return 0;
  }

  if (valueA === null || valueA === undefined) {
    return -1;
  }
  if (valueB === null || valueB === undefined) {
    return 1;
  }

  let upperValueA = valueA.toUpperCase();
  let upperValueB = valueB.toUpperCase();
  return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
}

function comparator(filterLocalDateAtMidnight, cellValue) {
  let cellDate = Moment(cellValue).format('YYYY-MM-DD')
  let filterLocalDate = Moment(filterLocalDateAtMidnight).format('YYYY-MM-DD')
  if (filterLocalDate == cellDate) {
    return 0
  }
  if (cellDate < filterLocalDate) {
    return -1;
  }
  if (cellDate > filterLocalDate) {
    return 1;
  }
}
