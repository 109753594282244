export const APP_GROUPS_LIST_API_CALL_INITIATED = 'APP_GROUPS_LIST_API_CALL_INITIATED'
export const APP_GROUPS_LIST_API_CALL_SUCCESS = 'APP_GROUPS_LIST_API_CALL_SUCCESS'
export const APP_GROUPS_LIST_API_CALL_FAIL = 'APP_GROUPS_LIST_API_CALL_FAIL'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const POST_APPS_GROUPS_API_CALL_FAIL = 'POST_APPS_GROUPS_API_CALL_FAIL'
export const POST_APPS_GROUPS_API_CALL_INITIATED = 'POST_APPS_GROUPS_API_CALL_INITIATED'
export const POST_APPS_GROUPS_API_CALL_SUCCESS = 'POST_APPS_GROUPS_API_CALL_SUCCESS'
export const POST_APPS_GROUPS_API_CALL_ERROR = 'POST_APPS_GROUPS_API_CALL_ERROR'
export const DELETE_APPS_GROUPS_API_CALL_FAIL = 'DELETE_APPS_GROUPS_API_CALL_FAIL'
export const DELETE_APPS_GROUPS_API_CALL_INITIATED = 'DELETE_APPS_GROUPS_API_CALL_INITIATED'
export const DELETE_APPS_GROUPS_API_CALL_SUCCESS = 'DELETE_APPS_GROUPS_API_CALL_SUCCESS'
export const CHECK_GROUPS_INITIATED = 'CHECK_GROUPS_INITIATED'
export const CHECK_GROUPS_SUCCESS = 'CHECK_GROUPS_SUCCESS'
export const CHECK_GROUPS_FAIL = 'CHECK_GROUPS_FAIL'
