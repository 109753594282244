import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, TextField, Typography, withStyles, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core'
import Moment from 'moment-timezone'

const styles = theme => ({
    buttonProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    saveButtonStyle: {
        background: '#0049DB',
        color: '#fff',
        '&:hover': {
          backgroundColor: "#0762db",
        },
    },
    container: {
        marginBottom: 20,
    },
})

class EditContentFeedbackForm extends Component {
    constructor(props) {
        super(props)
        const {content} = props && props.contents
        this.state = {
            author_comment: content && content.author_comment,
            baa_id: content && content.baa_id,
            browser_url: content && content.browser_url,
            comments: content && content.comments,
            crte_ts: content && content.crte_ts,
            document_version: content && content.document_version,
            feed_back_id: content && content.feed_back_id,
            feedback_rating: content && content.feedback_rating,
            item_id: content && content.item_id,
            resolved: content && content.resolved ? true : false,
            site_type: content && content.site_type,
            updt_ts: content && content.updt_ts
        }
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.contents && nextProps.contents !== this.props.contents) {
            const {content} = nextProps && nextProps.contents
            this.setState({
                baa_id: content && content.baa_id,
                browser_url: content && content.browser_url,
                comments: content && content.comments,
                crte_ts: content && content.crte_ts,
                document_version: content && content.document_version,
                feed_back_id: content && content.feed_back_id,
                feedback_rating: content && content.feedback_rating,
                item_id: content && content.item_id,
                resolved: content && content.resolved ? true : false,
                site_type: content && content.site_type,
                updt_ts: content && content.updt_ts,
                author_comment: content && content.author_comment
            })
        }
    }

    onSave = () => {
        this.props.editContentFeedback(this.state)
    }
    
    getDate = timestamp => Moment(timestamp).format('YYYY-MM-DDThh:mm:ssZ')

    editRadio = e => {
        if (e.target.value === 'true') {
            this.setState({resolved: true})
        } else {
            this.setState({ resolved: false })
        }
    }

    render() {
        const {contents, classes, configData} = this.props
        const {content} = contents
        return (
            <React.Fragment>
                {contents.loading ? <CircularProgress size={50} className={classes.buttonProgress} /> :
                    contents.apiError ?
                    <Grid container spacing={24}>
                        <Grid item xs={12} className={classes.boxDiv}>
                            <p>An error occurred while retrieving Apps/Reports data. Please contact support if this issue persists.</p>
                        </Grid>
                    </Grid> :
                    <React.Fragment>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>BAA_ID</Typography>
                                <TextField variant="outlined" value={content && content.baa_id} disabled />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>ITEM ID</Typography>
                                <TextField variant="outlined" value={content && content.item_id} disabled />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>FEEDBACK COMMENTS</Typography>
                                <TextField variant="outlined" value={content && content.comments} disabled />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>CREATED DATE</Typography>
                                <TextField variant="outlined" value={content && this.getDate(content.crte_ts)} disabled />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>FEEDBACK ID</Typography>
                                <TextField variant="outlined" value={content && content.feed_back_id} disabled />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>UPDATE DATE</Typography>
                                <TextField variant="outlined" value={content && this.getDate(content.updt_ts)} disabled />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>Author Comments</Typography>
                                <TextField variant="outlined" variant="outlined" value={this.state.author_comment} onChange={e => this.setState({author_comment: e.target.value})} />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography>Resolved</Typography>
                                <RadioGroup
                                    aria-label="Resolved"
                                    name="Resolved"
                                    className={classes.radioGroup}
                                    value={this.state.resolved ? "true" : "false"}
                                    onChange={this.editRadio}>
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="primary" />}
                                        label="True"
                                        labelPlacement="start"
                                        style={{ marginLeft: 0 }}
                                        disabled={configData.support}
                                        className={classes.radioLabel}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary" />}
                                        label="False"
                                        labelPlacement="start"
                                        disabled={configData.support}
                                        className={classes.radioLabel}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Button
                                color="primary"
                                variant="contained"
                                classes={classes.saveButtonStyle}
                                onClick={this.onSave}>
                                Save
                            </Button>
                        </Grid>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

EditContentFeedbackForm.propTypes = {
    classes: PropTypes.object.isRequired,
    contents: PropTypes.object.isRequired,
    configData: PropTypes.object,
    editContentFeedback: PropTypes.func,
}

export default (withStyles(styles)(EditContentFeedbackForm))
