import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import { withStyles } from '@material-ui/core/styles'
import Download from '@material-ui/icons/CloudDownloadRounded'
import { Error } from '@material-ui/icons'
import { Grid, CircularProgress, Button } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import GridConfig from './GridConfig'
import HeaderTitle from "../../components/Header/HeaderTitle"
import { fetchContent } from './../../store/contentFeedback/actionCreator'
import {fetchAuditLogs} from './../../store/auditLogs/actionCreator'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '99%',
  },
  container: {
    marginTop: 10,
  },
  button: {
    marginLeft: 20,
    marginBottom: 12,
  },
  search: {
    width: '60%',
    float: 'right',
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 24,
  },
  input: {
    width: 'calc(100% - 70px)',
    border: 0,
  },
  tableSize: {
    height: "calc(100vh - 130px)",
    width: "100%"
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  showArchive: {
    display: 'inline',
    marginLeft: 50,
  },
  showArchiveLabel: {
    color: '#757575',
    fontWeight: 700,
  },
  colorSwitchBase: {
    color: '#0049db',
    '&$colorChecked': {
      color: '#0049db',
      '& + $colorBar': {
        backgroundColor: '#0049db',
      },
    },
  },
  colorBar: {},
  colorChecked: {}
})

const PAGINATION_SIZE = 15

class AuditLogs extends Component {
  constructor(props) {
    super(props)
    this.gConfig = new GridConfig()
    this.state = {
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
        filter: true,
        autoHeight: true,
        // width: 100
      },
      rowData: [],
      enableFilter: true,
      pagination: true,
      paginationPageSize: PAGINATION_SIZE,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting: true,
      // /** For application **/
      enableEditCat: true,
      // selectedRowsCat: '',
      // selectedRowsId: '',
      // selectedRowsApps: '',
      // openModal: false,
      // showArchive: false,
      // readOnly: false,
      /** For application **/
    }
    this.gridOptions = {
      columnDefs: this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      onGridReady: this.onGridReady,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      onRowSelected: this.rowSelected,
      onSelectionChanged: this.onSelectionChanged.bind(this),
      onRowClicked: this.RowClickEventHandler,
      sideBar: true,
      onColumnResized: this.onColumnResized,
    }
    this.handleEditCat = this.handleEditCat.bind(this)
    this.closeEditCat = this.closeEditCat.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.auditLogs.result && nextProps.auditLogs.result !== this.props.auditLogs.result){
      this.setState({ rowData: nextProps.auditLogs.result })
    }
    if (nextProps.config.data && nextProps.config.data.admin !== this.props.config.data.admin) {
      this.setState({readOnly: nextProps.config.data.admin && JSON.parse(nextProps.config.data.admin)})
    }
  }
  componentWillMount() {
    this.props.fetchAuditLogs()
  }
  componentDidMount() {
    if(this.props.auditLogs && this.props.auditLogs.result){
      this.setState({ rowData: this.props.auditLogs.result })
    }
  }
  onColumnResized = (params) => {
    params.api.resetRowHeights();
  }
  onGridReady = params => {
    this.gridApi = params.api
  }
  handleClick = params => {
    let url = '/apps/edit/' + params.data.id
    // this.props.history.push(url)
  }
  handleEditCat = params => {
    // this.props.openModal('editForm')
  }
  closeEditCat() {
    // this.props.closeModal('editForm')
  }
  rowSelected(event) {
    // console.log(event)
    // return document.querySelector(cssSelector).checked === true
  }
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(document.getElementById("quickFilter").value)
  }
  onSelectionChanged(event) {
    this.setState({ enableEditCat: !event.api.getSelectedNodes().length >= 1 })
    let selectedRows = this.gridApi.getSelectedRows()
    let items = []
    selectedRows.forEach(function (selectedRow, index) {
      items.push(selectedRow)
    })
    this.setState({ selectedRowsApps: items })
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, function () {
      this.gridOptions.api.onFilterChanged()
      this.externalFilterChanged(name)
    })

  }
  externalFilterChanged() {
    this.gridOptions.api.onFilterChanged()
  }
  doesExternalFilterPass = node => {
    let status = node.data.archive === this.state.showArchive
    return status
  }
  isExternalFilterPresent() {
    return this.state.showArchive != 'false'
  }

  render() {
    const { classes, auditLogs } = this.props
    const { readOnly } = this.state
    return (
      <div className={classes.root}>
        <HeaderTitle title="Audit Logs" />
        <Helmet>
          <title>Audit Logs</title>
        </Helmet>
        <Button disabled={readOnly} variant="contained" color="primary" className={classes.button} onClick={this.gConfig.onBtExport.bind(this)}>
          <Download className={classes.buttonIcon} />
            Export
        </Button>
        {auditLogs.loading ? <CircularProgress size={50} className={classes.buttonProgress} />
          :!auditLogs.success ? <Grid container spacing={24} ><Grid item xs={12} className={classes.boxDiv}><p>An error occurred while retrieving Audit Logs. Please contact support if this issue persists.</p></Grid></Grid>
            : auditLogs.result ? <div className={[classes.tableSize, 'ag-theme-material'].join(' ')}>
              <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
            </div>
              :<Grid container spacing={24}><Grid item xs={12}><p className={classes.boxDiv}><Error className={classes.icon} /> There are no Audit Logs.</p></Grid></Grid>
        }
      </div>
    )
  }
}

AuditLogs.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchAuditLogs: PropTypes.func,
  auditLogs: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
  const { config, auditLogs } = state
  return {
    config,
    auditLogs,
  }
}

export default connect(mapStateToProps, {fetchContent, fetchAuditLogs})(withStyles(styles)(AuditLogs))
