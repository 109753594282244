import React from 'react'
import { object, string, func } from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import { appBarStyles } from 'react-praxis-components/SecondaryNav'
import { Link } from 'nicollet-react'
import apiConfig from '../../config/apiConfig'

export function Header({ classes, title, menuAction }) {
  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {menuAction && (
            <IconButton
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          <div className={classes.menuButton}>
            Open{' '}
            <Link
              href={apiConfig.hostUrl}
              target="_blank"
              color="onDark"
              underline="invert"
            >
              Partners Online
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.displayName = 'Header'

Header.propTypes = {
  classes: object,
  title: string.isRequired,
  menuAction: func,
}

Header.defaultProps = {
  classes: {},
}

const styles = theme => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  appBar: {
    ...appBarStyles,
  },
  logo: {
    height: 40,
    margin: '0 18px 0 6px',
    width: 35,
  },
  h1: {
    fontSize: 24,
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  buttonIcon: {
    color: theme.palette.primary.contrastText,
  },
  menuButton: {
    right: 20,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
})

export default withStyles(styles)(Header)
