import Moment from 'moment-timezone'

export const formatDate = function (date = '', delimiter = '-') {
  if (date === '') {
    return date
  }
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join(delimiter)
}

export const setTzFormat = (dateTime) => {
  let tzDate = ''
  if (dateTime) {
    tzDate = Moment(dateTime).utc('Z').format()
  }
  return tzDate
}
export const displayTzFormat = (dateTime) => {
  let tzDate = ''
  if (dateTime) {
    tzDate = Moment.utc(dateTime).format('YYYY-MM-DD HH:mm') //Moment(dateTime).utc('Z').format("YYYY-MM-DD HH:mm:ss")
  }
  return new Date(tzDate)
}

export const momentHelper = Moment
