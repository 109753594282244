import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Modal } from 'nicollet-react'
import PropTypes from 'prop-types'

class ModalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.open}
          onClose={this.props.onclose}
          closeButton={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ReactQuill
            value={this.props.notes && this.props.notes[this.props.modalName]}
            onChange={this.props.editNotes(this.props.modalName)}
            style={{ height: "20em", width: "100%" }}
            modules={{
              toolbar: this.toolbarOptions
            }}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

ModalContainer.propTypes = {
  open: PropTypes.bool,
  notes: PropTypes.object,
  modalName: PropTypes.string,
  editNotes: PropTypes.func,
  onclose: PropTypes.func,
}

export default ModalContainer
