import axios from 'axios'
import {
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_SUCCESS,
  FETCH_FIELDS_FAILURE,
  UPDATE_FIELDS_REQUEST,
  UPDATE_FIELDS_SUCCESS,
  UPDATE_FIELDS_FAILURE,
  FETCH_SEARCH_DATA_REPORT,
  FETCH_SEARCH_DATA_REPORT_SUCCESS,
  FETCH_SEARCH_DATA_REPORT_FAILURE,
  FETCH_SUMMARY_REPORT,
  FETCH_SUMMARY_REPORT_SUCCESS,
  FETCH_SUMMARY_REPORT_FAILURE,
  CLEAR_SELECTED_FILES
} from './actionType'
import {
  TEXT_SUCCESS_SEARCH_FIELD_UPDATES,
  TEXT_ERROR_DEFAULT,
  TEXT_SUCCESS_REPORT_GENERATED
} from '../../constants/notifications'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'

export const fetchFields = () => dispatch => {
  dispatch({ type: FETCH_FIELDS_REQUEST })
  axios
    .get(`${apiConfig.adminApi.cms}/all_fields`)
    .then(response => {
      const data = response.data
      dispatch({
        type: FETCH_FIELDS_SUCCESS,
        payload: data.result.map(field => ({
          ...field,
          checked: field.flag,
          weight: field.weight || ''
        }))
      })
    })
    .catch(error => {
      dispatch({ type: FETCH_FIELDS_FAILURE, payload: error.message })
    })
}

export const updateFields = changesArray => dispatch => {
  dispatch({ type: UPDATE_FIELDS_REQUEST })
  axios
    .post(`${apiConfig.adminApi.cms}/update_fields`, changesArray)
    .then(response => {
      if (response.status === 200) {
        dispatch({ type: UPDATE_FIELDS_SUCCESS })
        dispatch(showNotification(true, TEXT_SUCCESS_SEARCH_FIELD_UPDATES))
        dispatch(fetchFields())
      }
    })
    .catch(error => {
      dispatch({ type: UPDATE_FIELDS_FAILURE, payload: error.message })
      dispatch(showNotification(true, TEXT_ERROR_DEFAULT))
    })
}

export const getSearchReportData = formData => dispatch => {
  dispatch({ type: FETCH_SEARCH_DATA_REPORT })
  axios
    .post(`${apiConfig.adminApi.cms}/getSearchMatches`, formData)
    .then(response => {
      const binaryString = window.atob(response.data.result)
      const length = binaryString.length
      const bytes = new Uint8Array(length)
      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      const arrayBuffer = bytes.buffer

      const blob = new Blob([arrayBuffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'search_data.xlsx'
      a.click()

      dispatch({ type: FETCH_SEARCH_DATA_REPORT_SUCCESS })
      dispatch({ type: CLEAR_SELECTED_FILES })
      dispatch(showNotification(true, TEXT_SUCCESS_REPORT_GENERATED))
    })
    .catch(error => {
      dispatch({ type: FETCH_SEARCH_DATA_REPORT_FAILURE })
      dispatch(showNotification(true, TEXT_ERROR_DEFAULT))
    })
}

export const getSummaryReportData = formData => dispatch => {
  dispatch({ type: FETCH_SUMMARY_REPORT })
  axios
    .post(`${apiConfig.adminApi.cms}/getSearchSummary`, formData)
    .then(response => {
      const binaryString = window.atob(response.data.result)
      const length = binaryString.length
      const bytes = new Uint8Array(length)
      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      const arrayBuffer = bytes.buffer

      const blob = new Blob([arrayBuffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'summary_data.xlsx'
      a.click()
      dispatch({ type: FETCH_SUMMARY_REPORT_SUCCESS })
      dispatch(showNotification(true, TEXT_SUCCESS_REPORT_GENERATED))
    })
    .catch(error => {
      dispatch({ type: FETCH_SUMMARY_REPORT_FAILURE })
      dispatch(showNotification(true, TEXT_ERROR_DEFAULT))
    })
}
