import {
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_SUCCESS,
  FETCH_FIELDS_FAILURE,
  UPDATE_FIELDS_REQUEST,
  UPDATE_FIELDS_SUCCESS,
  UPDATE_FIELDS_FAILURE,
  FETCH_SEARCH_DATA_REPORT,
  FETCH_SEARCH_DATA_REPORT_SUCCESS,
  FETCH_SEARCH_DATA_REPORT_FAILURE,
  FETCH_SUMMARY_REPORT,
  FETCH_SUMMARY_REPORT_SUCCESS,
  FETCH_SUMMARY_REPORT_FAILURE,
  CLEAR_SELECTED_FILES
} from './actionType'

const initialState = {
  fields: [],
  loading: false,
  uploading: false,
  error: null,
  loading: {
    searchFieldsLoading: true
  },
  formData: new FormData()
}

const fieldReducer = (state = initialState, action) => {
  let loading
  switch (action.type) {
    case FETCH_FIELDS_REQUEST:
    case UPDATE_FIELDS_REQUEST:
      loading = {
        ...state.loading,
        searchFieldsLoading: true
      }
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_FIELDS_SUCCESS:
      loading = {
        ...state.loading,
        searchFieldsLoading: false
      }
      return {
        ...state,
        fields: action.payload,
        loading: false,
        error: null
      }
    case UPDATE_FIELDS_SUCCESS:
      loading = {
        ...state.loading,
        searchFieldsLoading: false
      }
      return {
        ...state,
        loading: false,
        error: null
      }
    case FETCH_FIELDS_FAILURE:
    case UPDATE_FIELDS_FAILURE:
      loading = {
        ...state.loading,
        searchFieldsLoading: false
      }
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case FETCH_SEARCH_DATA_REPORT:
      return {
        ...state,
        uploading: true,
        error: null
      }
    case FETCH_SEARCH_DATA_REPORT_SUCCESS:
      return {
        ...state,
        uploading: false,
        error: null
      }
    case FETCH_SEARCH_DATA_REPORT_FAILURE:
      return {
        ...state,
        uploading: false,
        error: 'Error generating report.'
      }
    case FETCH_SUMMARY_REPORT:
      return {
        ...state,
        uploading: true,
        error: null
      }
    case FETCH_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        uploading: false,
        error: null,
        formData: new FormData()
      }
    case FETCH_SUMMARY_REPORT_FAILURE:
      return {
        ...state,
        uploading: false,
        error: 'Error generating report.'
      }
    default:
      return state
  }
}

export default fieldReducer
