import apiConfig from "../../config/apiConfig";

const data = []

for(let i=0;i<Object.keys(apiConfig.sharePoint).length;i++){
  data.push({
    "POLSiteName": Object.keys(apiConfig.sharePoint)[i],
    "POLSiteUrl": Object.values(apiConfig.sharePoint)[i]
  })
}
  
export default data
