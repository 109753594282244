import axios from 'axios'
import { AUDIT_LOG_INIT, AUDIT_LOG_SUCCESS, AUDIT_LOG_FAIL } from './actionType'
import apiConfig from '../../config/apiConfig'

export function fetchAuditLogs () {
    return function (dispatch) {
        dispatch(auditCallInit())
        return axios.get(`${apiConfig.adminApi.host}audit_log`)
          .then(response => {
            dispatch(auditCallSuccess(response.data))
          })
          .catch((error) => {
            dispatch(auditCallFail(error))
          })
      }
}

export function auditCallInit (data) {
    return {
        type: AUDIT_LOG_INIT,
        data
    }
}

export function auditCallSuccess (data) {
    return {
        type: AUDIT_LOG_SUCCESS,
        data
    }
}

export function auditCallFail (error) {
    return {
        type: AUDIT_LOG_FAIL,
        error
    }
}
