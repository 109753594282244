import { reactCellRendererFactory } from "ag-grid-react";

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: "#",
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        suppressSorting: true,
        suppressMenu: true,
        pinned: true
      },
      {
        headerName: "Archive",
        field: "archive",
        width: 120,
        hide: true,
      },
      {
        headerName: "Reasons For Support",
        field: "reason_for_support",
        pinned: true,
        width: 300,
        sort: "asc",
        cellRenderer: params => {
          var link = document.createElement("a");
          link.href = "/reason";
          link.innerText = params.value;
          link.addEventListener("click", e => {
            e.preventDefault();
            handleClick(params);
          });
          return link;
        },
      },
      {
        headerName: "ID",
        field: "id",
        width: 100,
      },
      {
        headerName: "Contact Details",
        field: "es_contact_us_contents",
        width: 180,
        // valueGetter: 
        cellRenderer: params => {
          let contact_details = params.data.es_contact_us_contents
          if (contact_details && contact_details.length > 0) {
            return contact_details.map(item => item.phone).join('/ ')
          } else {
            return null
          }
        }
      },
      {
        headerName: "Comments",
        field: "comments",
        width: 600,
        resizable: true,
        cellRenderer: params => `<div>${params.value}</div>`
      },
      {
        headerName: "Email",
        field: "email",
        width: 250,
      },
      {
        headerName: "Published",
        field: "published",
        width: 110,
        getQuickFilterText: () => '',
      },
      {
        headerName: "Publish Start",
        field: "start_date",
        width: 130,
        getQuickFilterText: () => '',
      },
      {
        headerName: "Publish Update",
        field: "update_date",
        width: 130,
        getQuickFilterText: () => '',
      },
      {
        headerName: "Destination System Name",
        field: "destination_system_name",
        width: 200,
      },
      {
        headerName: "Paths",
        field: "path",
        width: 90,
      },
    ];
  }
  onBtExport(){
    let checked = this.state && this.state.enableEditCat ? false : true;
    let params = {
      skipHeader: false,
      columnGroups: getBooleanValue("#columnGroups"),
      skipFooters: getBooleanValue("#skipFooters"),
      skipGroups: getBooleanValue("#skipGroups"),
      skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      allColumns: getBooleanValue("#allColumns"),
      onlySelected: checked,
      fileName: 'Pol | ReasonForSupport',
      sheetName: 'Reason_for_support',
      exportMode: true,
      columnKeys: ['reason_for_support','id','es_contact_us_contents','comments','email','published','start_date','update_date','destination_system_name','path']
    }
    this.gridApi.exportDataAsCsv(params)
  }
}
function getBooleanValue(cssSelector) {
  return true
}
