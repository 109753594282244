import { createMuiTheme } from '@material-ui/core/styles'

export const praxis = {
  light: '#f05545',
  main: '#930000',
  dark: '#1a6ec5',
  contrastText: '#FFF',
  lightGrey: '#8d8d8d',
  lighterGrey: '#cbcbcb',
  lightestGrey: '#c5c5c5',
  mediumGrey: '#66666',
  darkGrey: '#333',
  lightBlue: '#1a6ec5',
  lighterBlue: '#5471d2',
  darkBlue: '#0d469f',
  darkerBlue: '#002171',
  sideNavHover: 'rgba(158,158,158,.2)',
  lighterYellow: '#FFFFB0',
  lightYellow: '#FFE57F',
  darkYellow: '#CAB350',
  linkColor: '#0b50dc',
  buttonColor: '#0049db',
}
const praxisTheme = createMuiTheme({
  palette: {
    primary: praxis,
    secondary: praxis,
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiLink: {
      root: {
        "color": praxis.linkColor,
      },
    },
    MuiButton:{
      containedPrimary:{
        backgroundColor: praxis.buttonColor,
        '&:hover': {
          cursor: "pointer",
        },
      },
    },
    MuiFormControl:{
      root: {
        width: '100%',
      }
    },
    MuiRadio:{
      colorPrimary:{
        '&$checked': {
          color: "#366CD9",
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after, &:before': {
          borderBottom: 0,
        },
      },
      multiline:{
        padding: '5px 10px',
      }
    },
    MuiInputBase:{
      root: {
        // border: `1px solid ${praxis.lighterGrey}`,
        color: '#666666',
        padding: '5px 10px',
      }
    },
    MuiOutlinedInput:{
      root: {
        '&$focused $notchedOutline': {
            borderColor: '#366CD9',
            borderWidth: 2,
        },
      },
      input:{
        padding: 5,
      },
    },
    MuiInputLabel: {
      root: {
        color: praxis.lightestGrey,
        // marginLeft: 10,
        // top: '7px !important',
        '&$focused': {
          color: praxis.lightestGrey,
        },
      },
      outlined:{
        lineHeight:0
      },
    },
    },
  },
)
export default praxisTheme
