import {
  APP_GROUPS_LIST_API_CALL_INITIATED,
  APP_GROUPS_LIST_API_CALL_SUCCESS,
  APP_GROUPS_LIST_API_CALL_FAIL,
  POST_APPS_GROUPS_API_CALL_FAIL,
  POST_APPS_GROUPS_API_CALL_INITIATED,
  POST_APPS_GROUPS_API_CALL_SUCCESS,
  CLOSE_MODAL,
  OPEN_MODAL,
  DELETE_APPS_GROUPS_API_CALL_INITIATED,
  DELETE_APPS_GROUPS_API_CALL_FAIL,
  DELETE_APPS_GROUPS_API_CALL_SUCCESS,
  POST_APPS_GROUPS_API_CALL_ERROR,
  CHECK_GROUPS_INITIATED,
  CHECK_GROUPS_FAIL,
  CHECK_GROUPS_SUCCESS,
} from './actionType'

export const appGroupsState = {
  appGroupsList: [],
  appGroupsDelete: [],
  appGroupsListCount: 0,
  loading: {
    appGroupsListLoading: true,
    appGroupsDeleteLoading: false,
  },
  modal: {
    editForm: false,
  },
}

export default function appGroups (state = appGroupsState, action = {}) {
  let loading
  let modal
  switch (action.type) {
    case CHECK_GROUPS_INITIATED:
      loading = {
        ...state.loading,
        appGroupLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case CHECK_GROUPS_SUCCESS:
      loading = {
        ...state.loading,
        appGroupLoading: false,
      }
      return {
        ...state,
        loading,
        appGroupCheck: action.data,
      }
    case CHECK_GROUPS_FAIL:
      loading = {
        ...state.loading,
        appGroupLoading: false,
      }
      return {
        ...state,
        loading,
        appGroupCheckError: action.error
      }
    case APP_GROUPS_LIST_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appGroupsListLoading: true,
      }
      return {
        ...state, loading,
      }
    case APP_GROUPS_LIST_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appGroupsListLoading: false,
      }
      let appGroupsList = action.data
      let appGroupsListCount = action.data.length
      return {
        ...state, loading,
        appGroupsList: appGroupsList,
        appGroupsListCount: appGroupsListCount,
      }
    case APP_GROUPS_LIST_API_CALL_FAIL:
      return {
        ...state,
        appGroupsError: action.error,
        appGroupsListLoading: false,
      }
    case OPEN_MODAL:
      loading = {
        ...state.loading,
        appGroupsListLoading: false,
      }
      modal = {
        ...state.modal,
        [action.modalName]: true,
      }
      return {
        ...state,
        appGroupsDelete: [],
        modal,
      }
    case CLOSE_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: false,
      }
      return {
        ...state,
        appGroupsDelete: [],
        modal,
      }
    /****/
    case POST_APPS_GROUPS_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appGroupsListLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case POST_APPS_GROUPS_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appGroupsListLoading: false,
      }
      return {
        ...state,
        loading,
        postAppCategoriesSuccess: true,
        redirect : true
      }
    case POST_APPS_GROUPS_API_CALL_FAIL:
      loading = {
        ...state.loading,
        appGroupsListLoading: false,
      }
      return {
        ...state,
        loading,
        apiError: action.error,
      }
    case POST_APPS_GROUPS_API_CALL_ERROR:
        loading = {
          ...state.loading,
          appGroupsListLoading: false,
        }
        return {
          ...state,
          loading,
          status: action.status,
          text: action.text,
        }
    /****/
    case DELETE_APPS_GROUPS_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        appGroupsDeleteLoading: true,
      }
      return {
        ...state, loading,
      }
    case DELETE_APPS_GROUPS_API_CALL_FAIL:
      loading = {
        ...state.loading,
        appGroupsDeleteLoading: false,
      }
      return {
        ...state,
        loading,
        apiError: action.error,
      }
    case DELETE_APPS_GROUPS_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        appGroupsDeleteLoading: false,
      }
      let deleteData = action.data
      return {
        ...state, loading,
        appGroupsDelete: deleteData,
      }
      /***/
    default:
      return state
  }
}
