import jwtDecode from 'jwt-decode'
import Storage from './Storage'

export function getPayload (lsTokenName) {
  const storage = new Storage()
  const payload = storage.get(lsTokenName)
  let decoded = null
  if (payload) {
    decoded = jwtDecode(payload)
  }
  return decoded
}