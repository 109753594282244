import {
  REASONFORSUPPORT_API_CALL_INNITIATED,
  REASONFORSUPPORT_API_CALL_SUCCESS,
  REASONFORSUPPORT_API_CALL_FAIL,
  REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED,
  REASONFORSUPPORT_BY_ID_API_CALL_SUCCESS,
  REASONFORSUPPORT_BY_ID_API_CALL_FAIL,
  REASONFORSUPPORT_POST_API_CALL_INNITIATED,
  REASONFORSUPPORT_POST_API_CALL_SUCCESS,
  REASONFORSUPPORT_POST_API_CALL_FAIL,
  REASONFORSUPPORT_POST_API_CALL_ERROR,
  REASONFORSUPPORT_POST_API_CALL_FINAL,
  CHECK_REASON_SUCCESS,
  CHECK_REASON_INITIATED,
  CHECK_REASON_FAIL,
} from "./actionType";

export const reasonForSupportState = {
  reasonForSupportList: [],
  reasonForSupportCount: 0,
  loading: {
    reasonForSupportLoading: true
  },
  //   modal: {
  //     modal: false
  //   },
  toast: {
    message: "",
    open: false
  }
};

export function reasonForSupport(state = reasonForSupportState, action = {}) {
  let loading;
  switch (action.type) {
    case REASONFORSUPPORT_API_CALL_INNITIATED:
      loading = {
        ...state.loading,
        reasonForSupportLoading: true
      };
      return {
        ...state,
        loading
      };
    case REASONFORSUPPORT_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        reasonForSupportLoading: false
      };
      let reasonForSupportList = action.data.result;
      let reasonForSupportCount = action.data.result.length;
      return {
        ...state,
        loading,
        reasonForSupportList,
        reasonForSupportCount
      };
    case REASONFORSUPPORT_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        reasonForSupportLoading: false,
        loading
      };
    default:
      return state;
  }
}

/*************** Fetching By Id *********************/

export const reasonForSupportByIdState = {
  reasonForSupportObj: null,
  reasonForSupportCount: null,
  loading: {
    reasonForSupportLoading: true
  },
  toast: {
    message: "",
    open: false
  }
};

export function reasonForSupportById(
  state = reasonForSupportByIdState,
  action = {}
) {
  let loading;
  switch (action.type) {
    case REASONFORSUPPORT_BY_ID_API_CALL_INNITIATED:
      loading = {
        ...state.loading,
        reasonForSupportLoading: true
      };
      return {
        ...state,
        loading
      };
    case REASONFORSUPPORT_BY_ID_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        reasonForSupportLoading: false
      };
      return {
        ...state,
        reasonForSupportObj: action.data.result,
        reasonForSupportCount:
          Object.entries(action.data.result).length === 0 &&
          action.data.result.constructor === Object
            ? 0
            : 1,
        loading
      };
    case REASONFORSUPPORT_BY_ID_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        reasonForSupportLoading: false,
        loading
      };
    default:
      return state;
  }
}

/*************** Post Reason For Support Form *********************/
export const reasonForSupportObjState = {
  reasonForSupportObj: null,
  // reasonForSupportCount: 0,
  loading: {
    reasonForSupportLoading: true
  },
  toast: {
    message: "",
    open: false
  }
};

export function reasonForSupportPost(
  state = reasonForSupportObjState,
  action = {}
) {
  let loading;
  switch (action.type) {
    case CHECK_REASON_INITIATED:
      loading = {
        ...state.loading,
        appReasonForSupportLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case CHECK_REASON_SUCCESS:
      loading = {
        ...state.loading,
        appReasonForSupportLoading: false,
      }
      return {
        ...state,
        loading,
        appReasonForSupportCheck: action.data,
      }
    case CHECK_REASON_FAIL:
      loading = {
        ...state.loading,
        appReasonForSupportLoading: false,
      }
      return {
        ...state,
        loading,
        appReasonForSupportCheckError: action.error
      }
    case REASONFORSUPPORT_POST_API_CALL_INNITIATED:
      loading = {
        ...state.loading,
        reasonForSupportLoading: true
      };
      return {
        ...state,
        loading
      };
    case REASONFORSUPPORT_POST_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        reasonForSupportLoading: false
      };
      return {
        ...state,
        apiSuccess: true,
        reasonForSupportObj: action.data.result,
        loading
      };
    case REASONFORSUPPORT_POST_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        reasonForSupportLoading: false,
        loading
      };
    case REASONFORSUPPORT_POST_API_CALL_ERROR:
      loading = {
        ...state.loading,
        reasonForSupportLoading: false
      };
      return {
        ...state,
        status: action.status,
        text: action.text,
        loading
      }
    case REASONFORSUPPORT_POST_API_CALL_FINAL:
      return {
        ...state,
        apiSuccess: false,
      }
    default:
      return state;
  }
}
