import { SHOW_NOTIFICATION, SHOW_MODAL } from './actionType'

export const initialState = {
  isShown: false,
  message: '',
}

export default function notificationReducer (state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const { payload } = action
      const { isShown, message, duration, variant } = payload
      const newState = {
        ...state,
        isShown,
        message,
        duration,
        variant,
      }
      return newState
    }
    default:
      return state
  }
}

export function modalReducer (state = {isShown: false, text: ''}, action = {}) {
  if(action.type === SHOW_MODAL){
    const { payload } = action
    const { text,isShown } = payload
    const newState = {
      ...state,
      isShown,
      text,
    }
    return newState
  }else{
    return state
  }
}
