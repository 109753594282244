import { reactCellRendererFactory } from 'ag-grid-react'
import moment from 'moment-timezone'
import { displayTzFormat } from '../../Utills/dateHelper'
import apiConfig from '../../config/apiConfig'

const style = {
  'white-space': 'normal',
  'border-right': '0px',
}

export default class ColumnDefinitionFactory {
  createColDefs(handleClick) {
    return [
      {
        headerName: '',
        width: 100,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
        // suppressMenu: true,
        pinned: true,
        cellStyle: style,
        rowDrag: true,
      },
      {
        headerName: "Label",
        field: "metric_name",
        width: 500,
        cellStyle: {color: "#0950dc", fontWeight: 600},
        cellRenderer: "group",
        pinned: true,
      },
      {
        headerName: "Type",
        field: "root_type",
      },
      {
        headerName: "Support Option",
        width: 300,
        field: "request_support",
        valueGetter: params => params.data.request_support && params.data.request_support.reason_for_support,
      },
      {
        headerName: "Published",
        field: "published",
        width: 150,
        valueGetter: params => params.data.published ? 'Yes' : 'No',
      },
      {
        headerName: "Sort Order",
        field: "sort_order",
        width: 100,
      },
      {
        headerName: "Help Categories",
        width: 300,
        field: "help_metric_groups",
        valueGetter: getHelpCategories,
      }
    ]
  }
  onBtExport() {
    let checked = this.state && this.state.enableEditCat ? false : true;
    let params = {
      skipHeader: false,
      columnGroups: getBooleanValue("#columnGroups"),
      skipFooters: getBooleanValue("#skipFooters"),
      skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      allColumns: getBooleanValue("#allColumns"),
      onlySelected: checked,
      fileName: 'Pol | ManageSupportPaths',
      sheetName: 'paths',
      exportMode: true,
      columnKeys: ['metric_name','root_type','request_support','published'],
    }
    this.gridApi.exportDataAsCsv(params)
  }
}

function getHelpCategories(params) {
  let helpGroupsArray = params.data.help_metric_groups
  return helpGroupsArray && helpGroupsArray.map(item => item.help_metric_group_name)
  // return ['General','Candidate Status']
}

function getBooleanValue(cssSelector) {
  return true
}
