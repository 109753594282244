import {
  GROUPS_LIST_API_CALL_INITIATED,
  GROUPS_LIST_API_CALL_SUCCESS,
  GROUPS_LIST_API_CALL_FAIL,
  GROUPS_LIST_CLEAR,
} from './actionType'

export const groupsState = {
  groupsList: [],
  groupsListCount: 0,
  loading: {
    groupsListLoading: true,
  },
  modal: {
    editForm: false,
  },
  toast: {
    message: '',
    open: false,
  },
}

export default function groups (state = groupsState, action = {}) {
  let loading
  let modal
  switch (action.type) {
    case GROUPS_LIST_API_CALL_INITIATED:
      loading = {
        ...state.loading,
        groupsListLoading: true,
      }
      return {
        ...state, loading,
      }
    case GROUPS_LIST_API_CALL_SUCCESS:
      loading = {
        ...state.loading,
        groupsListLoading: false,
      }
      let selectedObj = action.data.map(function(obj) {
        Object.assign(obj, {isAdded: false})
        return obj
      })
      let groupsList = selectedObj
      let groupsListCount = action.data.length
      return {
        ...state, loading,
        groupsList: groupsList,
        groupsListCount: groupsListCount,
      }
    case GROUPS_LIST_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        groupsListLoading: false,
      }
    case  GROUPS_LIST_CLEAR:
      return {
        ...state, loading,
        groupsList : null,
        groupsListCount : null
      }
    default:
      return state
  }
}
