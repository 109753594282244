import { SHOW_NOTIFICATION, SHOW_MODAL } from './actionType'

export function showNotification (isShown, message, variant = 'info', duration = 0) {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      isShown,
      message,
      variant,
      duration,
    },
  }
}

export function showModal (isShown, text) {
  return {
    type: SHOW_MODAL,
    payload: {
      isShown,
      text,
    }
  }
}