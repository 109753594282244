import { AUDIT_LOG_INIT, AUDIT_LOG_SUCCESS, AUDIT_LOG_FAIL } from './actionType'

let initialState = {
    loading: true,
    success: false,
    result: [],
}

export default function auditLogs (state = initialState, action = {}) {
    switch (action.type) {
        case AUDIT_LOG_INIT: 
            return {
                ...state,
                loading: true,
            }
        case AUDIT_LOG_SUCCESS: 
            return {
                ...state,
                loading: false,
                success: true,
                result: action.data.result,
            }
        case AUDIT_LOG_FAIL: 
            return {
                ...state,
                loading: false,
                success: false,
            }
        default: 
            return state
    }
}