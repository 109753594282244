export const CONTENT_CALL_SUCCESS = 'CONTENT_CALL_SUCCESS'
export const CONTENT_CALL_FAIL = 'CONTENT_CALL_FAIL'
export const CONTENT_CALL_INITIATED = 'CONTENT_CALL_INITIATED'

export const CONTENTBYID_CALL_SUCCESS = 'CONTENTBYID_CALL_SUCCESS'
export const CONTENTBYID_CALL_FAIL = 'CONTENTBYID_CALL_FAIL'
export const CONTENTBYID_CALL_INITIATED = 'CONTENTBYID_CALL_INITIATED'

export const EDITCONTENT_CALL_SUCCESS = 'EDITCONTENT_CALL_SUCCESS'
export const EDITCONTENT_CALL_FAIL = 'EDITCONTENT_CALL_FAIL'
export const EDITCONTENT_CALL_INITIATED = 'EDITCONTENT_CALL_INITIATED'