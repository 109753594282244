export const APPS_REPORTS_API_CALL_INITIATED = 'APPS_REPORTS_API_CALL_INITIATED'
export const APPS_REPORTS_API_CALL_SUCCESS = 'APPS_REPORTS_API_CALL_SUCCESS'
export const APPS_REPORTS_API_CALL_FAIL = 'APPS_REPORTS_API_CALL_FAIL'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const APPS_REPORT_CATEGORIES_UPDATE = 'APPS_REPORT_CATEGORIES_UPDATE'
export const APPS_REPORT_CATEGORIES_UPDATE_SUCCESS = 'APPS_REPORT_CATEGORIES_UPDATE_SUCCESS'
export const APPS_REPORT_CATEGORIES_UPDATE_FAIL = 'APPS_REPORT_CATEGORIES_UPDATE_FAIL'
export const FETCH_APPS_REPORTS_API_CALL_INITIATED = 'FETCH_APPS_REPORTS_API_CALL_INITIATED'
export const FETCH_APPS_REPORTS_API_CALL_SUCCESS = 'FETCH_APPS_REPORTS_API_CALL_SUCCESS'
export const FETCH_APPS_REPORTS_API_CALL_FAIL = 'FETCH_APPS_REPORTS_API_CALL_FAIL'
export const CLEAR_APPS_REPORTS_API_CALL_INITIATED = 'CLEAR_APPS_REPORTS_API_CALL_INITIATED'
export const POST_APPS_REPORTS_API_CALL_SUCCESS = 'POST_APPS_REPORTS_API_CALL_SUCCESS'
export const POST_APPS_REPORTS_API_CALL_FAIL = 'POST_APPS_REPORTS_API_CALL_FAIL'
export const POST_APPS_REPORTS_API_CALL_ERROR = 'POST_APPS_REPORTS_API_CALL_ERROR'
export const POST_APPS_REPORTS_API_CALL_INITIATED = 'POST_APPS_REPORTS_API_CALL_INITIATED'
export const CHECK_APPS_INITIATED = 'CHECK_APPS_INITIATED'
export const CHECK_APPS_SUCCESS = 'CHECK_APPS_SUCCESS'
export const CHECK_APPS_FAIL = 'CHECK_APPS_FAIL'
