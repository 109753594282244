import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ReasonForSupportForm from "./ReasonForSupportForm";
import { fetchReasonForSupportById, checkReasonForSupport } from "../../store/resonForSupport/actionCreator";

const styles = theme => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32
  },
  container: {
    marginTop: 10
  }
});

class EditReasonForSupport extends Component {
  newReasonForSupport = {
    id: "",
    reason_for_support: "",
    comments: "",
    email: "",
    destination_system_name: "ServiceNow",
    published: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      reasonForSupportObj: null,
      type: this.props.match.params.status === "new" ? "Add" : "Edit"
    };
  }
  componentDidMount() {
    if (this.props.match.params.status !== "new") {
      this.props.reasonForSupportById(this.props.match.params.id);
    }
    this.setState({
      reasonForSupportObj:
        this.props.match.params.status === "new"
          ? this.newReasonForSupport
          : this.props.requestSupportData.reasonForSupportObj &&
          this.props.requestSupportData.reasonForSupportObj,

      type: this.props.match.params.status === "new" ? "Add" : "Edit"
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        reasonForSupportObj:
          this.props.match.params.status === "new"
            ? this.newReasonForSupport
            : nextProps.requestSupportData.reasonForSupportObj &&
            nextProps.requestSupportData.reasonForSupportObj,
        type: this.props.match.params.status === "new" ? "Add" : "Edit"
      });
    }
  }
  checkReasonForSupports = (param) => {
    this.props.checkReasonForSupport(param)
  }

  render() {
    const { classes, requestSupportData, history } = this.props;
    const statusUI = this.props.match.params.status;
    return (
      <div>
        <Helmet>
          <title>{this.state.type} Reasons For Support</title>
        </Helmet>
        <Grid container className={classes.container}>
          {requestSupportData.loading &&
            requestSupportData.loading.reasonForSupportLoading &&
            this.state.type !== "Add" ? (
            <CircularProgress size={50} className={classes.buttonProgress} />
          ) : requestSupportData.apiError ? (
            <Grid container spacing={24}>
              <Grid item xs={12} className={classes.boxDiv}>
                <p>
                  'An error occurred while retrieving Reasons For Support Data.
                  Please contact support if this issue persists.'
                </p>
              </Grid>
            </Grid>
          ) : requestSupportData.reasonForSupportObj ||
            this.state.type === "Add" ? (
            <Grid item xs={12} >
              <ReasonForSupportForm
                reasonForSupportObj={this.state.reasonForSupportObj}
                status={statusUI}
                history={history}
                reasonType={this.state.type}
                edit={this.state.type === "Add" ? false : true}
                checkReasonForSupport={this.checkReasonForSupports}
              />
            </Grid>
          ) : (
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p className={classes.boxDiv}>
                  <Error className={classes.icon} /> There are no Reason For
                  Support.
                </p>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

EditReasonForSupport.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string,
      id: PropTypes.string
    })
  }),
  fetchReasonForSupportById: PropTypes.func,
  reasonForSupportById: PropTypes.func,
  requestSupportData: PropTypes.object,
  reasonForSupportObj: PropTypes.object,
  checkReasonForSupport: PropTypes.func,
};
const mapStateToProps = state => {
  const { reasonForSupportById } = state;
  return {
    requestSupportData: reasonForSupportById
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    reasonForSupportById: data => dispatch(fetchReasonForSupportById(data)),
    checkReasonForSupport: data => dispatch(checkReasonForSupport(data))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(EditReasonForSupport));
