import { combineReducers } from "redux";
// import auth from "./auth";
import layout from "../store/layout/reducer";
import notification from "./notification/reducer";
import appsAndReports from "./appsAndReport/reducer";
import categories from "./categories/reducer";
import groups from "./groups/reducer";
import appCategories from "./appCategories/reducer";
import appGroups from "./appGroups/reducer";
import {
  reasonForSupport,
  reasonForSupportById,
  reasonForSupportPost
} from "./resonForSupport/reducer";
import contents, {contentById, editContent} from "./contentFeedback/reducer"
import config from './config/reducer'
import auditLogs from './auditLogs/reducer'
import manageSupport, {
  supportOptions,
  updateSupport,
  addSupport,
  delSupport,
  getHelpCategories,
  editHelpCategories,
} from './manageSupport/reducer'
import fieldReducer from './SearchFieldWeightage/reducer'

const rootReducer = combineReducers({
  // auth,
  layout,
  notification,
  appsAndReports,
  categories,
  appCategories,
  groups,
  appGroups,
  reasonForSupport,
  reasonForSupportById,
  reasonForSupportPost,
  contents,
  config,
  auditLogs,
  manageSupport,
  supportOptions,
  updateSupport,
  addSupport,
  delSupport,
  getHelpCategories,
  editHelpCategories,
  contentById,
  editContent,
  fieldReducer,
});

export default rootReducer;
