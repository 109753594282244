import merge from "lodash/merge";

const commonConfig = {
  auth: {
    // authorizationPath: "/auth/oauth/v2/tgt/authorize/we2w/1",
    authorizationPath: "/auth/oauth/v2/authorize",
    logoutPath: "/login/responses/logoff.html",
    popupOptions: { width: 482, height: 680 },
    redirectUri: `${window.location.origin}/`,
    responseType: "token id_token",
    scope: ["openid profile"],
    storageType: "localStorage",
    tokenType: "Bearer"
  }
};

const envConfigs = {
  local: {
    auth: {
      host: 'https://oauth.iam.perf.partnersonline.com',
      logoutHost: "https://oauth.iam.perf.partnersonline.com",
      clientId: "partnersonline_npe_im",
      nonce: "1234"
    },
    adminApi: {
      // host: "https://polpolservice.dev.target.com/pol-service/api/v1/admin/",
      host: "https://polservice-dev.dev.target.com/pol-service/api/v1/admin/",
      cms: `https://cmsservice-dev.dev.target.com/cms-service/api/v1`,
    },
    sharePoint: {
      Library: "https://targetonline.sharepoint.com/sites/ogrp-tts-pol-test-migration/",
      Reports: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolreports/",
      Applications: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolapplications/",
      Glossary: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolglossary/",
      Help: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolhelp/",
      News: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolimportantupdates/",
      Notification: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolnotifications/",
      Public: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpollegal/",
      General: "https://targetonline.sharepoint.com"
    },
    hostUrl: 'https://partnersonline-dev.dev.target.com'
  },
  qa: {
    auth: {
      host: 'https://oauth.iam.perf.partnersonline.com',
      logoutHost: "https://oauth.iam.perf.partnersonline.com",
      clientId: "partnersonline_npe_im",
      nonce: "1234"
    },
    adminApi: {
      // host: "http://10.60.149.242/pol-service/api/v1/admin/",
      host: `${window.location.origin}/pol-service/api/v1/admin/`,
      cms: `${window.location.origin}/cms-service/api/v1`,
    },
    sharePoint: {
      Library: "https://targetonline.sharepoint.com/sites/ogrp-tts-pol-test-migration/",
      Reports: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolreports/",
      Applications: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolapplications/",
      Glossary: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolglossary/",
      Help: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolhelp/",
      News: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolimportantupdates/",
      Notification: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolnotifications/",
      Public: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpollegal/",
      General: "https://targetonline.sharepoint.com"
    },
    hostUrl: 'https://partnersonline-dev.dev.target.com'
  },
  stg: {
    auth: {
      host: 'https://oauth.iam.perf.partnersonline.com',
      logoutHost: "https://oauth.iam.perf.partnersonline.com",
      clientId: "partnersonline_npe_im",
      redirectUri: `${window.location.origin}/`,
      nonce: "1234"
    },
    adminApi: {
      host: `//${window.location.host}/pol-service/api/v1/admin/`,
      cms: `//${window.location.host}/cms-service/api/v1`,
    },
    sharePoint: {
      Library: "https://targetonline.sharepoint.com/sites/ogrp-tts-pol-test-migration/",
      Reports: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolreports/",
      Applications: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolapplications/",
      Glossary: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolglossary/",
      Help: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolhelp/",
      News: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolimportantupdates/",
      Notification: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpolnotifications/",
      Public: "https://targetonline.sharepoint.com/sites/ogrp-tts-testpollegal/",
      General: "https://targetonline.sharepoint.com"
    },
    hostUrl: 'https://xyc.partnersonline.com'
  },
  prod: {
    auth: {
      host: "https://oauth.iam.partnersonline.com",
      logoutHost: "https://oauth.iam.partnersonline.com",
      clientId: "partnersonline_prod_im",
      redirectUri: `${window.location.origin}/`,
      nonce: "1234"
    },
    adminApi: {
      host: `//${window.location.host}/pol-service/api/v1/admin/`,
      cms: `//${window.location.host}/cms-service/api/v1`,
    },
    sharePoint: {
      Library: "https://targetonline.sharepoint.com/sites/ogrp-tts-pollibrary",
      Reports: "https://targetonline.sharepoint.com/sites/ogrp-tts-polreports",
      Applications: "https://targetonline.sharepoint.com/sites/ogrp-tts-polapplications",
      Glossary: "https://targetonline.sharepoint.com/sites/ogrp-tts-polglossary",
      Help: "https://targetonline.sharepoint.com/sites/ogrp-tts-polhelp",
      News: "https://targetonline.sharepoint.com/sites/ogrp-tts-polnews",
      Notification: "https://targetonline.sharepoint.com/sites/ogrp-tts-polnotification",
      Public: "https://targetonline.sharepoint.com/sites/ogrp-tts-polpublic",
      General: "https://targetonline.sharepoint.com"
    },
    hostUrl: 'https://partnersonline.com'
  }
};

// env.js sets APP_ENV
let appEnv = ''

// eslint-disable-next-line no-console
console.log(process.env)
if (window.location.origin.indexOf('localhost') > -1) {
  appEnv = 'local';
} else {
  appEnv = process.env.REACT_APP_ENV;
}
const config = envConfigs[appEnv];
const apiConfig = merge(commonConfig, config);

export default apiConfig;
