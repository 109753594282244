import axios from 'axios'

export default function configureHttpInterceptor (history, showNotification) {
  // const showAuthModal = authModalActions.showAuthModal
  // const bearerToken = accessToken
  // const bearerToken = localStorage.getItem('access_token')

  // We intercept http requests here
  // This is a nice way to modify the outgoing request before it is sent
  // We show global loading progress here
  // We attach bearer tokens to the Authorization header

  axios.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('access_token')
    return config
  }, (error) => Promise.reject(error))

  axios.interceptors.response.use((response) => 
    // We intercept http responses here
    // This is a nice way to inspect the response and take action based on some criteria
     response
  , (error = {}) => {
    if (error.response === undefined && error.status) {
      window.location.reload(true) // its for logoff scenario
    }
    if (error.response) {
      const code = error.response.status
      const errMsg = error.response.data.meta_info && error.response.data.meta_info.error.message
      if (code === 401) {
        history.push('/unauthorized')
        showNotification(true, errMsg)
      }
      if (code === 400 || 500) {
        showNotification(false, '')
      }
      else {
        let message = 'Something went wrong.'
        if (code === 403) {
          message = 'You’re not authorized to do that.'
        } else if (error.message) {
          message = error.message
        }
        showNotification(true, message)
      }
    }
    return Promise.reject(error)
  })
}