import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import GridConfig from './GridConfig'
import HeaderTitle from "../../components/Header/HeaderTitle"
import apiConfig from '../../config/apiConfig'
import data from './dataConstant'

const styles = theme =>({
  root:{
    flexGrow: 1,
    width: '99%',
  },
  tableSize:{
    height: "calc(100vh)",
    width: "auto"
  },
})

class AllContent extends Component {
  constructor(props){
    super(props)
    this.gConfig = new GridConfig()
    this.state = {
      columnDefs: this.gConfig.createColDefs(this.handleClick),
      defaultColDef: {
        headerComponentParams: {
          menuIcon: 'fa-bars',
          sortable: true,
        },
        resizable: true,
      },
      rowData:  [],
      enableFilter: true,
      pagination: false,
      paginationPageSize: 10,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      enableSorting : true,
      enableEditCat:true,
      selectedRowsCat:'',
      selectedRowsId:'',
      selectedRowsApps:'',
      openModal : false,
      isAdd : false,
      selectedRow: '',
    }
    this.gridOptions = {
      columnDefs : this.state.columnDefs,
      enableFilter: this.state.enableFilter,
      enableSorting: this.state.enableSorting,
      enableColResize: this.state.enableColResize,
      pagination: this.state.pagination,
      paginationPageSize: this.state.paginationPageSize,
      rowSelection: this.state.rowSelection,
      suppressRowClickSelection: this.state.suppressRowClickSelection,
      suppressCellSelection: this.state.suppressCellSelection,
      defaultColDef: this.state.defaultColDef,
      enableCellTextSelection: true,
      rowBuffer: 10,
    }
  }

  componentDidMount(){
    this.setState({ rowData: data })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.appsAndReports.readOnly !== this.props.appsAndReports.readOnly){
      this.modifiedData = data.map(item => ({...item, 'readOnly':nextProps.appsAndReports.readOnly.read_only && JSON.parse(nextProps.appsAndReports.readOnly.read_only)}))
      this.setState({ rowData: this.modifiedData })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <HeaderTitle title="All Content ( SharePoint )" />
        <Helmet>
          <title>All Content ( SharePoint )</title>
        </Helmet>
        <Grid item xs={12}>
          <div className={[classes.tableSize, 'ag-theme-material full-row'].join(' ')}>
            <AgGridReact rowData={this.state.rowData} gridOptions={this.gridOptions}></AgGridReact>
          </div>
        </Grid>
      </div>
    )
  }
}

AllContent.propTypes = {
  classes: PropTypes.object.isRequired,
  appsAndReports: PropTypes.object,
}

const mapStateToProps = state => {
  const { appsAndReports } = state
  return {
    appsAndReports: appsAndReports,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(AllContent))
